import React, { Component, Suspense } from "react";
import { Table } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Responsive, Card, Grid } from "semantic-ui-react";
import { history } from "../../common/history";
import { StatusEnum } from "../../common/statusEnum";
import { mapStatusToSvg } from "../../common/mapStatusToSvg";
import MomentWithUtc from "../../common/components/momentWithUtc";

class SubmissionsTable extends Component {
  render() {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Responsive minWidth={768}>
          <h1>{this.props.t("profile.mySubmissions")}</h1>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {this.props.t("mySubmission.title")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {this.props.t("mySubmission.trackName")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {this.props.t("mySubmission.date")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {this.props.t("mySubmission.time")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {this.props.t("mySubmission.status")}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.submissions.map((submission, index) => (
                <Table.Row
                  key={index}
                  className="submissionRow"
                  onClick={() => {
                    history.push(`/submissions/${submission.id}`);
                  }}
                >
                  <Table.Cell>{submission.title}</Table.Cell>
                  <Table.Cell>{submission.trackName}</Table.Cell>
                  <Table.Cell>
                    <MomentWithUtc
                      format={"dateFormat"}
                      date={submission.date}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <MomentWithUtc
                      format={"timeFormat"}
                      date={submission.date}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {this.props.t(
                      `submissionStatus.${StatusEnum[submission.status]}`
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Responsive>
        <Responsive {...Responsive.onlyMobile}>
          <Card.Group itemsPerRow={1}>
            <h1>{this.props.t("profile.mySubmissions")}</h1>
            {this.props.submissions.map((submission, index) => (
              <Card
                key={index}
                onClick={() => {
                  history.push(`/submissions/${submission.id}`);
                }}
              >
                <Card.Content>
                  <Grid columns="equal">
                    <Grid.Column textAlign="left">
                      <b>{submission.title}</b>
                      <Grid.Column>
                        <MomentWithUtc
                          format={"dateFormat"}
                          date={submission.date}
                        />
                      </Grid.Column>
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                      {mapStatusToSvg(submission.status)}
                    </Grid.Column>
                  </Grid>
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
        </Responsive>
      </Suspense>
    );
  }
}

SubmissionsTable.propTypes = {
  t: PropTypes.func,
  submissions: PropTypes.array
};

export default withTranslation()(connect()(SubmissionsTable));
