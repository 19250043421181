import PropTypes from "prop-types";
import React, { Component } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Input,
  Button,
  Form,
  Segment,
  Modal,
  Checkbox
} from "semantic-ui-react";
import hu from "date-fns/locale/hu";
import en from "date-fns/locale/en-GB";

import {
  addNewTrack,
  fetchTrack,
  loadCommittee,
  updateTrack
} from "../actions";
import { getUsers } from "../../common/actions";
import {
  localDateToUtc,
  utcDateToLocal
} from "../../common/components/momentWithUtc";
import PopUpWarningSignWithMessage from "../../common/components/popupWarningSignWithMessage";
import DropdownSelector from "../../common/components/dropdownSelector";
import "react-datepicker/dist/react-datepicker.css";
import "../style/customDatePickerWidth.css";
import "../style/manageTrack.css";

export const ManageTrackModeEnum = Object.freeze({
  Create: 0,
  Edit: 1
});

registerLocale("hu", hu);
registerLocale("en", en);

const initialInputErrorState = {
  name: false,
  submissionDeadline: false,
  abstractLength: false,
  fileSize: false,
  trackExtensions: false
};

/////////////////////////////////////////////
//Create/Edit Track with Modal component
//
//1). Create:
//  componentMode=ManageTrackModeEnum.Create
//
//2). Edit:
//  componentMode=ManageTrackModeEnum.Edit
//  set:  track
//
/////////////////////////////////////////////
class ManageTrack extends Component {
  constructor(props) {
    super(props);
    this.searchCommitteeMembers = this.searchCommitteeMembers.bind(this);
    this.onCommitteeChange = this.onCommitteeChange.bind(this);
    switch (this.props.componentMode) {
      case ManageTrackModeEnum.Create: {
        this.state = {
          // added because I couldn't figure out how to detect deletion in the onChange method, may be refactored
          committeeMembersToSave: [],
          componentMode: ManageTrackModeEnum.Create,
          open: false,
          track: {
            name: "",
            submissionDeadline: this.props.defaultSubmissionDeadline,
            abstractLength: this.props.defaultAbstractLength,
            fileSize: this.props.defaultFileSize
          },
          trackExtensions: this.props.defaultExtensions,
          weightError: false
        };
        break;
      }
      case ManageTrackModeEnum.Edit: {
        this.state = {
          componentMode: ManageTrackModeEnum.Edit,
          evaluationCriteria: this.props.track.evaluationCriteria,
          open: false,
          track: this.props.track,
          trackExtensions: this.props.track.trackExtensions
        };
        break;
      }
      default: {
        break;
      }
    }
    this.state = {
      ...this.state,
      inputError: initialInputErrorState
    };
    ///////////////////////////////////////////////////////////
    //This is needed to reset the main initial state on close
    this.state.initialTrackState = this.state.track;
    ///////////////////////////////////////////////////////////
  }

  componentDidUpdate(prevProps) {
    ////////////////////////////////////////
    //In case of editing track, this forces
    //the modal to update its state...
    switch (this.props.componentMode) {
      case ManageTrackModeEnum.Edit: {
        if (prevProps.track !== this.props.track) {
          this.setState({
            track: this.props.track,
            trackExtensions: this.props.track.trackExtensions,
            evaluationCriteria: this.props.track.evaluationCriteria
          });
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  //////////////////////////////
  //Close the modal and reset
  //its state to initial state
  close = () => {
    this.setState({
      open: false,
      track: this.state.initialTrackState,
      inputError: initialInputErrorState
    });
  };

  onCommitteeChange(_, { value }) {
    let users = value.map(val => JSON.parse(val));
    this.setState({ committeeMembersToSave: users });
  }

  searchCommitteeMembers(e) {
    if (e.target.value.length > 2) {
      this.props.getCommitteeMembers(e.target.value);
    }
  }

  onCriteriaChange(e, idx) {
    this.setState({
      evaluationCriteria: this.state.evaluationCriteria.map((ecd, index) =>
        index === idx ? { ...ecd, name: e.target.value } : { ...ecd }
      )
    });
  }

  onWeightChange(e, idx) {
    if (isNaN(e.target.value) || parseInt(e.target.value) <= 0) {
      this.setState({
        evaluationCriteria: this.state.evaluationCriteria.map((ecd, index) =>
          index === idx
            ? { ...ecd, weight: e.target.value, error: true }
            : { ...ecd }
        )
      });
    } else {
      this.setState({
        evaluationCriteria: this.state.evaluationCriteria.map((ecd, index) =>
          index === idx
            ? { ...ecd, weight: e.target.value, error: false }
            : { ...ecd }
        )
      });
    }
  }

  /////////////////////////////////
  //The trigger to open the modal,
  //in this case Add and Edit buttons
  renderTrigger() {
    switch (this.state.componentMode) {
      case ManageTrackModeEnum.Create: {
        return (
          <Button
            content={this.props.t("track.newTrack")}
            icon="add"
            onClick={() => {
              this.props.loadCommittee();
              this.setState({
                evaluationCriteria: [{ name: "", weight: "", error: false }],
                open: true
              });
            }}
          />
        );
      }
      case ManageTrackModeEnum.Edit: {
        return (
          <Button
            icon="edit outline"
            onClick={async () => {
              await this.props.fetchTrack(
                this.props.conferenceId,
                this.props.trackId
              );
              this.props.loadCommittee(this.props.trackId);
              this.setState({
                evaluationCriteria: this.props.track.evaluationCriteria,
                open: true
              });
            }}
          />
        );
      }
      default: {
        return null;
      }
    }
  }

  renderHeader() {
    switch (this.state.componentMode) {
      case ManageTrackModeEnum.Create: {
        return this.props.t("track.newTrack");
      }
      case ManageTrackModeEnum.Edit: {
        return this.props.t("track.editTrack");
      }
      default: {
        return null;
      }
    }
  }

  renderContent() {
    return (
      <Form>
        <Form.Field>
          <label>
            {this.props.t("track.name")}
            <PopUpWarningSignWithMessage
              warningCondition={this.state.inputError.name}
              translatedWarningMessage={"trackCreateAndEditErrors.trackName"}
            />
          </label>
          <Input
            fluid
            defaultValue={this.state.track.name}
            onChange={e => {
              this.setState({
                track: { ...this.state.track, name: e.target.value }
              });
            }}
          />
        </Form.Field>
        <Form.Field>
          <label>
            {this.props.t("track.deadline")}
            <PopUpWarningSignWithMessage
              warningCondition={this.state.inputError.submissionDeadline}
              translatedWarningMessage={"trackCreateAndEditErrors.deadline"}
            />
          </label>
          <div className="customDatePickerWidth">
            <DatePicker
              fluid
              maxDate={new Date(utcDateToLocal(this.props.conferenceStartDate))}
              selected={
                new Date(utcDateToLocal(this.state.track.submissionDeadline))
              }
              onChange={date => {
                this.setState({
                  track: {
                    ...this.state.track,
                    submissionDeadline: localDateToUtc(date)
                  }
                });
              }}
              showTimeSelect
              locale={this.props.t("localeFormats:tag")}
              dateFormat="Pp"
            />
          </div>
        </Form.Field>
        <DropdownSelector
          label={this.props.t("track.committee")}
          searchElements={this.searchCommitteeMembers}
          elementChange={this.onCommitteeChange}
          elements={this.props.committeeMembers}
          defaultSelected={this.props.committeeMembers.map(user =>
            JSON.stringify(user)
          )}
          error={this.state.committeeError}
        />
        <Form.Field>
          <label>
            {this.props.t("track.abstractLength")}
            <PopUpWarningSignWithMessage
              warningCondition={this.state.inputError.abstractLength}
              translatedWarningMessage={
                "trackCreateAndEditErrors.abstractLength"
              }
            />
          </label>
          <Input
            fluid
            defaultValue={this.state.track.abstractLength}
            onChange={e => {
              this.setState({
                track: { ...this.state.track, abstractLength: e.target.value }
              });
            }}
          />
        </Form.Field>
        <Form.Field>
          <label>
            {this.props.t("track.fileSize")}{" "}
            <PopUpWarningSignWithMessage
              warningCondition={this.state.inputError.fileSize}
              translatedWarningMessage={"trackCreateAndEditErrors.fileSize"}
            />
          </label>
          <Input
            fluid
            defaultValue={this.state.track.fileSize}
            onChange={e => {
              this.setState({
                track: { ...this.state.track, fileSize: e.target.value }
              });
            }}
          />
        </Form.Field>
        <Form.Field>
          <label>
            {this.props.t("track.extensions")}
            <PopUpWarningSignWithMessage
              warningCondition={this.state.inputError.trackExtensions}
              translatedWarningMessage={
                "trackCreateAndEditErrors.trackExtensions"
              }
            />
          </label>
          <Segment className="extension-container">
            {this.props.extensions.map((extension, index) => (
              <Checkbox
                className="extension-checkbox"
                key={index}
                label={extension.name}
                value={extension.id}
                defaultChecked={this.hasThisExtension(extension)}
                onChange={this.handleExtensionChange}
              />
            ))}
          </Segment>
        </Form.Field>
        <Form.Field>
          <label>
            {this.props.t("track.evaluationCriteria")}{" "}
            <PopUpWarningSignWithMessage
              waningCondition={this.state.inputError.evaluationCriteria}
              translatedWarningMessage={
                "trackCreateAndEditErrors.evaluationCriteria"
              }
            />
          </label>
        </Form.Field>
        {this.state.evaluationCriteria !== undefined
          ? this.state.evaluationCriteria.map((ec, idx) => (
              <Form.Field key={idx} error={ec.error}>
                <div className="evaluationCriteriaDiv">
                  <span className="criterion">
                    <input
                      value={ec.name}
                      type="text"
                      onChange={e => this.onCriteriaChange(e, idx)}
                      placeholder={this.props.t("track.criterion")}
                    />
                  </span>
                  <span className="weight">
                    <input
                      value={ec.weight}
                      type="text"
                      onChange={e => this.onWeightChange(e, idx)}
                      placeholder={this.props.t("track.weight")}
                    />
                  </span>
                  <Button
                    icon="delete"
                    onClick={() => {
                      this.setState({
                        evaluationCriteria: this.state.evaluationCriteria.filter(
                          (_, index) => index !== idx
                        )
                      });
                    }}
                  />
                </div>
              </Form.Field>
            ))
          : null}
        <div className="addCriteriaFieldButtonDiv">
          <Button
            icon="add"
            onClick={() => {
              this.setState({
                evaluationCriteria: [
                  ...this.state.evaluationCriteria,
                  { name: "", weight: "", error: false }
                ]
              });
            }}
          />
        </div>
      </Form>
    );
  }

  //////////////////////////////////
  //Returns *true* if the track already has
  //the *extension*. Used to render the checkboxes
  hasThisExtension = extension => {
    return this.state.trackExtensions.some(
      trackExtension => extension.id === trackExtension.extensionId
    );
  };

  handleExtensionChange = (e, { label, value, checked }) => {
    if (checked) {
      let trackId =
        this.state.componentMode === ManageTrackModeEnum.Edit
          ? this.state.track.id
          : null;
      let extension = { trackId: trackId, extensionId: value, name: label };
      this.setState({
        trackExtensions: [...this.state.trackExtensions, extension]
      });
    } else {
      this.setState({
        trackExtensions: this.state.trackExtensions.filter(
          trackExtension => trackExtension.extensionId !== value
        )
      });
    }
  };

  ///////////////////////////////////
  //Action buttons for Create- and
  //Save the updated- track
  renderActionButtons() {
    switch (this.state.componentMode) {
      case ManageTrackModeEnum.Create: {
        return (
          <Button
            content={this.props.t("buttonText.create")}
            onClick={() => {
              if (this.validateInputData()) {
                this.props.addNewTrack(
                  this.props.conferenceId,
                  this.state.track,
                  this.state.trackExtensions,
                  this.state.committeeMembersToSave,
                  this.state.evaluationCriteria,
                  this.close
                );
              }
            }}
          />
        );
      }
      case ManageTrackModeEnum.Edit: {
        return (
          <Segment basic floated="right">
            <Button
              content={this.props.t("buttonText.save")}
              onClick={() => {
                if (this.validateInputData()) {
                  this.props.updateTrack(
                    this.props.conferenceId,
                    this.state.track,
                    this.state.trackExtensions,
                    this.state.committeeMembersToSave,
                    this.state.evaluationCriteria,
                    this.close
                  );
                }
              }}
            />
          </Segment>
        );
      }
      default: {
        return null;
      }
    }
  }

  validateInputData = () => {
    let inputError = { initialInputErrorState };
    let noError = true;
    if (this.state.track.name.length < 1) {
      noError = false;
      inputError.name = true;
    }

    if (
      this.state.track.abstractLength < 1 ||
      this.state.track.abstractLength > 10000
    ) {
      noError = false;
      inputError.abstractLength = true;
    }
    if (this.state.track.fileSize < 1 || this.state.track.fileSize > 1024) {
      noError = false;
      inputError.fileSize = true;
    }
    if (this.state.trackExtensions.length < 1) {
      noError = false;
      inputError.trackExtensions = true;
    }

    this.state.evaluationCriteria.forEach(ec => {
      if (
        ec.error ||
        (ec.name !== "" && ec.weight === "") ||
        (ec.name === "" && ec.weight !== "")
      ) {
        noError = false;
        inputError.evaluationCriteria = true;
      }
    });

    this.setState({ inputError: inputError });

    return noError;
  };

  render() {
    const { open } = this.state;
    return (
      <Modal
        open={open}
        trigger={this.renderTrigger()}
        on="click"
        closeIcon
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={this.close}
      >
        <Modal.Header>{this.renderHeader()}</Modal.Header>
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Actions>{this.renderActionButtons()}</Modal.Actions>
      </Modal>
    );
  }
}

ManageTrack.propTypes = {
  abstractLength: PropTypes.number,
  addNewTrack: PropTypes.func,
  committeeMembers: PropTypes.array,
  componentMode: PropTypes.number,
  conferenceId: PropTypes.string,
  conferenceStartDate: PropTypes.string,
  defaultAbstractLength: PropTypes.number,
  defaultExtensions: PropTypes.array,
  defaultFileSize: PropTypes.number,
  defaultSubmissionDeadline: PropTypes.string,
  deleteTrack: PropTypes.func,
  extensions: PropTypes.array,
  fetchTrack: PropTypes.func,
  getCommitteeMembers: PropTypes.func,
  loadCommittee: PropTypes.func,
  t: PropTypes.func,
  track: PropTypes.object,
  trackId: PropTypes.string,
  trackName: PropTypes.string,
  updateTrack: PropTypes.func
};

const mapStateToProps = state => ({
  committeeMembers: state.conferenceProfileReducer.committeeMembers,
  conferenceId: state.conferenceProfileReducer.conference.id,
  conferenceStartDate: state.conferenceProfileReducer.conference.startDate,
  defaultAbstractLength:
    state.conferenceProfileReducer.conference.abstractLength,
  defaultExtensions:
    state.conferenceProfileReducer.conference.conferenceExtensions,
  defaultFileSize: state.conferenceProfileReducer.conference.fileSize,
  defaultSubmissionDeadline:
    state.conferenceProfileReducer.conference.submissionDeadline,
  extensions: state.conferenceProfileReducer.extensions
});

const mapDispatchToProps = dispatch => ({
  addNewTrack: (
    conferenceId,
    track,
    trackExtensions,
    committeeMembers,
    evaluationCriteria,
    closeFunction
  ) =>
    addNewTrack(
      dispatch,
      conferenceId,
      track,
      trackExtensions,
      committeeMembers,
      evaluationCriteria,
      closeFunction
    ),
  fetchTrack: (conferenceId, trackId) =>
    fetchTrack(conferenceId, trackId, dispatch),
  getCommitteeMembers: substring =>
    getUsers(dispatch, substring, "committeeMember"),
  loadCommittee: trackId => loadCommittee(dispatch, trackId),
  updateTrack: (
    conferenceId,
    track,
    trackExtensions,
    committeeMembers,
    evaluationCriteria,
    closeFunction
  ) =>
    updateTrack(
      dispatch,
      conferenceId,
      track,
      trackExtensions,
      committeeMembers,
      evaluationCriteria,
      closeFunction
    )
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ManageTrack)
);
