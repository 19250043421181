import React, { Component } from "react";
import { SemanticToastContainer, toast } from "react-semantic-toasts";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "../styles/alert.css";

class Alert extends Component {
  render() {
    const { alert } = this.props;
    return (
      <div>
        {alert.message &&
          toast({
            type: alert.type,
            description: this.props.t(alert.message),
            time: 4000
          })}
        <SemanticToastContainer
          position="top-center"
          animation="swing up"
          className="alert"
        />
      </div>
    );
  }
}

Alert.propTypes = {
  alert: PropTypes.object,
  t: PropTypes.func
};

const mapStateToProps = state => ({
  alert: state.alert
});

export default withTranslation()(connect(mapStateToProps)(Alert));
