import React, { Component } from "react";
import { Icon, Form, Popup, Button, Dropdown } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import "../style/login.css";
import { login, logout, setOpen } from "../actions";
import PropTypes from "prop-types";
import { validateEmail } from "../../common/validator";
import { orderNames } from "../../common/nameLocalization";
import { PermissionEnum } from "../../common/permissionEnum";
import { checkPermission } from "../../common/checkPermission";
const trigger = (
  <span>
    <Icon name="circle user" size="big" />
  </span>
);

class LoginLogout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        email: "",
        password: "",
        firstName: "",
        lastName: ""
      },
      emailError: false,
      passwordError: false,
      canViewUsers: false
    };

    if (props.loggedInUser) {
      this.state.canViewUsers = checkPermission(
        props.loggedInUser.permissions,
        PermissionEnum.userView
      );
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.logout = this.logout.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleClose() {
    this.props.setOpen(false);
  }

  handleOpen() {
    this.props.setOpen(true);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    switch (name) {
      case "email": {
        if (!validateEmail(value)) {
          this.setState({ emailError: true });
        } else {
          this.setState({ emailError: false });
        }
        break;
      }
      case "password": {
        if (value.length < 8) {
          this.setState({ passwordError: true });
        } else {
          this.setState({ passwordError: false });
        }
        break;
      }
      default: {
        break;
      }
    }
    this.setState({
      user: { ...user, [name]: value }
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.login(this.state.user);
    this.props.setOpen(false);
  }

  logout() {
    this.props.logout();
    this.props.setOpen(false);
  }

  render() {
    return (
      <>
        {this.props.loggedIn ? (
          <Dropdown
            icon={null}
            pointing="top right"
            trigger={trigger}
            on="click"
            open={this.props.isOpen}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            position="bottom right"
          >
            <Dropdown.Menu>
              <Dropdown.Item>
                {orderNames(
                  this.props.loggedInUser.firstName,
                  this.props.loggedInUser.lastName,
                  this.props.t("localeFormats:namePattern")
                )}
              </Dropdown.Item>
              <Dropdown.Divider></Dropdown.Divider>
              <Dropdown.Item href={"/profile/" + this.props.loggedInUser.id}>
                {this.props.t("profile.myProfile")}
              </Dropdown.Item>
              <Dropdown.Item href="/conferences">
                {this.props.t("profile.conferences")}
              </Dropdown.Item>
              <Dropdown.Item href="/submissions" className="profilePopup">
                {this.props.t("profile.mySubmissions")}
              </Dropdown.Item>
              {this.state.canViewUsers ? (
                <Dropdown.Item href="/userList">
                  {this.props.t("profile.userList")}
                </Dropdown.Item>
              ) : null}
              <Dropdown.Item
                href="/assignedSubmissions"
                className="profilePopup"
              >
                {this.props.t("profile.supervisedSubmissions")}
              </Dropdown.Item>
              <Dropdown.Divider></Dropdown.Divider>
              <Dropdown.Item>
                <p onClick={this.logout}>{this.props.t("profile.logout")}</p>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <Popup
            trigger={trigger}
            on="click"
            open={this.props.isOpen}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            position="bottom right"
            popperDependencies={[!!this.props.loggedIn]}
          >
            <Form autoComplete="off" onSubmit={this.handleSubmit}>
              <Form.Field required error={this.state.emailError}>
                <input
                  type="email"
                  name="email"
                  placeholder={this.props.t("login.email")}
                  onKeyUp={this.handleChange}
                />
              </Form.Field>
              <Form.Field required error={this.state.passwordError}>
                <input
                  type="password"
                  name="password"
                  placeholder={this.props.t("login.password")}
                  onKeyUp={this.handleChange}
                />
              </Form.Field>
              <Button
                type="submit"
                disabled={
                  !this.state.user.email ||
                  !this.state.user.password ||
                  this.state.emailError ||
                  this.state.passwordError
                }
              >
                {this.props.t("login.submit")}
              </Button>
              <Form.Field>
                <a href="/register" className="registerLink">
                  {this.props.t("profile.register")}
                </a>
              </Form.Field>
            </Form>
          </Popup>
        )}
      </>
    );
  }
}

LoginLogout.propTypes = {
  login: PropTypes.func,
  t: PropTypes.func,
  logout: PropTypes.func,
  loggedIn: PropTypes.bool,
  loggedInUser: PropTypes.object,
  setOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  user: PropTypes.object
};

const mapStateToProps = state => ({
  isOpen: state.loginLogout.isOpen,
  loggedIn: state.loginLogout.loggedIn,
  loggedInUser: state.loginLogout.user
});

const mapDispatchToProps = dispatch => ({
  login: user => login(dispatch, user),
  logout: () => logout(dispatch),
  setOpen: isOpen => setOpen(dispatch, isOpen)
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(LoginLogout)
);
