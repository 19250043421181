import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import {
  Responsive,
  Card,
  TextArea,
  Form,
  Grid,
  Segment,
  Button,
  Icon,
  Checkbox,
  Input
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import DatePicker, { registerLocale } from "react-datepicker";
import hu from "date-fns/locale/hu";
import en from "date-fns/locale/en-GB";
import PropTypes from "prop-types";
import { localDateToUtc } from "../../common/components/momentWithUtc";
import "../style/conferenceCreatePage.css";
import "react-datepicker/dist/react-datepicker.css";
import "../style/customDatePickerWidth.css";
import {
  createNewConference,
  fetchExtensions,
  unauthorized,
  updateConference
} from "../actions";
import { setConferenceListTab } from "../../conferenceList/actions";
import { conferenceLogoPlaceholder } from "../conferenceLogoPlaceholder";
import ImageSelector from "../../common/components/imageSelector";
import { utcDateToLocal } from "../../common/components/momentWithUtc";
import PopUpWarningSignWithMessage from "../../common/components/popupWarningSignWithMessage";
import { PermissionEnum } from "../../common/permissionEnum";
import { checkPermission } from "../../common/checkPermission";

export const ManageConferenceModeEnum = Object.freeze({
  Create: 0,
  Edit: 1
});

registerLocale("hu", hu);
registerLocale("en", en);

const initialEmptyState = {
  name: "",
  shortName: "",
  location: "",
  startDate: new Date(),
  endDate: new Date(),
  shortDescription: "",
  longDescription: "",
  logo: conferenceLogoPlaceholder,
  submissionDeadline: new Date(),
  abstractLength: 0,
  fileSize: 0,
  conferenceExtensions: []
};

const initialInputErrorState = {
  name: false,
  shortName: false,
  location: false,
  startDate: false,
  endDate: false,
  shortDescription: false,
  longDescription: false,
  submissionDeadline: false,
  abstractLength: false,
  fileSize: false,
  conferenceExtensions: false
};

class ConferenceCreateAndEditPage extends Component {
  constructor(props) {
    super(props);
    this.props.fetchExtensions();
    if (this.props.conferenceToEdit !== undefined) {
      this.state = {
        conference: {
          ...this.props.conferenceToEdit,
          conferenceExtensions: this.props.conferenceExtensions
        }
      };
    } else {
      this.state = {
        conference: initialEmptyState
      };
    }
    this.state = {
      ...this.state,
      inputError: initialInputErrorState
    };

    let canCreate = false;

    if (this.props.user) {
      canCreate = checkPermission(
        this.props.user.permissions,
        PermissionEnum.conferenceCreation
      );
    }

    if (!canCreate) {
      this.props.unauthorized();
    }
  }

  renderTitleWithMetaData() {
    return (
      <div>
        <div>
          <PopUpWarningSignWithMessage
            warningCondition={this.state.inputError.name}
            translatedWarningMessage={
              "conferenceCreateAndEditErrors.conferenceName"
            }
          />
          <Input
            placeholder={this.props.t("conferenceProfile.conferenceName")}
            defaultValue={
              this.props.componentMode === ManageConferenceModeEnum.Edit
                ? this.state.conference.name
                : ""
            }
            onChange={e => {
              this.setState({
                conference: { ...this.state.conference, name: e.target.value }
              });
            }}
          />
        </div>
        <div className="conferenceMeta">
          <PopUpWarningSignWithMessage
            warningCondition={this.state.inputError.location}
            translatedWarningMessage={"conferenceCreateAndEditErrors.location"}
          />
          {this.props.t("conferenceProfile.location")}
          <Input
            defaultValue={
              this.props.componentMode === ManageConferenceModeEnum.Edit
                ? this.state.conference.location
                : ""
            }
            onChange={e => {
              this.setState({
                conference: {
                  ...this.state.conference,
                  location: e.target.value
                }
              });
            }}
          />

          <br />
          <PopUpWarningSignWithMessage
            warningCondition={this.state.inputError.startDate}
            translatedWarningMessage={"conferenceCreateAndEditErrors.startDate"}
          />
          {this.props.t("conferenceProfile.startDate")}
          {/* Show and pick only date... */}
          <div className="customDatePickerWidth">
            <DatePicker
              fluid
              minDate={new Date()}
              selected={new Date(this.state.conference.startDate)}
              onChange={date => {
                this.setState({
                  conference: {
                    ...this.state.conference,
                    startDate: localDateToUtc(date)
                  }
                });
              }}
              locale={this.props.t("localeFormats:tag")}
            />
          </div>
          <br />
          <PopUpWarningSignWithMessage
            warningCondition={this.state.inputError.endDate}
            translatedWarningMessage={"conferenceCreateAndEditErrors.endDate"}
          />
          {this.props.t("conferenceProfile.endDate")}
          {/* Show and pick only date... */}
          <div className="customDatePickerWidth">
            <DatePicker
              fluid
              minDate={new Date(this.state.conference.startDate)}
              selected={new Date(this.state.conference.endDate)}
              onChange={date => {
                this.setState({
                  conference: {
                    ...this.state.conference,
                    endDate: localDateToUtc(date)
                  }
                });
              }}
              locale={this.props.t("localeFormats:tag")}
            />
          </div>
        </div>
      </div>
    );
  }

  renderContent() {
    return (
      <Segment basic>
        <Form>
          <PopUpWarningSignWithMessage
            warningCondition={this.state.inputError.longDescription}
            translatedWarningMessage={
              "conferenceCreateAndEditErrors.longDescription"
            }
          />
          <TextArea
            name="longDescription"
            required
            placeholder={this.props.t("conferenceProfile.longDescription")}
            defaultValue={
              this.props.componentMode === ManageConferenceModeEnum.Edit
                ? this.state.conference.longDescription
                : ""
            }
            style={{ minHeight: 200 }}
            onChange={e => {
              this.setState({
                conference: {
                  ...this.state.conference,
                  longDescription: e.target.value
                }
              });
            }}
          />
        </Form>
      </Segment>
    );
  }

  renderAdditionalData() {
    return (
      <Segment basic>
        <hr />
        <div className="additionalInfoLabel">
          {this.props.t("conferenceProfile.additionalInfo")}
        </div>
        <Form>
          <Form.Field>
            <label>
              <PopUpWarningSignWithMessage
                warningCondition={this.state.inputError.shortName}
                translatedWarningMessage={
                  "conferenceCreateAndEditErrors.shortName"
                }
              />

              {this.props.t("conferenceProfile.conferenceShortName")}
            </label>
            <Input
              name="conferenceShortName"
              defaultValue={
                this.props.componentMode === ManageConferenceModeEnum.Edit
                  ? this.state.conference.shortName
                  : ""
              }
              onChange={e => {
                this.setState({
                  conference: {
                    ...this.state.conference,
                    shortName: e.target.value
                  }
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>
              <PopUpWarningSignWithMessage
                warningCondition={this.state.inputError.abstractLength}
                translatedWarningMessage={
                  "conferenceCreateAndEditErrors.abstractLength"
                }
              />
              {this.props.t("track.abstractLength")}
            </label>
            <Input
              name="abstractLength"
              type="number"
              defaultValue={
                this.props.componentMode === ManageConferenceModeEnum.Edit
                  ? this.state.conference.abstractLength
                  : ""
              }
              onChange={e => {
                this.setState({
                  conference: {
                    ...this.state.conference,
                    abstractLength: e.target.value
                  }
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>
              <PopUpWarningSignWithMessage
                warningCondition={this.state.inputError.fileSize}
                translatedWarningMessage={
                  "conferenceCreateAndEditErrors.fileSize"
                }
              />
              {this.props.t("track.fileSize")}
            </label>
            <Input
              name="fileSize"
              type="number"
              defaultValue={
                this.props.componentMode === ManageConferenceModeEnum.Edit
                  ? this.state.conference.fileSize
                  : ""
              }
              onChange={e => {
                this.setState({
                  conference: {
                    ...this.state.conference,
                    fileSize: e.target.value
                  }
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>
              <PopUpWarningSignWithMessage
                warningCondition={this.state.inputError.submissionDeadline}
                translatedWarningMessage={
                  "conferenceCreateAndEditErrors.deadline"
                }
              />
              {this.props.t("conferenceProfile.deadline")}
            </label>
            <div className="customDatePickerWidth">
              <DatePicker
                fluid
                // This maxDate is set to the starting date (conference.startDate) of the conference...
                // There shouldn't be deadlines after that date.
                maxDate={new Date(this.state.conference.startDate)}
                selected={
                  new Date(
                    utcDateToLocal(this.state.conference.submissionDeadline)
                  )
                }
                onChange={date => {
                  this.setState({
                    conference: {
                      ...this.state.conference,
                      submissionDeadline: localDateToUtc(date)
                    }
                  });
                }}
                showTimeSelect
                locale={this.props.t("localeFormats:tag")}
                dateFormat="Pp"
              />
            </div>
          </Form.Field>
          <Form.Field>
            <label>
              <PopUpWarningSignWithMessage
                warningCondition={this.state.inputError.conferenceExtensions}
                translatedWarningMessage={
                  "conferenceCreateAndEditErrors.conferenceExtensions"
                }
              />
              {this.props.t("track.extensions")}
            </label>
            <Segment className="extension-container">
              {this.props.extensions.map((extension, index) => (
                <Checkbox
                  className="extension-checkbox"
                  key={index}
                  label={extension.name}
                  value={extension.id}
                  defaultChecked={this.hasThisExtension(extension)}
                  onChange={this.handleExtensionChange}
                />
              ))}
            </Segment>
          </Form.Field>
          <PopUpWarningSignWithMessage
            warningCondition={this.state.inputError.shortDescription}
            translatedWarningMessage={
              "conferenceCreateAndEditErrors.shortDescription"
            }
          />
          <Form.TextArea
            name="shortDescription"
            required
            defaultValue={
              this.props.componentMode === ManageConferenceModeEnum.Edit
                ? this.state.conference.shortDescription
                : ""
            }
            placeholder={this.props.t("conferenceProfile.shortDescription")}
            style={{ minHeight: 100 }}
            onChange={e => {
              this.setState({
                conference: {
                  ...this.state.conference,
                  shortDescription: e.target.value
                }
              });
            }}
          />
        </Form>
        <Segment basic>
          {this.props.componentMode === ManageConferenceModeEnum.Edit ? (
            <Button
              floated="right"
              onClick={() => {
                if (this.checkInput()) {
                  this.props.updateConference(this.state.conference);
                }
              }}
            >
              {this.props.t("buttonText.edit")}
            </Button>
          ) : (
            <Button
              floated="right"
              onClick={() => {
                if (this.checkInput()) {
                  this.props.createNewConference(this.state.conference);
                }
              }}
            >
              {this.props.t("buttonText.create")}
            </Button>
          )}
        </Segment>
      </Segment>
    );
  }

  hasThisExtension = extension => {
    return this.state.conference.conferenceExtensions.some(
      conferenceExtension => conferenceExtension.extensionId === extension.id
    );
  };

  checkInput = () => {
    let inputError = { initialInputErrorState };
    let noError = true;
    if (this.state.conference.name.length < 1) {
      noError = false;
      inputError.name = true;
    }
    if (
      this.state.conference.shortName.length < 5 ||
      this.state.conference.shortName.length > 20 ||
      this.state.conference.shortName.indexOf(" ") >= 0
    ) {
      noError = false;
      inputError.shortName = true;
    }
    if (this.state.conference.location.length < 1) {
      noError = false;
      inputError.location = true;
    }
    //Start date can't be before today...
    if (
      new Date(this.state.conference.startDate).getTime() / 86400000 <=
      Math.floor(new Date().getTime() / 86400000)
    ) {
      noError = false;
      inputError.startDate = true;
    }
    if (
      new Date(this.state.conference.endDate).getTime() <=
      new Date(this.state.conference.startDate).getTime()
    ) {
      noError = false;
      inputError.endDate = true;
    }
    if (this.state.conference.shortDescription.length < 1) {
      noError = false;
      inputError.shortDescription = true;
    }
    if (this.state.conference.longDescription.length < 1) {
      noError = false;
      inputError.longDescription = true;
    }
    if (
      new Date(this.state.conference.submissionDeadline).getTime() >=
      new Date(this.state.conference.startDate).getTime()
    ) {
      noError = false;
      inputError.submissionDeadline = true;
    }
    if (
      this.state.conference.abstractLength < 1 ||
      this.state.conference.abstractLength > 10000
    ) {
      noError = false;
      inputError.abstractLength = true;
    }
    if (
      this.state.conference.fileSize < 1 ||
      this.state.conference.fileSize > 1024
    ) {
      noError = false;
      inputError.fileSize = true;
    }
    if (this.state.conference.conferenceExtensions.length < 1) {
      noError = false;
      inputError.conferenceExtensions = true;
    }
    this.setState({ inputError: inputError });

    return noError;
  };

  handleExtensionChange = (e, { value, checked }) => {
    if (checked) {
      let extension = { extensionId: value };
      this.setState({
        conference: {
          ...this.state.conference,
          conferenceExtensions: [
            ...this.state.conference.conferenceExtensions,
            extension
          ]
        }
      });
    } else {
      this.setState({
        conference: {
          ...this.state.conference,
          conferenceExtensions: this.state.conference.conferenceExtensions.filter(
            conferenceExtension => conferenceExtension.extensionId !== value
          )
        }
      });
    }
  };

  imageChange = img =>
    this.setState({
      conference: {
        ...this.state.conference,
        logo: img
      }
    });

  render() {
    if (this.props.error) {
      return <div>Error! {this.props.error.message}</div>;
    }

    return (
      <div style={{ marginTop: "100px" }}>
        <Suspense fallback={<div>Loading...</div>}>
          <Responsive minWidth={768}>
            <Button
              onClick={() => {
                this.props.setConferenceListTab(0);
                window.history.back();
              }}
            >
              <Icon name="angle left" />
            </Button>
            <Card style={{ width: "100%" }}>
              <Segment>
                <Card.Content>
                  <Grid columns={2}>
                    <Grid.Row>
                      <Grid.Column>
                        {this.renderTitleWithMetaData()}
                        {this.renderContent()}
                      </Grid.Column>

                      <Grid.Column>
                        <ImageSelector
                          placeholder={this.state.conference.logo}
                          imageChangeFunction={this.imageChange}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  {this.renderAdditionalData()}
                </Card.Content>
              </Segment>
            </Card>
          </Responsive>
          <Responsive {...Responsive.onlyMobile}>
            <Button onClick={() => window.history.back()}>
              <Icon name="angle left" />
            </Button>
            <Card centered style={{ width: "100%" }}>
              <div>
                <ImageSelector
                  placeholder={this.state.conference.logo}
                  imageChangeFunction={this.imageChange}
                />
              </div>
              <Card.Content>
                {this.renderTitleWithMetaData()}
                {this.renderContent()}
                {this.renderAdditionalData()}
              </Card.Content>
            </Card>
          </Responsive>
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  extensions: state.conferenceCreateReducer.extensions,
  isLoggedIn: state.loginLogout.loggedIn,
  user: state.loginLogout.user,
  conferences: state.conferenceProfileReducer.conference,
  loading: state.conferenceProfileReducer.loadingConferenceProfile,
  error: state.conferenceProfileReducer.error,
  submissions: state.submissions.submissions
});

const mapDispatchToProps = dispatch => ({
  setConferenceListTab: listTab => setConferenceListTab(dispatch, listTab),
  fetchExtensions: () => fetchExtensions(dispatch),
  createNewConference: conference => createNewConference(dispatch, conference),
  updateConference: conference => updateConference(dispatch, conference),
  unauthorized: () => unauthorized(dispatch)
});

ConferenceCreateAndEditPage.propTypes = {
  conferences: PropTypes.object,
  conferenceToEdit: PropTypes.object,
  t: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  createNewConference: PropTypes.func,
  match: PropTypes.object,
  fetchExtensions: PropTypes.func,
  error: PropTypes.string,
  extensions: PropTypes.array,
  loading: PropTypes.bool,
  index: PropTypes.number,
  setConferenceListTab: PropTypes.func,
  setOpen: PropTypes.func,
  submissions: PropTypes.array,
  componentMode: PropTypes.number,
  updateConference: PropTypes.func,
  conferenceExtensions: PropTypes.array,
  user: PropTypes.object,
  unauthorized: PropTypes.func
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConferenceCreateAndEditPage)
);
