import * as ActionTypes from "../constants/actionTypes";

const initialState = { language: localStorage.getItem("selectedLanguage") };

const setLanguageReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_LANGUAGE:
      return {
        language: action.language
      };

    default:
      return state;
  }
};

export default setLanguageReducer;
