import { authHeader } from "../../common/authHeader";
import * as ActionTypes from "../constants/actionTypes";
import * as ListTypes from "../constants/submissionListTypes";
import { UserEnum } from "../../common/userEnum";
import * as CONFIG from "../../configuration/config.json";
import { fetchWrapper } from "../../common/fetchWrap";
import { alertActions } from "../../alerts/actions";

const fetchSubmissionsBegin = () => ({
  type: ActionTypes.FETCH_SUBMISSIONS_OF_SUPERVISOR_BEGIN
});

export const fetchSubmissionsSuccess = data => ({
  type: ActionTypes.FETCH_SUBMISSIONS_OF_SUPERVISOR,
  submissions: data.submissions
});

export const fetchSubmissionsError = error => ({
  type: ActionTypes.FETCH_SUBMISSIONS_OF_SUPERVISOR_ERROR,
  error
});

const convertTabNameToUserRole = tabName => {
  switch (tabName) {
    case ListTypes.SUPERVISED: {
      return UserEnum.supervisor;
    }
    default: {
      return UserEnum.supervisor;
    }
  }
};

export const fetchSubmissionsBasedOnRole = (dispatch, listType, pageNumber) => {
  let userRole = convertTabNameToUserRole(listType);
  dispatch(fetchSubmissionsBegin());
  fetchWrapper(
    `/api/users/submissions?page=${pageNumber}&limit=${CONFIG.assignedSubmissionsPerPageLimit}&userRole=${userRole}`,
    {
      method: "GET",
      headers: authHeader()
    },
    true
  )
    .then(res => res.json())
    .then(res => {
      fetchNumberOfSubmissionsBasedOnRole(dispatch, listType);
      dispatch(fetchSubmissionsSuccess(res.value));
    })
    .catch(error => {
      dispatch(fetchSubmissionsError(error));
      dispatch(alertActions.error(error));
    });
};

export const fetchNumberOfSubmissionsBasedOnRole = (dispatch, listType) => {
  let userRole = convertTabNameToUserRole(listType);
  fetchWrapper(
    `/api/users/submissionsCount?userRole=${userRole}`,
    {
      method: "GET",
      headers: authHeader()
    },
    true
  )
    .then(res => res.json())
    .then(res => dispatch(setNumberOfSubmissions(res.value)))
    .catch(error => dispatch(alertActions.error(error)));
};

const setNumberOfSubmissions = number => ({
  type: ActionTypes.SET_TOTAL_SUPERVISEDSUBMISSIONS,
  total: number
});

const setCurrent = currentPage => ({
  type: ActionTypes.SET_CURRENT_SUPERVISEDSUBMISSIONS,
  currentPage
});

export const setCurrentPage = (dispatch, current) => {
  dispatch(setCurrent(current));
};

const setListTab = submissionsListTab => ({
  type: ActionTypes.SET_SUPERVISEDSUBMISSIONS_TAB,
  submissionsListTab
});

export const setSubmissionsListTab = (dispatch, listTab) => {
  switch (listTab) {
    case ListTypes.SUPERVISED: {
      dispatch(setListTab(0));
      break;
    }
    default: {
      dispatch(setListTab(0));
      break;
    }
  }
};
