import React, { Component, Suspense } from "react";
import RegisterForm from "./registerForm";
import { Button } from "semantic-ui-react";
import "../style/registerForm.css";
import PropTypes from "prop-types";

class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
  }
  goBack() {
    this.props.history.goBack();
  }
  render() {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <div className="backButton">
          <Button onClick={this.goBack}>&#60;</Button>
        </div>
        <RegisterForm />
      </Suspense>
    );
  }
}

RegisterPage.propTypes = {
  history: PropTypes.object
};

export default RegisterPage;
