import { alertConstants } from "../constants/actionTypes";

export function alert(_, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: "success",
        message: action.message
      };
    case alertConstants.ERROR:
      return {
        type: "error",
        message: action.message
      };
    default:
      return {};
  }
}
