import * as ActionTypes from "../constants/actionTypes";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { loggedIn: true, user, isOpen: false, userId: user.id }
  : { loggedIn: false, isOpen: false };

const loginLogoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case ActionTypes.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
        userId: action.user.id
      };
    case ActionTypes.LOGIN_FAILURE:
      return {};
    case ActionTypes.LOGOUT:
      return { loggedIn: false, isOpen: false };
    case ActionTypes.SETOPEN:
      return { ...state, isOpen: action.isOpen };
    default:
      return state;
  }
};

export default loginLogoutReducer;
