import * as ActionTypes from "../constants/actionTypes";
import { alertActions } from "../../alerts/actions";
import { history } from "../../common/history";
import { authHeader } from "../../common/authHeader";
import { fetchWrapper } from "../../common/fetchWrap";

const fetchConferenceSuccess = data => {
  return {
    type: ActionTypes.GET_PROPERTIES,
    conference: data.conference
  };
};

const fetchConferenceError = error => ({
  type: ActionTypes.GET_PROPERTIES_ERROR,
  error
});

export const getSubmissionProperties = (dispatch, name) => {
  fetchWrapper(
    `/api/conferences/${name}/submission`,
    {
      method: "GET",
      headers: authHeader()
    },
    true
  )
    .then(res => {
      if (!res.ok) {
        dispatch(alertActions.error("alertMessage.somethingWentWrong"));
        history.push("/");
      } else {
        res
          .json()
          .then(res => dispatch(fetchConferenceSuccess(res.value)))
          .catch(error => dispatch(fetchConferenceError(error)));
      }
    })
    .catch(error => dispatch(alertActions.error(error)));
};

export const submitPaper = (dispatch, paper) => {
  dispatch(submittingPaper());
  fetchWrapper(
    `/api/submissions`,
    {
      method: "POST",
      headers: authHeader(),
      body: paper
    },
    true
  )
    .then(res => {
      res.json().then(() => {
        dispatch(submittingTerminated());
        switch (res.status) {
          case 409: {
            dispatch(alertActions.error("alertMessage.submissionFailed"));
            break;
          }
          case 201: {
            dispatch(alertActions.success("alertMessage.submissionSuccess"));
            window.history.back();
            break;
          }
          default: {
            dispatch(alertActions.error("alertMessage.somethingWentWrong"));
          }
        }
      });
    })
    .catch(error => dispatch(alertActions.error(error)));
};

const submittingPaper = () => {
  return {
    type: ActionTypes.SUBMITTING_PAPER
  };
};

const submittingTerminated = () => {
  return {
    type: ActionTypes.SUBMITTING_TERMINATED
  };
};
