import React, { Component } from "react";
import { connect } from "react-redux";
import * as ListTypes from "../constants/conferenceListTypes";
import PropTypes from "prop-types";
import { Tab } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

class ConferenceListTab extends Component {
  render() {
    let panes = [
      {
        menuItem: {
          key: "upcoming",
          content: this.props.t("conferenceList.showUpcoming"),
          as: Link,
          to: "/conferences?listType=" + ListTypes.UPCOMING + "&current=1",
          state: { current: "#current" }
        }
      },
      {
        menuItem: {
          key: "all",
          content: this.props.t("conferenceList.showAll"),
          as: Link,
          to: "/conferences?listType=" + ListTypes.ALL + "&current=1",
          state: { current: "#current" }
        }
      }
    ];
    if (this.props.isLoggedIn) {
      panes = [
        ...panes,
        {
          menuItem: {
            key: "followed",
            content: this.props.t("conferenceList.showFollowed"),
            icon: "star outline",
            as: Link,
            to: "/conferences?listType=" + ListTypes.FOLLOWED + "&current=1"
          }
        }
      ];
    }
    return (
      <div>
        <Tab activeIndex={this.props.conferenceListTab} panes={panes} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.loginLogout.loggedIn,
  conferenceListTab: state.conferences.conferenceListTab
});

ConferenceListTab.propTypes = {
  isLoggedIn: PropTypes.bool,
  t: PropTypes.func,
  conferenceListTab: PropTypes.number
};

export default withTranslation()(
  connect(
    mapStateToProps,
    null
  )(ConferenceListTab)
);
