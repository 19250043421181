import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Form, Image, Input } from "semantic-ui-react";

class ImageSelector extends Component {
  constructor(props) {
    super(props);
    this.state = { image: this.props.placeholder };
  }
  handleImageSubmit = e => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file.type && file.type.indexOf("image") === -1) {
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", event => {
      this.props.imageChangeFunction(event.target.result);
      this.setState({ image: event.target.result });
    });
    reader.readAsDataURL(file);
  };

  render() {
    return (
      <div className="logoImageContainer">
        <Image className="image" centered src={this.state.image} />
        <div className="middle">
          <Form onChange={this.handleImageSubmit}>
            <Form.Field>
              <label>{this.props.t("buttonText.uploadImage")}</label>
              <Input type="file" name="conferenceLogo" />
            </Form.Field>
          </Form>
        </div>
      </div>
    );
  }
}

ImageSelector.propTypes = {
  t: PropTypes.func,
  placeholder: PropTypes.string,
  imageChangeFunction: PropTypes.func
};

export default withTranslation()(ImageSelector);
