import React, { Component } from "react";
import { Button, Form } from "semantic-ui-react";
import "../style/registerForm.css";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { register } from "../actions";

import "semantic-ui-css/semantic.min.css";
import PropTypes from "prop-types";
import { validateEmail, validateName } from "../../common/validator";

class RegisterForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      specError: false,
      institutionError: false,
      departmentError: false,
      yearError: false,
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      passwordError: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    let error = false;
    const data = new FormData(e.target);
    const institution = data.get("institution");
    const department = data.get("department");
    const specialization = data.get("specialization");
    const year = data.get("year");
    const firstName = data.get("firstName");
    const lastName = data.get("lastName");
    const email = data.get("email");
    const password = data.get("password");

    if (!validateName(firstName)) {
      this.setState({ firstNameError: true });
      error = true;
    } else {
      this.setState({ firstNameError: false });
    }
    if (!validateName(lastName)) {
      this.setState({ lastNameError: true });
      error = true;
    } else {
      this.setState({ lastNameError: false });
    }
    this.setState({ institutionError: institution === "" });
    this.setState({ departmentError: department === "" });
    this.setState({ specError: specialization === "" });
    this.setState({ yearError: year === "" });
    if (!validateEmail(email)) {
      error = true;
      this.setState({ emailError: true });
    } else {
      this.setState({ emailError: false });
    }
    if (data.get("password").length < 8) {
      error = true;
      this.setState({ passwordError: true });
    } else {
      this.setState({ passwordError: false });
    }
    if (
      !this.state.specError &&
      !this.state.departmentError &&
      !this.state.yearError &&
      !this.state.fnameError &&
      !this.state.lnameError &&
      !this.state.emailError &&
      !this.state.passwordError &&
      !error
    ) {
      const user = {
        department: department,
        institution: institution,
        specialization: specialization,
        year: year,
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        preferredLanguage: localStorage.getItem("selectedLanguage")
          ? localStorage.getItem("selectedLanguage")
          : "en"
      };
      this.props.register(user);
      this.setState({ showMessage: true });
    }
  }

  render() {
    return (
      <>
        <Form
          className="registerForm"
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
          <Form.Field required error={this.state.firstNameError}>
            <label>{this.props.t("register.firstName")}</label>
            <input
              name="firstName"
              placeholder={this.props.t("register.firstName")}
            />
          </Form.Field>
          {this.state.firstNameError ? (
            <p className="formError">{this.props.t("formError.fname")}</p>
          ) : (
            ""
          )}
          <Form.Field required error={this.state.lastNameError}>
            <label>{this.props.t("register.lastName")}</label>
            <input
              name="lastName"
              placeholder={this.props.t("register.lastName")}
            />
          </Form.Field>
          {this.state.lastNameError ? (
            <p className="formError">{this.props.t("formError.lname")}</p>
          ) : (
            ""
          )}
          <Form.Field required error={this.state.institutionError}>
            <label>{this.props.t("paperSubmission.institution")}</label>
            <input name="institution" />
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field required error={this.state.departmentError}>
              <label>{this.props.t("paperSubmission.department")}</label>
              <input name="department" />
            </Form.Field>
            <Form.Field required error={this.state.specError}>
              <label>{this.props.t("paperSubmission.specialization")}</label>
              <input name="specialization" />
            </Form.Field>
            <Form.Field required error={this.state.yearError}>
              <label>{this.props.t("paperSubmission.year")}</label>
              <input type="number" min={1} max={6} name="year" />
            </Form.Field>
          </Form.Group>
          <Form.Field required error={this.state.emailError}>
            <label>{this.props.t("register.email")}</label>
            <input
              type="email"
              name="email"
              placeholder={this.props.t("register.email")}
            />
          </Form.Field>
          {this.state.emailError ? (
            <p className="formError">{this.props.t("formError.email")}</p>
          ) : (
            ""
          )}
          <Form.Field required error={this.state.passwordError}>
            <label>{this.props.t("register.password")}</label>
            <input
              type="password"
              name="password"
              placeholder={this.props.t("register.password")}
            />
          </Form.Field>
          {this.state.passwordError ? (
            <p className="formError">{this.props.t("formError.password")}</p>
          ) : (
            ""
          )}
          <Button loading={this.props.loading} type="submit">
            {this.props.t("register.submit")}
          </Button>
        </Form>
      </>
    );
  }
}

RegisterForm.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  register: PropTypes.func,
  t: PropTypes.func
};

const mapStateToProps = state => ({
  error: state.register.error,
  loading: state.register.loading
});

const mapDispatchToProps = dispatch => ({
  register: user => register(dispatch, user)
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(RegisterForm)
);
