import React, { Component } from "react";
import User from "./user";
import { connect } from "react-redux";
import * as Actions from "../actions";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import PageSizeDropdown from "../../common/components/PageSizeDropdown";
import { Card, Message, Pagination, Responsive } from "semantic-ui-react";
import "../style/userList.css";
import "../style/paginationComponent.css";
import { history } from "../../common/history";
import { PermissionEnum } from "../../common/permissionEnum";
import { checkPermission } from "../../common/checkPermission";

class UserList extends Component {
  constructor(props) {
    super(props);
    this.onNavigate = this.onNavigate.bind(this);
    this.setPageSize = this.setPageSize.bind(this);

    var canView = false;
    if (this.props.user) {
      canView = checkPermission(
        this.props.user.permissions,
        PermissionEnum.userView
      );
    }

    if (!canView) {
      this.props.unauthorized();
    }
  }
  componentDidMount() {
    this.props.fetchNumberOfUsers();
    if (
      this.props.currentPage < 1 ||
      this.props.currentPage > this.props.limit
    ) {
      this.props.currentPage = 1;
    }
    this.props.fetchUserList(this.props.currentPage, this.props.limit);
  }

  renderCardGroupUsersElement() {
    const { users } = this.props;
    return users.length > 0 ? (
      <div>
        <Card.Group itemsPerRow={1}>
          {users.map(user => (
            <User key={user.id} user={user} />
          ))}
        </Card.Group>
      </div>
    ) : (
      <Message warning>{this.props.t("userList.noResult")}</Message>
    );
  }

  onNavigate(e) {
    if (
      undefined === e.target.attributes.value ||
      undefined === e.target.attributes.value.value
    ) {
      return;
    }
    const newCurrentPage = Number(e.target.attributes.value.value);
    if (this.props.currentPage !== newCurrentPage) {
      this.props.setCurrentPage(newCurrentPage);
      this.props.fetchUserList(newCurrentPage, this.props.limit);
    }
    history.push("/userList?&currentPage=" + newCurrentPage);
  }

  renderPaginationElement() {
    const { limit, currentPage, total } = this.props;
    return (
      <Pagination
        activePage={currentPage}
        totalPages={Math.ceil(total / limit)}
        onClick={this.onNavigate}
        boundaryRange={0}
        siblingRange={1}
        ellipsisItem={null}
      />
    );
  }

  setPageSize(_, data) {
    if (data.value !== this.props.limit) {
      this.props.setPageSize(data.value);
      this.props.fetchUserList(1, data.value);
      this.props.setCurrentPage(1);
    }
  }

  render() {
    const { error, loading, users } = this.props;

    if (error) {
      return <div>Error! {error.message}</div>;
    }

    if (loading) {
      return <div className="loadingDiv">{this.props.t("common.loading")}</div>;
    }
    return (
      <div>
        <Responsive minWidth={768}>
          <div className="userListDiv">
            {users.length > 0 ? (
              <div className="paginationComponentDiv">
                <PageSizeDropdown onChange={this.setPageSize} />

                {this.renderPaginationElement()}
              </div>
            ) : null}
            <div>{this.renderCardGroupUsersElement()}</div>
          </div>
        </Responsive>
        <Responsive {...Responsive.onlyMobile}>
          <div className="userListDivMobile">
            {users.length > 0 ? (
              <div className="paginationComponentDiv">
                <PageSizeDropdown onChange={this.setPageSize} />

                {this.renderPaginationElement()}
              </div>
            ) : null}
            <div>{this.renderCardGroupUsersElement()}</div>
          </div>
        </Responsive>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.loginLogout.user,
  users: state.users.users,
  limit: state.users.limit,
  currentPage: state.users.currentPage,
  total: state.users.total,
  error: state.users.error,
  loading: state.conferences.loading
});

const mapDispatchToProps = dispatch => ({
  fetchUserList: (pageNumber, limit) =>
    Actions.fetchUserList(dispatch, pageNumber, limit),
  fetchNumberOfUsers: () => Actions.fetchNumberOfUsers(dispatch),
  setCurrentPage: currentPage => Actions.setCurrentPage(dispatch, currentPage),
  setPageSize: limit => Actions.setPageSize(dispatch, limit),
  unauthorized: () => Actions.unauthorized(dispatch)
});

UserList.propTypes = {
  setCurrentPage: PropTypes.func,
  setPageSize: PropTypes.func,
  fetchUserList: PropTypes.func,
  fetchNumberOfUsers: PropTypes.func,
  currentPage: PropTypes.number,
  limit: PropTypes.number,
  users: PropTypes.array,
  total: PropTypes.number,
  error: PropTypes.string,
  loading: PropTypes.bool,
  t: PropTypes.func,
  user: PropTypes.object,
  unauthorized: PropTypes.func
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(UserList)
);
