import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // we init with resources
    fallbackLng: "en",
    debug: true,
    ns: ["translation", "localeFormats"],
    interpolation: {
      escapeValue: false
    }
  });

if (!Object.prototype.hasOwnProperty.call(localStorage, "selectedLanguage")) {
  localStorage.setItem("selectedLanguage", "en");
}

i18n.changeLanguage(localStorage.getItem("selectedLanguage"));

export default i18n;
