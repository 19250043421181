import * as actionTypes from "../constants/actionTypes";

const initialState = {
  loading: false,
  newConference: null,
  error: null,
  extensions: []
};

const conferenceCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_OR_UPDATE_CONFERENCE:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.CREATE_OR_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case actionTypes.CREATE_OR_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case actionTypes.FETCH_EXTENSIONS: {
      return {
        ...state,
        extensions: action.extensions
      };
    }
    case actionTypes.FETCH_EXTENSIONS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
    default:
      return state;
  }
};

export default conferenceCreateReducer;
