import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, Checkbox, FormField } from "semantic-ui-react";
import { fetchAllPermissions, updateUserPermissions } from "../actions";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

class UserPermissions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPermissions: Array.from(this.props.user.permissions)
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.props.fetchAllPermissions(props.user.id);
  }

  handleSave(e) {
    e.preventDefault();

    const userPermissions = {
      id: this.props.user.id,
      selectedPermissions: { permissions: this.state.selectedPermissions }
    };

    this.props.updateUserPermissions(userPermissions);
  }

  handleClick(_, data) {
    if (data.checked) {
      this.setState(prevState => ({
        selectedPermissions: [...prevState.selectedPermissions, data.label]
      }));
    } else {
      var array = this.state.selectedPermissions;
      this.setState({
        selectedPermissions: array.filter(x => x !== data.label)
      });
    }
  }

  render() {
    return (
      <div>
        <Form className="border" autoComplete="off" onSubmit={this.handleSave}>
          <FormField>
            <label>{this.props.t("userProfile.permissions")}</label>
          </FormField>
          {this.props.allPermissions !== null
            ? this.props.allPermissions.map(permission => (
                <FormField key={permission}>
                  <Checkbox
                    label={permission}
                    onChange={this.handleClick}
                    checked={
                      this.state.selectedPermissions.indexOf(permission) !== -1
                    }
                  />
                </FormField>
              ))
            : null}
          <Button type="submit" onClick={this.handleSave}>
            {this.props.t("userProfile.save")}
          </Button>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  allPermissions: state.userProfile.allPermissions,
  error: state.userProfile.error,
  user: state.userProfile.user
});

const mapDispatchToProps = dispatch => ({
  fetchAllPermissions: id => fetchAllPermissions(dispatch, id),
  updateUserPermissions: user => updateUserPermissions(dispatch, user)
});

UserPermissions.propTypes = {
  error: PropTypes.string,
  user: PropTypes.object,
  fetchAllPermissions: PropTypes.func,
  updateUserPermissions: PropTypes.func,
  allPermissions: PropTypes.array,
  t: PropTypes.func
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(UserPermissions)
);
