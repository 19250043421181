import * as ActionTypes from "../constants/actionTypes";
import { authHeader } from "../../common/authHeader";
import { fetchWrapper } from "../../common/fetchWrap";

const fetchConferencesBegin = () => ({
  type: ActionTypes.FETCH_CONFERENCES_BEGIN
});

const fetchConferencesSuccess = data => ({
  type: ActionTypes.FETCH_CONFERENCES,
  conferences: data.conferences
});

const fetchConferencesError = error => ({
  type: ActionTypes.FETCH_CONFERENCES_ERROR,
  error
});

export const fetchConferences = (
  dispatch,
  number,
  limit,
  my,
  upcoming,
  isLoggedIn
) => {
  dispatch(fetchConferencesBegin());
  fetchWrapper(
    `/api/conferences?my=${my}&active=${upcoming}&number=${number}&limit=${limit}`,
    {
      method: "GET",
      headers: authHeader()
    },
    isLoggedIn
  )
    .then(res => res.json())
    .then(res => dispatch(fetchConferencesSuccess(res.value)))
    .catch(error => dispatch(fetchConferencesError(error)));
};

const setFilter = name => ({
  type: ActionTypes.SET_FILTER,
  filter: true,
  filterBy: name
});

export const setFilterConferences = (dispatch, name) => {
  dispatch(setFilter(name));
};

const filterConferencesSuccess = data => ({
  type: ActionTypes.FILTER_CONFERENCES,
  conferences: data.conferences
});

export const filterConferences = (
  dispatch,
  name,
  number,
  limit,
  my,
  upcoming,
  isLoggedIn
) => {
  fetchWrapper(
    `/api/conferences?my=${my}&name=${name}&number=${number}&limit=${limit}&active=${upcoming}`,
    {
      method: "GET",
      headers: authHeader()
    },
    isLoggedIn
  )
    .then(res => res.json())
    .then(res => dispatch(filterConferencesSuccess(res.value)))
    .catch(error => dispatch(fetchConferencesError(error)));
};

const removeFilter = () => ({
  type: ActionTypes.REMOVE_FILTER
});

export const removeFilterConferences = dispatch => {
  dispatch(removeFilter());
};

const setNumberOfConferences = number => ({
  type: ActionTypes.SET_TOTAL,
  total: number
});

export const fetchNumberOfConferences = (dispatch, name, my, upcoming) => {
  fetchWrapper(
    `/api/conferences/count?name=${name}&my=${my}&active=${upcoming}`,
    {
      method: "GET",
      headers: authHeader()
    }
  )
    .then(res => res.json())
    .then(res => dispatch(setNumberOfConferences(res.value)))
    .catch(error => dispatch(fetchConferencesError(error)));
};

const setCurrent = current => ({
  type: ActionTypes.SET_CURRENT,
  current
});

export const setCurrentPage = (dispatch, current) => {
  dispatch(setCurrent(current));
};

const setLimit = limit => ({
  type: ActionTypes.SET_LIMIT,
  limit
});

export const setPageSize = (dispatch, limit) => {
  dispatch(setLimit(limit));
};

const setListTab = conferenceListTab => ({
  type: ActionTypes.SET_CONFERECE_LIST_TAB,
  conferenceListTab
});

export const setConferenceListTab = (dispatch, listTab) => {
  dispatch(setListTab(listTab));
};
