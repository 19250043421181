import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class PageSizeDropdown extends Component {
  render() {
    const possiblePageSizes = [
      { key: 1, text: this.props.t("pageSize.3perPage"), value: 3 },
      { key: 2, text: this.props.t("pageSize.5perPage"), value: 5 }
    ];
    return (
      <Dropdown
        placeholder={this.props.t(`pageSize.${this.props.limit}perPage`)}
        closeOnBlur
        selection
        options={possiblePageSizes}
        className="pageSize"
        onChange={this.props.onChange}
      />
    );
  }
}

const mapStateToProps = state => ({
  limit: state.conferences.limit
});

PageSizeDropdown.propTypes = {
  t: PropTypes.func,
  limit: PropTypes.number,
  onChange: PropTypes.func
};

export default withTranslation()(connect(mapStateToProps)(PageSizeDropdown));
