import * as actionTypes from "../constants/actionTypes";

const initialState = {
  submissionWithDetail: null,
  loading: true,
  error: null
};

const submissionDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SUBMISSION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.FETCH_SUBMISSION_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
        submissionWithDetail: null
      };
    }
    case actionTypes.FETCH_SUBMISSION: {
      return {
        ...state,
        loading: false,
        submissionWithDetail: action.submission
      };
    }
    case actionTypes.FETCH_FILE_ERROR: {
      return {
        ...state,
        error: action.error
      };
    }
    case actionTypes.WITHDRAW_SUBMISSION: {
      return {
        ...state,
        error: null
      };
    }
    default:
      return state;
  }
};
export default submissionDetailsReducer;
