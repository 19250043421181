import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import ConferenceCreateAndEditPage, {
  ManageConferenceModeEnum
} from "./conferenceCreateAndEditPage";
import { fetchConference } from "../../conferenceProfile/actions";

class ConferenceEditPage extends Component {
  componentDidMount() {
    this.props.fetchConference(
      this.props.match.params.conferenceShortName,
      this.props.user.id
    );
  }
  render() {
    if (this.props.conference !== null) {
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <ConferenceCreateAndEditPage
            conferenceToEdit={this.props.conference}
            conferenceExtensions={this.props.conference.conferenceExtensions}
            componentMode={ManageConferenceModeEnum.Edit}
          />
        </Suspense>
      );
    } else {
      return <>loading</>;
    }
  }
}

const mapStateToProps = state => ({
  conference: state.conferenceProfileReducer.conference,
  user: state.loginLogout.user
});

const mapDispatchToProps = dispatch => ({
  fetchConference: (name, id) => fetchConference(dispatch, name, id)
});

ConferenceEditPage.propTypes = {
  match: PropTypes.object,
  fetchConference: PropTypes.func,
  conference: PropTypes.object,
  conferenceExtensions: PropTypes.array,
  user: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(ConferenceEditPage);
