import * as ActionTypes from "../constants/actionTypes";
import { authHeader } from "../../common/authHeader";
import { alertActions } from "../../alerts/actions";
import { fetchWrapper } from "../../common/fetchWrap";

const fetchSubmissionBegin = () => ({
  type: ActionTypes.FETCH_SUBMISSION_BEGIN
});

export const fetchSubmissionSuccess = data => ({
  type: ActionTypes.FETCH_SUBMISSION,
  submission: data.submission
});

export const fetchSubmissionError = error => ({
  type: ActionTypes.FETCH_SUBMISSION_ERROR,
  error
});

export const fetchFileError = error => ({
  type: ActionTypes.FETCH_FILE_ERROR,
  error
});

const withdrawSubmissionAction = submissionId => {
  return {
    type: ActionTypes.WITHDRAW_SUBMISSION,
    submissionId: submissionId
  };
};

export const fetchSubmission = (dispatch, id) => {
  dispatch(fetchSubmissionBegin());
  fetchWrapper(
    `/api/submissions/${id}`,
    {
      method: "GET",
      headers: authHeader()
    },
    true
  )
    .then(res => res.json())
    .then(res => {
      dispatch(fetchSubmissionSuccess(res.value));
    })
    .catch(error => {
      dispatch(fetchSubmissionError(error));
      dispatch(alertActions.error(error));
    });
};

export const fetchFile = (dispatch, id, fileName) => {
  fetchWrapper(
    `/api/submissions/${id}/file`,
    {
      method: "GET",
      headers: authHeader()
    },
    true
  )
    .then(response => {
      response.blob().then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    })
    .catch(error => {
      dispatch(fetchFileError(error));
      dispatch(alertActions.error(error));
    });
};

export const withdrawSubmission = (dispatch, submissionId) => {
  fetchWrapper(
    `/api/submissions/${submissionId}`,
    {
      method: "DELETE",
      headers: authHeader()
    },
    true
  )
    .then(res => {
      switch (res.status) {
        case 204: {
          dispatch(withdrawSubmissionAction(submissionId));
          dispatch(alertActions.success("alertMessage.submissionWithdrawn"));
          window.location.href = "/submissions";
          break;
        }
        case 400: {
          dispatch(alertActions.error("alertMessage.submissionNotWithdrawn"));
          break;
        }
        case 403: {
          dispatch(alertActions.error("alertMessage.userNoRight"));
          break;
        }
        default: {
          dispatch(alertActions.error("alertMessage.submissionWithdrawnError"));
          break;
        }
      }
    })
    .catch(error => dispatch(alertActions.error(error)));
};
