import React, { Component } from "react";
import { Card, Grid } from "semantic-ui-react";
import PropTypes from "prop-types";
import { history } from "../../common/history";
import { withTranslation } from "react-i18next";
import { orderNames } from "../../common/nameLocalization";

class User extends Component {
  navigate = () => {
    history.push("/profile/" + this.props.user.id);
  };
  render() {
    const user = this.props.user;
    return (
      <Card>
        <Card.Content>
          <Card.Header>
            <Grid.Column
              onClick={() => this.navigate()}
              style={{ cursor: "pointer" }}
            >
              {orderNames(
                  this.props.user.firstName,
                  this.props.user.lastName,
                  this.props.t("localeFormats:namePattern")
                )}
            </Grid.Column>
          </Card.Header>
        </Card.Content>
        <Card.Content>
          {user.email}
          <br />
          {this.props.t("userProfile.institution")}: {user.institution}
          <br />
          {this.props.t("userProfile.department")}: {user.department}
          <br />
          {this.props.t("userProfile.specialization")}: {user.specialization}
          <br />
          {this.props.t("userProfile.year")}: {user.year} 
          <br />
        </Card.Content>
      </Card>
    );
  }
}

User.propTypes = {
  user: PropTypes.object,
  t: PropTypes.func
};

export default withTranslation()(User);
