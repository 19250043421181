import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

class Rejected extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        width="48px"
        height="48px"
      >
        <path
          fill="#d50000"
          d="M5.7 22H42.5V26H5.7z"
          transform="rotate(-45.001 24.036 24.037)"
        />
        <path
          fill="#d50000"
          d="M24,4C13,4,4,13,4,24s9,20,20,20s20-9,20-20S35,4,24,4z M24,40c-8.8,0-16-7.2-16-16S15.2,8,24,8 s16,7.2,16,16S32.8,40,24,40z"
        />
        <title>{this.props.t("submissionStatus.rejected")}</title>
      </svg>
    );
  }
}

Rejected.propTypes = {
  t: PropTypes.func
};

export default withTranslation()(connect()(Rejected));
