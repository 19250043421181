import React, { Component } from "react";
import Search from "./search";
import LanguageDropdown from "./LanguageDropdown";
import { Menu, Image, Responsive } from "semantic-ui-react";
import LoginLogout from "../../loginLogout/components/loginLogout";

class TopBar extends Component {
  render() {
    return (
      <div>
        <Menu fixed="top">
          <Menu.Menu position="left">
            <Menu.Item>
              <Responsive minWidth={768}>
                <Image
                  as="a"
                  href="/conferences"
                  size="medium"
                  src={"/resources/img/scones_logo_1.1.png"}
                />
              </Responsive>
              <Responsive {...Responsive.onlyMobile}>
                <Image
                  as="a"
                  href="/conferences"
                  size="tiny"
                  src={"/resources/img/scones_logo_1.1_mobil.png"}
                />
              </Responsive>
            </Menu.Item>
          </Menu.Menu>
          <Menu.Menu position="right">
            <Menu.Item>
              <Search />
            </Menu.Item>
            <Menu.Item>
              <LanguageDropdown />
            </Menu.Item>
            <Menu.Item>
              <LoginLogout />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      </div>
    );
  }
}

export default TopBar;
