export const FETCH_CONFERENCE = "FETCH_CONFERENCE";
export const FETCH_CONFERENCE_BEGIN = "FETCH_CONFERENCE_BEGIN";
export const FETCH_CONFERENCE_ERROR = "FETCH_CONFERENCE_ERROR";
export const ADD_TRACK_TO_LIST = "ADD_TRACK_TO_LIST";
export const REMOVE_TRACK_FROM_LIST = "REMOVE_TRACK_FROM_LIST";
export const UPDATE_TRACK_LIST = "UPDATE_TRACK_LIST";
export const FETCH_EXTENSIONS = "FETCH_EXTENSIONS";
export const GET_USERS = "GET_USERS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";
export const LOAD_COMMITTEE = "LOAD_COMMITTEE";
