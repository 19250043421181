import React, { Component } from "react";
import { Table, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { history } from "../../common/history";
import ManageTrack, { ManageTrackModeEnum } from "./manageTrack";
import DeleteTrack from "./deleteTrack";
import MomentWithUtc from "../../common/components/momentWithUtc";

class TrackDesktopViewTableRow extends Component {
  render() {
    return (
      <>
        <Table.Row>
          <Table.Cell>{this.props.track.name}</Table.Cell>
          <Table.Cell>
            <MomentWithUtc
              format={"dateFormat"}
              date={this.props.track.submissionDeadline}
            />
            &nbsp;
            <MomentWithUtc
              format={"timeFormat"}
              date={this.props.track.submissionDeadline}
            />
          </Table.Cell>
          <Table.Cell>{this.props.track.abstractLength}</Table.Cell>
          <Table.Cell>{this.props.track.fileSize}</Table.Cell>
          {this.props.isOrganizer || this.props.isCommitteeMember ? (
            <Table.Cell textAlign="center">
              <Button
                onClick={() =>
                  history.push(
                    `/conferences/${this.props.conferenceId}/tracks/${this.props.track.id}/submissions`
                  )
                }
              >
                {this.props.t("conferenceProfile.listSubmissions")}
              </Button>
            </Table.Cell>
          ) : this.props.committeeMemberAtCount > 0 ? (
            <Table.Cell textAlign="center">
              {this.props.t("conferenceProfile.notCommittee")}
            </Table.Cell>
          ) : null}
          {this.props.isOrganizer ? (
            <Table.Cell textAlign="center">
              {/* If organizer Edit/Delete track component: */}
              <ManageTrack
                componentMode={ManageTrackModeEnum.Edit}
                track={{ ...this.props.track }}
                trackId={this.props.track.id}
              />
              <DeleteTrack
                trackName={this.props.track.name}
                trackId={this.props.track.id}
              />
            </Table.Cell>
          ) : null}
        </Table.Row>
      </>
    );
  }
}

TrackDesktopViewTableRow.propTypes = {
  committeeMemberAt: PropTypes.array,
  committeeMemberAtCount: PropTypes.number,
  conferenceId: PropTypes.string,
  isCommitteeMember: PropTypes.bool,
  isOrganizer: PropTypes.bool,
  t: PropTypes.func,
  track: PropTypes.object
};

const mapStateToProps = state => ({
  conferenceId: state.conferenceProfileReducer.conference.id
});

export default withTranslation()(
  connect(mapStateToProps)(TrackDesktopViewTableRow)
);
