import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Card, Message, Responsive, Pagination } from "semantic-ui-react";
import Submission from "./submission";
import {
  fetchSubmissionsBasedOnRole,
  setCurrentPage,
  setSubmissionsListTab
} from "../actions";
import SubmissionsListTab from "./submissionsTab";
import * as ListTypes from "../constants/submissionListTypes";
import { history } from "../../common/history";
import queryString from "query-string";
import * as CONFIG from "../../configuration/config.json";

class AssignedSubmissionsPage extends Component {
  constructor(props) {
    super(props);
    this.onPaginationClick = this.onPaginationClick.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.values = queryString.parse(this.props.location.search);
    this.listType = this.values.listType;
    if (undefined === this.listType) {
      this.listType = ListTypes.DEFAULT;
    } else {
      this.props.setListTab(this.listType);
    }

    let currentPage = parseInt(this.values.page);
    if (currentPage === undefined || isNaN(currentPage)) {
      currentPage = this.props.currentPage;
    } else {
      this.props.setCurrentPage(currentPage);
    }

    this.props.fetchSubmissionsBasedOnRole(this.listType, currentPage);
  }

  componentDidUpdate(prevState) {
    this.values = queryString.parse(this.props.location.search);
    this.listType = this.values.listType;
    if (undefined === this.listType) {
      this.listType = ListTypes.DEFAULT;
    }
    let prevTab = queryString.parse(prevState.location.search).listType;
    if (undefined === prevTab) {
      prevTab = ListTypes.DEFAULT;
    }
    if (this.listType !== prevTab) {
      this.props.setListTab(this.listType);
      this.props.setCurrentPage(1);
      this.props.fetchSubmissionsBasedOnRole(this.listType, 1);
    }
  }

  onPaginationClick(e) {
    if (
      undefined === e.target.attributes.value ||
      undefined === e.target.attributes.value.value
    ) {
      return;
    }
    const newCurrentPage = Number(e.target.attributes.value.value);

    if (this.props.currentPage !== newCurrentPage) {
      this.props.setCurrentPage(newCurrentPage);

      this.props.fetchSubmissionsBasedOnRole(this.listType, newCurrentPage);
    }
    history.push(
      "/assignedSubmissions?listType=" +
        this.listType +
        "&page=" +
        newCurrentPage
    );
  }

  renderPaginationElement() {
    const { currentPage, numberOfSubmissions } = this.props;
    return (
      <Pagination
        activePage={currentPage}
        totalPages={Math.ceil(
          numberOfSubmissions / CONFIG.assignedSubmissionsPerPageLimit
        )}
        onClick={this.onPaginationClick}
        boundaryRange={0}
        siblingRange={1}
        ellipsisItem={null}
      />
    );
  }

  renderCardGroupSubmissionsElement() {
    const { submissions } = this.props;
    return submissions.length > 0 ? (
      <Card.Group itemsPerRow={1}>
        {submissions.map((submission, index) => (
          <Submission key={index} index={index} submission={submission} />
        ))}
      </Card.Group>
    ) : (
      <Message warning>{this.props.t("mySubmission.noResult")}</Message>
    );
  }

  render() {
    const { error, loading } = this.props;

    if (error) {
      return <div>Error! {error.message}</div>;
    }

    if (loading) {
      return <div className="loadingDiv">{this.props.t("common.loading")}</div>;
    }

    return (
      <>
        <Responsive>
          <div className="submissionListDiv">
            <SubmissionsListTab />
            {this.props.submissions.length > 0 ? (
              <div className="paginationElementDiv">
                {this.renderPaginationElement()}
              </div>
            ) : null}
            {this.renderCardGroupSubmissionsElement()}
          </div>
        </Responsive>
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentPage: state.assignedSubmissions.currentPage,
  error: state.assignedSubmissions.error,
  loading: state.assignedSubmissions.loading,
  submissions: state.assignedSubmissions.submissions,
  listType: state.assignedSubmissions.submissionsListTab,
  numberOfSubmissions: state.assignedSubmissions.total
});

const mapDispatchToProps = dispatch => ({
  fetchSubmissionsBasedOnRole: (listType, pageNumber) => {
    fetchSubmissionsBasedOnRole(dispatch, listType, pageNumber);
  },
  setCurrentPage: currentPage => setCurrentPage(dispatch, currentPage),
  setListTab: listType => setSubmissionsListTab(dispatch, listType)
});

AssignedSubmissionsPage.propTypes = {
  currentPage: PropTypes.number,
  error: PropTypes.string,
  fetchSubmissionsBasedOnRole: PropTypes.func,
  loading: PropTypes.bool,
  location: PropTypes.object,
  numberOfSubmissions: PropTypes.number,
  setCurrentPage: PropTypes.func,
  setListTab: PropTypes.func,
  submissions: PropTypes.array,
  t: PropTypes.func,
  total: PropTypes.number
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AssignedSubmissionsPage)
);
