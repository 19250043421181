import * as ActionTypes from "../constants/actionTypes";
import { authHeader } from "../../common/authHeader";
import { perPageLimit } from "../constants/perPageLimit";
import { fetchWrapper } from "../../common/fetchWrap";
import { alertActions } from "../../alerts/actions";

const fetchTrackSubmissionsBegin = () => ({
  type: ActionTypes.FETCH_TRACKSUBMISSIONS_BEGIN
});

export const fetchTrackSubmissionsSuccess = data => ({
  type: ActionTypes.FETCH_TRACKSUBMISSIONS,
  submissions: data.submissions
});

export const fetchTrackSubmissionsError = error => ({
  type: ActionTypes.FETCH_TRACKSUBMISSIONS_ERROR,
  error
});

export const fetchTrackSubmissions = (
  dispatch,
  conferenceId,
  trackId,
  pageNumber
) => {
  dispatch(fetchTrackSubmissionsBegin());
  fetchWrapper(
    `/api/conferences/${conferenceId}/tracks/${trackId}/submissions?page=${pageNumber}&limit=${perPageLimit}`,
    {
      method: "GET",
      headers: authHeader()
    },
    true
  )
    .then(res => res.json())
    .then(res => {
      fetchNumberOfTrackSubmissions(dispatch, conferenceId, trackId);
      dispatch(fetchTrackSubmissionsSuccess(res.value));
    })
    .catch(error => {
      dispatch(fetchTrackSubmissionsError(error));
      dispatch(alertActions.error(error));
    });
};

const setNumberOfSubmissions = number => ({
  type: ActionTypes.SET_TOTAL_TRACKSUBMISSIONS,
  total: number
});

export const fetchNumberOfTrackSubmissions = (
  dispatch,
  conferenceId,
  trackId
) => {
  fetchWrapper(
    `/api/conferences/${conferenceId}/tracks/${trackId}/count`,
    {
      method: "GET",
      headers: authHeader()
    },
    true
  )
    .then(res => res.json())
    .then(res => dispatch(setNumberOfSubmissions(res.value)))
    .catch(error => {
      dispatch(fetchTrackSubmissionsError(error));
      dispatch(alertActions.error(error));
    });
};

const setCurrent = currentPage => ({
  type: ActionTypes.SET_CURRENT_TRACKSUBMISSIONS,
  currentPage
});

export const setCurrentPage = (dispatch, current) => {
  dispatch(setCurrent(current));
};

export const assignReviewer = (dispatch, submissionId, trackId) => {
  const headers = Object.assign(authHeader(), {
    "Content-Type": "application/json"
  });
  fetchWrapper(
    `/api/submissions/${submissionId}`,
    {
      method: "PATCH",
      headers,
      body: JSON.stringify(trackId)
    },
    true
  )
    .then(res => {
      if (!res.ok) {
        dispatch(alertActions.error("alertMessage.couldntAssignReviewer"));
        return;
      }
      dispatch(alertActions.success("alertMessage.assignedReviewer"));
    })
    .catch(error => dispatch(alertActions.error(error)));
};
