import React, { Component } from "react";
import { Button, Form, Dropdown, Responsive, Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import "../style/submissionForm.css";
import { getSubmissionProperties, submitPaper } from "../actions";
import { getUsers } from "../../common/actions";
import _lodash from "lodash";
import DropdownSelector from "../../common/components/dropdownSelector";
import "semantic-ui-css/semantic.min.css";
import PropTypes from "prop-types";

import {
  validateName as isValidName,
  validateAbstract as isValidAbstract
} from "../../common/validator";

class SubmissionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      abstractError: false,
      abstractLength: "",
      currentExtension: [],
      currentTrack: "",
      currentTrackId: "",
      coAuthorsError: false,
      coAuthorsToSave: [],
      extensions: [],
      fileError: false,
      fileExtensionError: false,
      fileSize: "",
      firstNameError: false,
      lastNameError: false,
      supervisorsError: false,
      supervisorsToSave: [],
      titleError: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setTracks = this.setTracks.bind(this);
    this.handleTrackChange = this.handleTrackChange.bind(this);
    this.searchCoAuthors = this.searchCoAuthors.bind(this);
    this.onCoAuthorChange = this.onCoAuthorChange.bind(this);
    this.searchSupervisors = this.searchSupervisors.bind(this);
    this.onSupervisorChange = this.onSupervisorChange.bind(this);
  }

  componentDidMount() {
    this.props.getSubmissionProperties(
      this.props.match.params.conferenceShortName
    );
  }

  hasCollision(arrayOne, arrayTwo) {
    return (
      _lodash.intersectionWith(arrayOne, arrayTwo, _lodash.isEqual).length > 0
    );
  }

  searchCoAuthors(e) {
    if (e.target.value.length > 2) {
      this.props.getCoAuthors(e.target.value);
    }
  }

  onCoAuthorChange(_, { value }) {
    let users = value.map(val => JSON.parse(val));
    if (
      this.hasCollision(
        this.state.supervisorsToSave.map(user => user.email),
        users.map(user => user.email)
      )
    ) {
      this.setState({ coAuthorsError: true, supervisorsError: true });
    } else if (
      this.hasCollision(
        [this.props.loggedInUser.email],
        users.map(user => user.email)
      )
    ) {
      this.setState({ coAuthorsError: true });
    } else {
      this.setState({
        coAuthorsToSave: users,
        coAuthorsError: false,
        supervisorsError: false
      });
    }
  }

  searchSupervisors(e) {
    if (e.target.value.length > 2) {
      this.props.getSupervisors(e.target.value);
    }
  }

  onSupervisorChange(_, { value }) {
    let users = value.map(val => JSON.parse(val));
    if (
      this.hasCollision(
        this.state.coAuthorsToSave.map(user => user.email),
        users.map(user => user.email)
      )
    ) {
      this.setState({ supervisorsError: true, coAuthorsError: true });
    } else if (
      this.hasCollision(
        [this.props.loggedInUser.email],
        users.map(user => user.email)
      )
    ) {
      this.setState({ supervisorsError: true });
    } else {
      this.setState({
        supervisorsToSave: users,
        supervisorsError: false,
        coAuthorsError: false
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    let noError = true;
    const data = new FormData(e.target);
    let firstName = data.get("firstName");
    if (firstName === "") firstName = this.props.loggedInUser.firstName;
    if (!isValidName(firstName)) {
      this.setState({ firstNameError: true });
      noError = false;
    }
    let lastName = data.get("lastName");
    if (lastName === "") lastName = this.props.loggedInUser.lastName;
    if (!isValidName(lastName)) {
      this.setState({ lastNameError: true });
      noError = false;
    }
    if (
      this.props.supervisors === undefined ||
      this.props.supervisors.length === 0
    ) {
      this.setState({ supervisorsError: true });
      noError = false;
    }
    const track = this.state.currentTrack;
    const title = data.get("title");
    const abstract = data.get("abstract");
    const file = data.get("file");

    this.setState({ firstNameError: !isValidName(firstName) });
    this.setState({ lastNameError: !isValidName(lastName) });
    this.setState({
      abstractError: !isValidAbstract(
        abstract.length,
        this.state.abstractLength
      )
    });
    this.setState({ trackError: track === "" });
    this.setState({ titleError: title === "" });

    if (file.size === 0) {
      this.setState({ fileError: true });
    } else {
      this.setState({ fileError: false });
      let goodFileExtension = false;
      for (var extension of this.state.currentExtensions) {
        if (file.name.toLowerCase().includes(extension.toLowerCase())) {
          goodFileExtension = true;
          break;
        }
      }
      if (!goodFileExtension) {
        noError = false;
        this.setState({ fileExtensionError: true });
      }
    }

    if (
      !this.state.abstractError &&
      !this.state.firstNameError &&
      !this.state.lastNameError &&
      !this.state.fileError &&
      !this.state.fileExtensionError &&
      !this.state.titleError &&
      !this.state.trackErrors &&
      !this.state.coAuthorsError &&
      !this.state.supervisorsError &&
      noError
    ) {
      data.append("trackId", this.state.currentTrackId);
      data.append(
        "conferenceShortName",
        this.props.match.params.conferenceShortName
      );
      if (this.props.coAuthors !== undefined) {
        data.append(
          "coAuthors",
          this.state.coAuthorsToSave.map(user => user.email)
        );
      }
      data.append(
        "supervisors",
        this.state.supervisorsToSave.map(user => user.email)
      );
      data.append("fileName", file.name);

      this.props.submitPaper(data);
    }
  }

  setTracks() {
    let tracks;
    if (this.props.tracks) {
      tracks = this.props.conference.tracks.map((t, index) => ({
        key: index,
        text: t.name,
        id: t.id,
        value: this.props.conference.tracks.indexOf(t),
        disabled: !t.active
      }));
      return tracks;
    }
    return (tracks = []);
  }

  handleTrackChange(_, data) {
    const names = this.props.conference.tracks
      .find(track => track.id === data.options[data.value].id)
      .trackExtensions.map(trackExtension => trackExtension.name);

    this.setState({ currentExtensions: names });

    const extensions = [].concat.apply([], names);
    this.setState({ currentExtension: extensions });

    this.setState({ currentTrackId: data.options[data.value].id });
    this.setState({ currentTrack: data.value });
    const trackFile = this.props.tracks[data.value].fileSize;
    const trackAbstract = this.props.tracks[data.value].abstractLength;
    if (trackFile !== null) this.setState({ fileSize: trackFile });
    else this.setState({ fileSize: this.props.conference.fileSize });
    if (trackAbstract !== null)
      this.setState({ abstractLength: trackAbstract });
    else
      this.setState({ abstractLength: this.props.conference.abstractLength });
  }

  renderForm(style) {
    return (
      <>
        <Button
          onClick={() => {
            window.history.back();
          }}
        >
          <Icon name="angle left" />
        </Button>
        <Form className={style} autoComplete="off" onSubmit={this.handleSubmit}>
          <Form.Field error={this.state.firstNameError}>
            <label>{this.props.t("paperSubmission.firstName")}</label>
            <input
              name="firstName"
              placeholder={this.props.loggedInUser.firstName}
            />
          </Form.Field>
          <Form.Field error={this.state.lastNameError}>
            <label>{this.props.t("paperSubmission.lastName")}</label>
            <input
              name="lastName"
              placeholder={this.props.loggedInUser.lastName}
            />
          </Form.Field>
          <DropdownSelector
            label={this.props.t("paperSubmission.coAuthors")}
            error={this.state.coAuthorsError}
            elements={this.props.coAuthors}
            searchElements={this.searchCoAuthors}
            elementChange={this.onCoAuthorChange}
          />
          <DropdownSelector
            required
            label={this.props.t("paperSubmission.supervisor")}
            error={this.state.supervisorsError}
            elements={this.props.supervisors}
            searchElements={this.searchSupervisors}
            elementChange={this.onSupervisorChange}
          />
          <Form.Field required error={this.state.trackError}>
            <label>{this.props.t("paperSubmission.track")}</label>
            <Dropdown
              fluid
              search
              selection
              options={this.setTracks()}
              onChange={this.handleTrackChange}
            />
          </Form.Field>
          <Form.Field required error={this.state.titleError}>
            <label>{this.props.t("paperSubmission.title")}</label>
            <input
              name="title"
              onChange={() => this.setState({ titleError: false })}
            />
          </Form.Field>
          <Form.TextArea
            error={this.state.abstractError}
            name="abstract"
            required
            label={this.props.t("paperSubmission.abstract")}
          />
          <Form.Field
            required
            error={this.state.fileError || this.state.fileExtensionError}
            disabled={this.state.currentTrack === ""}
          >
            <label>{this.props.t("paperSubmission.file")}</label>
            <input
              type="file"
              name="file"
              onChange={() => {
                this.setState({ fileError: false, fileExtensionError: false });
              }}
            />
          </Form.Field>
          {this.state.currentTrack !== "" ? (
            <p>
              {this.props.t("paperSubmission.extensions")}:{" "}
              {this.state.currentExtensions.map(extension => extension + " ")}
            </p>
          ) : (
            ""
          )}
          <Button loading={this.props.submittingLoading} type="submit">
            {this.props.t("paperSubmission.submit")}
          </Button>
        </Form>
      </>
    );
  }

  render() {
    return (
      <div style={{ marginTop: "100px" }}>
        <Responsive minWidth={768}>
          {this.renderForm("submissionForm")}
        </Responsive>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderForm("submissionFormMobile")}
        </Responsive>
      </div>
    );
  }
}

SubmissionForm.propTypes = {
  coAuthors: PropTypes.array,
  conference: PropTypes.object,
  getCoAuthors: PropTypes.func,
  getSubmissionProperties: PropTypes.func,
  getSupervisors: PropTypes.func,
  loggedInUser: PropTypes.object,
  match: PropTypes.object,
  submitPaper: PropTypes.func,
  submittingLoading: PropTypes.bool,
  supervisors: PropTypes.array,
  t: PropTypes.func,
  tracks: PropTypes.array
};

const mapStateToProps = state => ({
  coAuthors: state.paperSubmission.coAuthors,
  conference: state.paperSubmission.conference,
  loggedInUser: state.loginLogout.user,
  submittingLoading: state.paperSubmission.loading,
  supervisors: state.paperSubmission.supervisors,
  tracks: state.paperSubmission.conference.tracks
});

const mapDispatchToProps = dispatch => ({
  getCoAuthors: substring => getUsers(dispatch, substring, "coAuthor"),
  getSubmissionProperties: shortName =>
    getSubmissionProperties(dispatch, shortName),
  getSupervisors: substring => getUsers(dispatch, substring, "supervisor"),
  submitPaper: paper => submitPaper(dispatch, paper)
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(SubmissionForm)
);
