import * as ActionTypes from "../constants/actionTypes";
import { alertActions } from "../../alerts/actions";
import { authHeader } from "../../common/authHeader";
import { history } from "../../common/history";
import { fetchWrapper } from "../../common/fetchWrap";

export const unauthorized = dispatch => {
  dispatch(alertActions.error("alertMessage.unauthorized"));
  history.push("/");
};

export const createNewConference = (dispatch, conference) => {
  dispatch(request());
  let header = Object.assign(authHeader(), {
    "Content-Type": "application/json"
  });
  fetchWrapper(
    `/api/conferences/`,
    {
      method: "POST",
      headers: header,
      body: JSON.stringify(conference)
    },
    true
  )
    .then(res => {
      switch (res.status) {
        case 201: {
          dispatch(alertActions.success("alertMessage.conferenceCreated"));
          dispatch(success());
          history.push(`/conferences/${conference.shortName}`);
          break;
        }
        case 403: {
          dispatch(alertActions.error("alertMessage.userNoRight"));
          history.push("/conferences");
          break;
        }
        default: {
          dispatch(alertActions.error("alertMessage.conferenceCreationError"));
          res.json().then(res => dispatch(failure(res.errors)));
          break;
        }
      }
    })
    .catch(error => dispatch(alertActions.error(error)));
};

export const updateConference = (dispatch, conference) => {
  dispatch(request());
  let header = Object.assign(authHeader(), {
    "Content-Type": "application/json"
  });
  fetchWrapper(
    `/api/conferences/${conference.id}`,
    {
      method: "PATCH",
      headers: header,
      body: JSON.stringify(conference)
    },
    true
  )
    .then(res => {
      switch (res.status) {
        case 200: {
          dispatch(alertActions.success("alertMessage.conferenceUpdated"));
          dispatch(success());
          history.push(`/conferences/${conference.shortName}`);

          break;
        }
        case 403: {
          dispatch(alertActions.error("alertMessage.userNoRight"));
          dispatch(failure("User has no right"));
          break;
        }
        default: {
          dispatch(alertActions.error("alertMessage.conferenceUpdateError"));
          dispatch(failure());
          break;
        }
      }
    })
    .catch(error => dispatch(alertActions.error(error)));
};

export const fetchExtensions = dispatch => {
  fetchWrapper(`/api/extensions`, {
    method: "GET",
    headers: authHeader()
  })
    .then(res => {
      if (res.ok) {
        res.json().then(res => dispatch(fetchExtensionsSuccess(res.value)));
      } else {
        dispatch(alertActions.error("alertMessage.fetchExtensionError"));
      }
    })
    .catch(error => dispatch(fetchExtensionsError(error)));
};

const fetchExtensionsSuccess = data => {
  return {
    type: ActionTypes.FETCH_EXTENSIONS,
    extensions: data.extensions
  };
};

const fetchExtensionsError = error => {
  return {
    type: ActionTypes.FETCH_EXTENSIONS_ERROR,
    error
  };
};

const request = () => ({
  type: ActionTypes.CREATE_OR_UPDATE_CONFERENCE
});

const success = () => ({
  type: ActionTypes.CREATE_OR_UPDATE_SUCCESS
});

const failure = error => ({
  type: ActionTypes.CREATE_OR_UPDATE_FAILURE,
  error
});
