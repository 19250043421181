import { combineReducers } from "redux";
import conferences from "../conferenceList/reducers/conferenceListReducer";
import conferenceProfileReducer from "../conferenceProfile/reducers/conferenceProfileReducer";
import conferenceCreateReducer from "../conferenceCreateAndEdit/reducers/conferenceCreateReducer";
import register from "../registration/reducers/registerReducer";
import loginLogout from "../loginLogout/reducers/loginLogoutReducer";
import { alert } from "../alerts/reducers/alertReducer";
import submissionReducer from "../paperSubmission/reducers/submissionReducer";
import submissionListReducer from "../submissions/reducers/submissionsListReducer";
import userProfileReducer from "../userProfile/reducers/userProfileReducer";
import submissionDetailsReducer from "../submissionDetails/reducers/submissionDetailsReducer";
import setLanguageReducer from "./reducers/setLanguageReducer";
import trackSubmissionsReducer from "../trackSubmissions/reducers/trackSubmissionsReducer";
import assignedSubmissionsReducer from "../assignedSubmissions/reducers/assignedSubmissionsReducer";
import userListReducer from "../userList/reducers/userListReducer";

export default combineReducers({
  conferenceProfileReducer,
  conferences,
  conferenceCreateReducer,
  register,
  loginLogout,
  alert,
  paperSubmission: submissionReducer,
  submissions: submissionListReducer,
  userProfile: userProfileReducer,
  setLanguage: setLanguageReducer,
  submissionDetails: submissionDetailsReducer,
  trackSubmissions: trackSubmissionsReducer,
  assignedSubmissions: assignedSubmissionsReducer,
  users: userListReducer
});
