import React, { Component } from "react";
import { Button, Card, Form, Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as Actions from "../actions";
import PropTypes from "prop-types";
import "../style/resendConfirmation.css";
import { validateEmail } from "../../common/validator";

class ResendConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailError: false
    };
    this.handleResend = this.handleResend.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { value } = event.target;
    if (!validateEmail(value)) {
      this.setState({ emailError: true });
    } else {
      this.setState({ email: value, emailError: false });
    }
  }

  handleResend(e) {
    e.preventDefault();
    this.props.resendConfirmationEmail(this.state.email);
    this.setState({ showMessage: true });
  }

  render() {
    return (
      <>
        <div className="backButton">
          <Button
            onClick={() => {
              window.history.back();
            }}
          >
            <Icon name="angle left" />
          </Button>
        </div>

        <Card style={{ width: "100%" }}>
          <Card.Content>
            <p>{this.props.t("resendConfirmationEmail.message")}</p>
            <Form autoComplete="off" onSubmit={this.handleResend}>
              <Form.Field required error={this.state.emailError}>
                <input
                  type="email"
                  name="email"
                  placeholder={this.props.t("resendConfirmationEmail.email")}
                  onKeyUp={this.handleChange}
                />
              </Form.Field>
              <Button
                type="submit"
                disabled={!this.state.email || this.state.emailError}
              >
                {this.props.t("resendConfirmationEmail.resend")}
              </Button>
            </Form>
          </Card.Content>
        </Card>
      </>
    );
  }
}

ResendConfirmation.propTypes = {
  t: PropTypes.func,
  resendConfirmationEmail: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  resendConfirmationEmail: emailAddress =>
    Actions.resendConfirmationEmail(dispatch, emailAddress)
});

export default withTranslation()(
  connect(null, mapDispatchToProps)(ResendConfirmation)
);
