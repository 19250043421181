import * as ActionTypes from "../constants/actionTypes";
import { alertActions } from "../../alerts/actions";
import { history } from "../../common/history";
import { fetchWrapper } from "../../common/fetchWrap";

export const register = (dispatch, user) => {
  dispatch(request(user.email));
  fetchWrapper(`/api/users`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user)
  })
    .then(res => {
      res.json().then(result => {
        switch (res.status) {
          case 201:
            dispatch(success());
            dispatch(alertActions.success("alertMessage.registrationSuccess"));
            history.push("/");
            break;
          case 409:
            dispatch(failure(res.statusText));
            dispatch(alertActions.error(result.value.error));
            break;
          default:
            dispatch(failure(res.statusText));
            dispatch(alertActions.error("alertMessage.registrationFailed"));
            break;
        }
      });
    })
    .catch(error => dispatch(failure(error)));
};

const request = user => ({
  type: ActionTypes.REGISTER_REQUEST,
  user
});

const success = user => ({
  type: ActionTypes.REGISTER_SUCCESS,
  user
});

const failure = error => ({
  type: ActionTypes.REGISTER_FAILURE,
  error
});
