import * as ActionTypes from "../constants/actionTypes";
import i18n from "../../i18n";
import { history } from "../../common/history";
import { alertActions } from "../../alerts/actions";
import { authHeader } from "../../common/authHeader";
import { fetchWrapper } from "../fetchWrap";

export const setPreferredLanguage = (dispatch, user) => {
  let headers = Object.assign(authHeader(), {
    "Content-Type": "application/json"
  });
  fetchWrapper(
    `/api/users/${user.id}`,
    {
      method: "PATCH",
      headers,
      body: JSON.stringify(user)
    },
    true
  ).catch(error => dispatch(alertActions.error(error)));
};

export const setLanguage = (dispatch, language) => {
  localStorage.setItem("selectedLanguage", language);
  i18n.changeLanguage(language);
  dispatch(_setLanguage(language));
};

const _setLanguage = language => ({
  type: ActionTypes.SET_LANGUAGE,
  language
});

export const confirmUser = (dispatch, userId, token) => {
  history.push("/");
  fetchWrapper(`/api/users/${userId}/confirmation`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(token)
  }).then(res => {
    switch (res.status) {
      case 200: {
        dispatch(alertActions.success("alertMessage.confirmationSuccessful"));
        break;
      }
      case 400: {
        dispatch(alertActions.error("alertMessage.confirmedToken"));
        break;
      }
      case 401: {
        dispatch(alertActions.error("alertMessage.tokenNotValid"));
        break;
      }
      default: {
        dispatch(alertActions.error("alertMessage.confirmationError"));
        break;
      }
    }
  });
};

const fetchUserSuccess = (data, userType) => {
  return {
    type: ActionTypes.GET_USERS,
    users: data.users,
    userType: userType
  };
};

const fetchUserError = error => ({
  type: ActionTypes.GET_USERS_ERROR,
  error
});

export const getUsers = (dispatch, substring, userType) => {
  fetchWrapper(
    `/api/users?subs=${substring}&number=1&limit=5`,
    {
      method: "GET",
      headers: authHeader()
    },
    true
  )
    .then(res => {
      if (!res.ok) {
        dispatch(alertActions.error("alertMessage.somethingWentWrong"));
        history.push("/");
      } else {
        res
          .json()
          .then(res => dispatch(fetchUserSuccess(res.value, userType)))
          .catch(error => dispatch(fetchUserError(error)));
      }
    })
    .catch(error => dispatch(alertActions.error(error)));
};
