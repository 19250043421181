import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Responsive, Segment, Table } from "semantic-ui-react";

import ManageTrack, { ManageTrackModeEnum } from "./manageTrack";
import TrackDesktopViewTableRow from "./trackDesktopViewTableRow";
import TrackMobileViewCard from "./trackMobileViewCard";

import "../style/trackPanel.css";

class TrackPanel extends Component {
  render() {
    if (!this.props.loadingConference) {
      return (
        <>
          {/* //////////////////////////////// */}
          {/* DESKTOP:  */}
          {/* //////////////////////////////// */}
          <Responsive minWidth={768}>
            <Segment basic loading={false}>
              <h4>{this.props.t("track.tracks")}</h4>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      {this.props.t("track.name")}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {this.props.t("track.deadline")}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {this.props.t("track.abstractLength")}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {this.props.t("track.fileSize")}
                    </Table.HeaderCell>
                    {this.props.isOrganizer || this.props.isCommitteeMember ? (
                      <Table.HeaderCell textAlign="center">
                        {this.props.t(
                          "conferenceProfile.listSubmissionsHeader"
                        )}
                      </Table.HeaderCell>
                    ) : null}
                    {this.props.isOrganizer ? (
                      <Table.HeaderCell textAlign="center">
                        {this.props.t("track.edit")}
                      </Table.HeaderCell>
                    ) : null}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.conferenceTracks.map((track, index) => {
                    // -------------------This to seperate component------------------------
                    return (
                      <TrackDesktopViewTableRow
                        key={index}
                        track={track}
                        isOrganizer={this.props.isOrganizer}
                        isCommitteeMember={this.props.committeeMemberAt.includes(
                          track.id
                        )}
                        committeeMemberAtCount={
                          this.props.committeeMemberAt.length
                        }
                      />
                    );
                    // ------------------------------------------------------------------------
                  })}
                </Table.Body>
              </Table>
              {/* New track component: */}
              {this.props.isOrganizer ? (
                <ManageTrack componentMode={ManageTrackModeEnum.Create} />
              ) : null}
            </Segment>
          </Responsive>
          {/* //////////////////////////////// */}
          {/* MOBILE:  */}
          {/* //////////////////////////////// */}
          <Responsive {...Responsive.onlyMobile}>
            <Segment basic loading={false}>
              <h4>{this.props.t("track.tracks")}</h4>

              {this.props.conferenceTracks.map((track, index) => (
                <TrackMobileViewCard
                  key={index}
                  track={track}
                  isOrganizer={this.props.isOrganizer}
                  isCommitteeMember={this.props.committeeMemberAt.includes(
                    track.id
                  )}
                  committeeMemberAtCount={this.props.committeeMemberAt.length}
                />
              ))}

              {this.props.isOrganizer ? (
                <ManageTrack componentMode={ManageTrackModeEnum.Create} />
              ) : null}
            </Segment>
          </Responsive>
        </>
      );
    } else {
      return <div className="loadingDiv">{this.props.t("common.loading")}</div>;
    }
  }
}

TrackPanel.propTypes = {
  committeeMemberAt: PropTypes.array,
  conference: PropTypes.object,
  conferenceTracks: PropTypes.array,
  isCommitteeMember: PropTypes.bool,
  isOrganizer: PropTypes.bool,
  loadingConference: PropTypes.bool,
  t: PropTypes.func
};

const mapStateToProps = state => ({
  conference: state.conferenceProfileReducer.conference,
  loadingConference: state.conferenceProfileReducer.loadingConferenceProfile
});

export default withTranslation()(connect(mapStateToProps, null)(TrackPanel));
