import { logout, setOpen } from "../loginLogout/actions";
import { store } from "./store";
import { alertActions } from "../alerts/actions";
import jwtDecode from "jwt-decode";

export function checkTokenExpiration(token) {
  const decodedToken = jwtDecode(token);
  const nowInSeconds = Date.now() / 1000;
  return decodedToken.exp < nowInSeconds;
}

export var accessToken;

export const fetchWrapper = (
  url,
  { method, headers, body },
  authorizationRequired = false
) => {
  // eslint-disable-next-line no-undef
  return new Promise((resolve, reject) => {
    if (
      false === authorizationRequired ||
      (Object.prototype.hasOwnProperty.call(headers, "Authorization") &&
        false === checkTokenExpiration(headers.Authorization.slice(7)))
    ) {
      fetch(url, { method, headers, body })
        .then(res => resolve(res))
        .catch(err => reject(err));
    }
    // if no access token or access token expired, try to refresh
    else {
      fetch(
        `/api/users/${JSON.parse(localStorage.getItem("user")).id}/accessToken`,
        { headers: headers, method: "GET" }
      )
        .then(res => {
          // refresh happened, now make the original call
          if (res.ok) {
            res.json().then(json => {
              accessToken = json;
              headers = { ...headers, Authorization: "Bearer " + accessToken };
              fetch(url, { method, headers, body })
                .then(res => resolve(res))
                .catch(err => reject(err));
            });
            // refresh failed, log the user out
          } else {
            logout(store.dispatch);
            setOpen(store.dispatch, true);
            store.dispatch(alertActions.error("alertMessage.noAuthorization"));
            reject();
          }
        })
        // error during refresh
        .catch(() => {
          logout(store.dispatch);
          setOpen(store.dispatch, true);
          store.dispatch(alertActions.error("alertMessage.noAuthorization"));
          reject();
        });
    }
  });
};
