import * as ActionTypes from "../constants/actionTypes";
import { alertActions } from "../../alerts/actions";
import { authHeader } from "../../common/authHeader";
import { history } from "../../common/history";
import { fetchWrapper } from "../../common/fetchWrap";

const fetchUserDetailsBegin = () => ({
  type: ActionTypes.FETCH_USER_BEGIN
});
const fetchUserDetailsError = error => ({
  type: ActionTypes.FETCH_USER_ERROR,
  error
});

const fetchUserDetailsSuccess = data => {
  // provides clearer access of user's permissions
  data.user.permissions = data.user.permissions.permissions;
  return {
    type: ActionTypes.FETCH_USER,
    user: data.user
  };
};

export const unauthorized = dispatch => {
  dispatch(alertActions.error("alertMessage.unauthorized"));
  history.push("/");
};

export const notLoggedIn = dispatch => {
  dispatch(alertActions.error("alertMessage.notLoggedIn"));
  history.push("/");
};

export const fetchUserDetails = (dispatch, id) => {
  dispatch(fetchUserDetailsBegin());
  fetchWrapper(
    `/api/users/${id}/adminView`,
    {
      method: "GET",
      headers: authHeader()
    },
    true
  )
    .then(res => {
      if (!res.ok) {
        dispatch(alertActions.error("alertMessage.userNotFound"));
        history.push("/");
      } else {
        res
          .json()
          .then(res => {
            dispatch(fetchUserDetailsSuccess(res.value));
          })
          .catch(error => dispatch(fetchUserDetailsError(error)));
      }
    })
    .catch(error => dispatch(alertActions.error(error)));
};

export const updateUserDetails = (dispatch, user) => {
  let header = Object.assign(authHeader(), {
    "Content-Type": "application/json"
  });
  fetchWrapper(
    `/api/users/${user.id}`,
    {
      method: "PATCH",
      headers: header,
      body: JSON.stringify(user)
    },
    true
  )
    .then(res => {
      if (!res.ok) {
        dispatch(alertActions.error("alertMessage.updateFailed"));
        history.push("/");
      } else {
        dispatch(alertActions.success("alertMessage.updateSuccessful"));
        history.push("/");
      }
    })
    .catch(error => dispatch(alertActions.error(error)));
};

export const updateUserPermissions = (dispatch, userPermissionDTO) => {
  let header = Object.assign(authHeader(), {
    "Content-Type": "application/json"
  });
  fetchWrapper(
    `/api/users/${userPermissionDTO.id}/permissions`,
    {
      method: "PATCH",
      headers: header,
      body: JSON.stringify(userPermissionDTO.selectedPermissions)
    },
    true
  )
    .then(res => {
      if (!res.ok) {
        dispatch(alertActions.error("alertMessage.updateFailed"));
      } else {
        dispatch(alertActions.success("alertMessage.updateSuccessful"));
      }
    })
    .catch(error => dispatch(alertActions.error(error)));
};

const fetchAllPermissionsSuccess = data => {
  return {
    type: ActionTypes.FETCH_ALL_PERMISSIONS,
    allPermissions: data.permissions
  };
};

export const fetchAllPermissions = (dispatch, id) => {
  fetchWrapper(
    `/api/users/${id}/permissions`,
    {
      method: "GET",
      headers: authHeader()
    },
    true
  )
    .then(res => {
      res.json().then(res => {
        dispatch(fetchAllPermissionsSuccess(res.value));
      });
    })
    .catch(err => dispatch(fetchUserDetailsError(err)));
};
