import PropTypes from "prop-types";
import React from "react";
import { Dropdown, Form } from "semantic-ui-react";
import { orderNames } from "../nameLocalization";
import { withTranslation } from "react-i18next";

const DropdownSelector = props => {
  return (
    <Form.Field required={props.required} error={props.error}>
      <label>{props.label}</label>
      <Dropdown
        onSearchChange={props.searchElements}
        onChange={props.elementChange}
        search
        multiple
        selection
        noResultsMessage={props.t("dropdown.noResult")}
        defaultValue={props.defaultSelected}
        options={
          props.elements === undefined
            ? null
            : props.elements.map(user => {
                return {
                  key: user.email,
                  text:
                    orderNames(
                      user.firstName,
                      user.lastName,
                      props.t("localeFormats:namePattern")
                    ) +
                    " <" +
                    user.email +
                    ">",
                  value: JSON.stringify(user)
                };
              })
        }
      />
    </Form.Field>
  );
};

DropdownSelector.propTypes = {
  defaultSelected: PropTypes.array,
  elementChange: PropTypes.func,
  elements: PropTypes.array,
  error: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  searchElements: PropTypes.func,
  t: PropTypes.func
};

export default withTranslation()(DropdownSelector);
