import * as ActionTypes from "../constants/actionTypes";
import { alertActions } from "../../alerts/actions";
import { history } from "../../common/history";
import { setLanguage } from "../../common/actions";
import { fetchWrapper } from "../../common/fetchWrap";

export const login = (dispatch, user) => {
  dispatch(request({ email: user.email }));
  fetchWrapper(`/api/users/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(user)
  })
    .then(res => {
      res.json().then(user => {
        switch (res.status) {
          case 200: {
            // provides cleaner access for components
            user.permissions = user.permissions.permissions;
            setLanguage(dispatch, user.preferredLanguage);
            localStorage.setItem("user", JSON.stringify(user));
            dispatch(success(user));
            dispatch(alertActions.success("alertMessage.loginSuccess"));
            window.location.reload();
            break;
          }
          case 401: {
            history.push("/resendConfirmationEmail");
            dispatch(failure(res.statusText));
            dispatch(alertActions.error("alertMessage.userNotConfirmed"));
            break;
          }
          case 400: {
            dispatch(failure(res.statusText));
            dispatch(alertActions.error("alertMessage.invalidEmailOrPassword"));
            break;
          }

          default: {
            dispatch(failure(res.statusText));
            dispatch(alertActions.error("alertMessage.loginFaild"));
            break;
          }
        }
      });
    })
    .catch(error => dispatch(failure(error)));
};

const request = user => ({
  type: ActionTypes.LOGIN_REQUEST,
  user
});

const success = user => ({
  type: ActionTypes.LOGIN_SUCCESS,
  user
});

const failure = error => ({
  type: ActionTypes.LOGIN_FAILURE,
  error
});

export const logout = dispatch => {
  localStorage.removeItem("user");
  dispatch(logoutCreator());
  // used to clear state, thus clearing the access token
  window.location.href = "/";
};

const logoutCreator = () => ({
  type: ActionTypes.LOGOUT
});

export const setOpen = (dispatch, isOpen) => {
  dispatch(setOpenClose(isOpen));
};

const setOpenClose = isOpen => ({
  type: ActionTypes.SETOPEN,
  isOpen
});

export const resendConfirmationEmail = (dispatch, emailAdress) => {
  history.push("/");
  fetchWrapper(`api/users/resendConfirmation`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(emailAdress)
  })
    .then(res => {
      switch (res.status) {
        case 200: {
          dispatch(
            alertActions.success("alertMessage.confirmationEmailResent")
          );
          break;
        }
        default: {
          dispatch(failure(res.statusText));
          dispatch(alertActions.error("alertMessage.confirmationEmailError"));
          break;
        }
      }
    })
    .catch(error => dispatch(failure(error)));
};
