import React, { Component } from "react";
import { connect } from "react-redux";
import { Icon, Message, Popup } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import PropTypes from "prop-types";

import MomentWithUtc from "../../common/components/momentWithUtc";

//////////////////////////////////////////////
// If the conference is not active
//(we passed the deadline) shows the last
//deadline in red outline.
// If it is still active and has multiple deadlines
//the latest apears with a popup that contains all
//the others too.
//
class SubmissionDeadlinePanel extends Component {
  latestDeadline = () => {
    let deadlines = this.props.conference.tracks.map(track => {
      return new Date(track.submissionDeadline);
    });
    return Math.max.apply(null, deadlines);
  };

  render() {
    return (
      <div>
        {this.props.conference.active ? (
          <Message positive>
            {this.props.conference.commonDeadline ? (
              <p>
                {this.props.t("conferenceProfile.deadline")}:{" "}
                <MomentWithUtc
                  format={"dateFormat"}
                  date={this.props.conference.tracks[0].submissionDeadline}
                />
              </p>
            ) : (
              <p>
                {this.props.t("conferenceProfile.deadline")}:{" "}
                <MomentWithUtc
                  format={"dateFormat"}
                  date={this.latestDeadline()}
                />{" "}
                <Popup
                  trigger={<Icon name="info circle" />}
                  content={this.props.conference.tracks.map(track => (
                    <p key={track.name}>
                      {track.name}:{" "}
                      <MomentWithUtc
                        format={"dateFormat"}
                        date={track.submissionDeadline}
                      />
                    </p>
                  ))}
                  position="bottom center"
                />
              </p>
            )}
          </Message>
        ) : (
          <Message negative>
            {this.props.t("conferenceProfile.deadline")}:{" "}
            <MomentWithUtc
              format={"dateFormat"}
              date={this.props.conference.submissionDeadline}
            />
          </Message>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  conference: state.conferenceProfileReducer.conference
});

SubmissionDeadlinePanel.propTypes = {
  conference: PropTypes.object,
  t: PropTypes.func
};

export default withTranslation()(
  connect(mapStateToProps, null)(SubmissionDeadlinePanel)
);
