import React from "react";
import Pending from "./svgs/pending";
import Approved from "./svgs/approved";
import Accepted from "./svgs/accepted";
import Rejected from "./svgs/rejected";

export const mapStatusToSvg = number => {
  switch (number) {
    case 0:
      return <Pending />;
    case 1:
      return <Approved />;
    case 2:
      return <Accepted />;
    case 3:
      return <Rejected />;
    default:
      return <div></div>;
  }
};
