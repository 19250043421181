import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Moment from "react-moment";
import moment from "moment";
import PropTypes from "prop-types";

export function localDateToUtc(dateIn) {
  return new Date(dateIn).toUTCString();
}

export function utcDateToLocal(dateIn) {
  return moment(moment.utc(dateIn).toDate()).local();
}

export class MomentWithUtc extends Component {
  constructor(props) {
    super(props);
    if (this.props.format === undefined) {
      this.state = { format: "dateFormat" };
    } else {
      this.state = { format: this.props.format };
    }
  }

  render() {
    return (
      <Moment
        locale={localStorage.getItem("selectedLanguage")}
        format={this.props.t(`localeFormats:${this.state.format}`)}
        date={moment(moment.utc(this.props.date).toDate()).local()}
      />
    );
  }
}

MomentWithUtc.propTypes = {
  format: PropTypes.string,
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]),
  t: PropTypes.func
};

export default withTranslation()(MomentWithUtc);
