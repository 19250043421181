export const FETCH_SUBMISSIONS_OF_SUPERVISOR_BEGIN =
  "GET_SUBMISSIONS_OF_SUPERVISOR_BEGIN";
export const FETCH_SUBMISSIONS_OF_SUPERVISOR = "GET_SUBMISSIONS_OF_SUPERVISOR";
export const FETCH_SUBMISSIONS_OF_SUPERVISOR_ERROR =
  "GET_SUBMISSIONS_OF_SUPERVISOR_ERROR";
export const SET_TOTAL_SUPERVISEDSUBMISSIONS =
  "SET_TOTAL_SUPERVISEDSUBMISSIONS";
export const SET_CURRENT_SUPERVISEDSUBMISSIONS =
  "SET_CURRENT_SUPERVISEDSUBMISSIONS";
export const SET_SUPERVISEDSUBMISSIONS_TAB = "SET_SUPERVISEDSUBMISSIONS_TAB";
