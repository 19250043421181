import * as actionTypes from "../constants/actionTypes";
import _ from "lodash";

const initialState = {
  committeeMembers: [],
  conference: null,
  editTrackMode: false,
  error: null,
  extensions: [],
  loadingConferenceProfile: true
};

const checkIfActive = tracks => {
  return tracks.some(track => new Date(track.submissionDeadline) > new Date());
};

const checkIfCommonDeadline = tracks => {
  if (tracks.length === 1) return true;
  return tracks.every(
    track =>
      new Date(tracks[0].submissionDeadline).getTime() ===
      new Date(track.submissionDeadline).getTime()
  );
};

const conferenceProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CONFERENCE_BEGIN:
      return {
        ...state,
        loadingConferenceProfile: true,
        error: null
      };
    case actionTypes.FETCH_CONFERENCE_ERROR: {
      return {
        ...state,
        loadingConferenceProfile: false,
        error: action.error,
        conference: null
      };
    }
    case actionTypes.FETCH_CONFERENCE: {
      return {
        ...state,
        conference: action.conference,
        loadingConferenceProfile: false
      };
    }
    case actionTypes.FETCH_EXTENSIONS: {
      return {
        ...state,
        extensions: action.extensions
      };
    }
    case actionTypes.ADD_TRACK_TO_LIST: {
      let newTrackList = [...state.conference.tracks, action.track];

      return {
        ...state,
        conference: {
          ...state.conference,
          active: checkIfActive(newTrackList),
          commonDeadline: checkIfCommonDeadline(newTrackList),
          tracks: newTrackList
        }
      };
    }
    case actionTypes.REMOVE_TRACK_FROM_LIST: {
      let newTrackList = state.conference.tracks.filter(
        track => track.id !== action.trackId
      );
      if (newTrackList.length === 0) {
        return {
          ...state,
          conference: {
            ...state.conference,
            active: false,
            commonDeadline: true,
            tracks: newTrackList
          }
        };
      }
      return {
        ...state,
        conference: {
          ...state.conference,
          active: checkIfActive(newTrackList),
          commonDeadline: checkIfCommonDeadline(newTrackList),
          tracks: newTrackList
        }
      };
    }
    case actionTypes.UPDATE_TRACK_LIST: {
      let oldTrack = state.conference.tracks.filter(
        track => track.id === action.trackId
      );
      let trackIndex = state.conference.tracks.indexOf(oldTrack[0]);
      let newTrackList = state.conference.tracks;
      newTrackList[trackIndex] = action.track;
      return {
        ...state,
        conference: {
          ...state.conference,
          active: checkIfActive(newTrackList),
          commonDeadline: checkIfCommonDeadline(newTrackList),
          tracks: newTrackList
        }
      };
    }
    case actionTypes.GET_USERS: {
      let _users;
      switch (action.userType) {
        case "committeeMember":
          // eslint-disable-next-line no-undef
          _users = new Set(state.committeeMembers);
          break;
        default:
          return state;
      }

      action.users.forEach(user => {
        _users.add(user);
      });

      switch (action.userType) {
        case "committeeMember":
          return {
            ...state,
            committeeMembers: _.uniqWith(
              Array.from(_users),
              (arrVal, othVal) => arrVal.email === othVal.email
            )
          };
        default:
          return state;
      }
    }
    case actionTypes.GET_USERS_ERROR: {
      return {
        ...state,
        error: action.error
      };
    }
    case actionTypes.LOAD_COMMITTEE: {
      // we want an empty list of committee members when the user is presented the create track
      // modal. the below code ensures that when a user clicks on the edit button, the
      // committeeMembers list is cleared. otherwise it would be populated with the committee members
      // of the last edited track
      if (action.trackId === undefined) {
        return {
          ...state,
          committeeMembers: []
        };
      }
      return {
        ...state,
        committeeMembers: state.conference.tracks.find(
          track => track.id === action.trackId
        ).userTracks
      };
    }
    default:
      return state;
  }
};
export default conferenceProfileReducer;
