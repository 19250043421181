import * as ActionTypes from "../constants/actionTypes";
import { authHeader } from "../../common/authHeader";
import { fetchWrapper } from "../../common/fetchWrap";
import { alertActions } from "../../alerts/actions";

const fetchSubmissionsBegin = () => ({
  type: ActionTypes.FETCH_SUBMISSIONS_BEGIN
});

export const fetchSubmissionsSuccess = data => ({
  type: ActionTypes.FETCH_SUBMISSIONS,
  submissions: data.submissions
});

export const fetchSubmissionsError = error => ({
  type: ActionTypes.FETCH_SUBMISSIONS_ERROR,
  error
});

export const fetchSubmissions = (dispatch, id) => {
  dispatch(fetchSubmissionsBegin());
  fetchWrapper(
    `/api/users/${id}/submissions`,
    {
      method: "GET",
      headers: authHeader()
    },
    true
  )
    .then(res => res.json())
    .then(res => dispatch(fetchSubmissionsSuccess(res.value)))
    .catch(error => {
      dispatch(fetchSubmissionsError(error));
      dispatch(alertActions.error(error));
    });
};
