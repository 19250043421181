import React, { Component } from "react";
import { Card, Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { orderNames } from "../../common/nameLocalization";
import { StatusEnum } from "../../common/statusEnum";
import { mapStatusToSvg } from "../../common/mapStatusToSvg";
import MomentWithUtc from "../../common/components/momentWithUtc";
import { UserEnum } from "../../common/userEnum";

class Submission extends Component {
  render() {
    this.uploader = this.props.submission.userSubmission.find(
      us => us.isUploader
    );
    return (
      <Card>
        <Card.Content key={this.props.submission.id}>
          <Card.Description>
            <Grid columns="equal">
              <Grid.Column textAlign="left">
                <p>
                  <b>{this.props.submission.title} </b>
                </p>
              </Grid.Column>
              <Grid.Column textAlign="right">
                {mapStatusToSvg(this.props.submission.status)}
              </Grid.Column>
            </Grid>
            <p>
              {this.props.t("submissionList.uploader")}
              {": "}
              {orderNames(
                this.uploader.firstName,
                this.uploader.lastName,
                this.props.t("localeFormats:namePattern")
              ) +
                " <" +
                this.uploader.email +
                ">"}
            </p>
            <p>
              {this.props.t("paperSubmission.coAuthors")}
              {": "}
              {this.props.submission.userSubmission
                .filter(
                  us => us.email !== this.uploader.email && us.userRole === 0
                )
                .map(us =>
                  orderNames(
                    us.firstName,
                    us.lastName,
                    this.props.t("localeFormats:namePattern")
                  )
                )}
            </p>
            <p>
              {this.props.t("paperSubmission.supervisor")}
              {": "}
              {this.props.submission.userSubmission
                .filter(us => us.userRole === 1)
                .map(
                  us =>
                    orderNames(
                      us.firstName,
                      us.lastName,
                      this.props.t("localeFormats:namePattern")
                    ) +
                    " <" +
                    us.email +
                    ">"
                )}
            </p>
            <p>
              {this.props.t("mySubmission.date")}
              {": "}
              <MomentWithUtc
                format={"dateFormat"}
                date={this.props.submission.date}
              />{" "}
            </p>
            <p>
              {this.props.t("mySubmission.time")}
              {": "}
              <MomentWithUtc
                format={"timeFormat"}
                date={this.props.submission.date}
              />{" "}
            </p>
            <p>
              {this.props.t("mySubmission.status")}
              {": "}
              {this.props.t(
                `submissionStatus.${StatusEnum[this.props.submission.status]}`
              )}{" "}
            </p>
            <p>
              {this.props.t("paperSubmission.reviewer")}
              {": "}
              {this.props.submission.userSubmission
                .filter(us => us.userRole === UserEnum.reviewer)
                .map(
                  us =>
                    orderNames(
                      us.firstName,
                      us.lastName,
                      this.props.t("localeFormats:namePattern")
                    ) +
                    " <" +
                    us.email +
                    ">"
                )}
              {0 ===
              this.props.submission.userSubmission.filter(
                us =>
                  UserEnum.reviewer === us.userRole ||
                  this.props.userId === us.userId
              ).length ? (
                <label
                  className="assignYourselfLabel"
                  onClick={() => {
                    this.props.assignAsReviewer(
                      this.props.submission.userSubmission[0].submissionId,
                      this.props.trackId
                    );
                    this.props.refreshSubmissions();
                  }}
                >
                  {this.props.t("trackSubmissions.assignYourself")}
                </label>
              ) : null}
            </p>
          </Card.Description>
        </Card.Content>
      </Card>
    );
  }
}

Submission.propTypes = {
  assignAsReviewer: PropTypes.func,
  refreshSubmissions: PropTypes.func,
  submission: PropTypes.object,
  t: PropTypes.func,
  trackId: PropTypes.string,
  userId: PropTypes.string
};

export default withTranslation()(Submission);
