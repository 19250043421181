import * as actionTypes from "../constants/actionTypes";

const initialState = {
  currentPage: 1,
  limit: 3,
  error: null,
  loading: false,
  users: [],
  total: null
};

const userListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USERLIST_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.FETCH_USERLIST:
      return {
        ...state,
        loading: false,
        users:  action.users
      };
    case actionTypes.FETCH_USERLIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        users: []
      };
    case actionTypes.SET_TOTAL_USERLIST:
      return {
        ...state,
        total: action.total
      };
    case actionTypes.SET_USERLIST_CURRENT:
      return {
        ...state,
        currentPage: action.currentPage
      };
      case actionTypes.SET_USERLIST_LIMIT:
        return {
          ...state,
          limit: action.limit
        };
    default:
      return state;
  }
};

export default userListReducer;