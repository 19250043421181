import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Segment, Modal } from "semantic-ui-react";
import { withdrawSubmission } from "../actions";
import "../style/submission.css";

class WithdrawSubmission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      submissionId: props.submissionId
    };
  }

  closeModal = () => this.setState({ modalOpen: false });

  renderTrigger() {
    return (
      <div className="withdrawButtonWrapper">
        <Button
          floated="right"
          onClick={() => this.setState({ modalOpen: true })}
        >
          {this.props.t("submissionDetails.withdraw")}
        </Button>
      </div>
    );
  }

  renderActionButtons() {
    return (
      <Segment basic floated="right">
        <Button
          onClick={() => {
            this.props.withdrawSubmission(this.state.submissionId);
            this.closeModal();
          }}
        >
          {this.props.t("buttonText.confirm")}
        </Button>
      </Segment>
    );
  }

  render() {
    const { modalOpen } = this.state;
    return (
      <Modal
        open={modalOpen}
        trigger={this.renderTrigger()}
        on="click"
        closeIcon
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={this.closeModal}
      >
        <Modal.Header>
          {this.props.t("submissionDetails.modalWithdrawHeader")}
        </Modal.Header>
        <Modal.Content>
          {this.props.t("submissionDetails.modalWithdrawConfirm")}
        </Modal.Content>
        <Modal.Actions>{this.renderActionButtons()}</Modal.Actions>
      </Modal>
    );
  }
}

WithdrawSubmission.propTypes = {
  submissionId: PropTypes.string,
  withdrawSubmission: PropTypes.func,
  t: PropTypes.func
};

const mapStateToProps = state => ({
  submissionId:
    state.submissionDetails.submissionWithDetail.userSubmission[0].submissionId
});

const mapDispatchToProps = dispatch => ({
  withdrawSubmission: submissionId => withdrawSubmission(dispatch, submissionId)
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(WithdrawSubmission)
);
