import React, { Component } from "react";
import { Card, Button } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { history } from "../../common/history";
import ManageTrack, { ManageTrackModeEnum } from "./manageTrack";
import DeleteTrack from "./deleteTrack";
import MomentWithUtc from "../../common/components/momentWithUtc";

class TrackMobileViewCard extends Component {
  render() {
    return (
      <>
        <Card>
          <Card.Content header={this.props.track.name} />

          <Card.Content>
            {this.props.t("track.deadline") + ": "}
            <MomentWithUtc
              format={"dateFormat"}
              date={this.props.track.submissionDeadline}
            />
            &nbsp;
            <MomentWithUtc
              format={"timeFormat"}
              date={this.props.track.submissionDeadline}
            />
          </Card.Content>
          <Card.Content>
            {this.props.t("track.abstractLength") + ": "}
            {this.props.track.abstractLength}
          </Card.Content>
          <Card.Content>
            {this.props.t("track.fileSize") + ": "}
            {this.props.track.fileSize}
          </Card.Content>
          {this.props.isOrganizer || this.props.isCommitteeMember ? (
            <Card.Content textAlign="center">
              <Button
                onClick={() =>
                  history.push(
                    `/conferences/${this.props.conferenceId}/tracks/${this.props.track.id}/submissions`
                  )
                }
              >
                {this.props.t("conferenceProfile.listSubmissions")}
              </Button>
            </Card.Content>
          ) : this.props.committeeMemberAtCount > 0 ? (
            <Card.Content textAlign="center">
              {this.props.t("conferenceProfile.notCommittee")}
            </Card.Content>
          ) : null}
          {this.props.isOrganizer ? (
            <Card.Content textAlign="center">
              <ManageTrack
                componentMode={ManageTrackModeEnum.Edit}
                track={{ ...this.props.track }}
                trackId={this.props.track.id}
              />
              <DeleteTrack
                trackName={this.props.track.name}
                trackId={this.props.track.id}
              />
            </Card.Content>
          ) : null}
        </Card>
      </>
    );
  }
}

TrackMobileViewCard.propTypes = {
  committeeMemberAt: PropTypes.array,
  committeeMemberAtCount: PropTypes.number,
  conferenceId: PropTypes.string,
  isCommitteeMember: PropTypes.bool,
  isOrganizer: PropTypes.bool,
  t: PropTypes.func,
  track: PropTypes.object
};

export default withTranslation()(TrackMobileViewCard);
