import React, { Component } from "react";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";
import * as Actions from "../actions";
import PropTypes from "prop-types";
import "../style/followButton.css";

class FollowButton extends Component {
  state = { isFollowed: false };
  constructor(props) {
    super(props);
    this.state.isFollowed = this.props.isFollowed;
  }

  render() {
    if (this.state.isFollowed) {
      return (
        <Icon
          onClick={() => {
            this.setState({
              isFollowed: false
            });
            this.props.unFollow(this.props.conferenceId);
          }}
          name="star"
          color="yellow"
          className="followIcon"
        />
      );
    } else {
      return (
        <Icon
          onClick={() => {
            this.setState({
              isFollowed: true
            });
            this.props.follow(this.props.conferenceId);
          }}
          className="followIcon"
          name="star outline"
        />
      );
    }
  }
}

const mapDispatchToProps = dispatch => ({
  follow: (conferenceId, index) =>
    Actions.follow(dispatch, conferenceId, index),
  unFollow: (conferenceId, index) =>
    Actions.unFollow(dispatch, conferenceId, index)
});

FollowButton.propTypes = {
  isFollowed: PropTypes.bool,
  followState: PropTypes.bool,
  unFollow: PropTypes.func,
  follow: PropTypes.func,
  conferenceId: PropTypes.string,
  index: PropTypes.number
};

export default connect(null, mapDispatchToProps)(FollowButton);
