import { connect } from "react-redux";
import React, { Component } from "react";
import * as Actions from "../actions";
import * as ListTypes from "../constants/conferenceListTypes";
import Conference from "./conference";
import ConferenceListTab from "./conferenceListTab";
import {
  Card,
  Message,
  Pagination,
  Responsive,
  Button,
  Segment
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "../style/conferenceList.css";
import "../style/pageSizeDropdown.css";
import "../style/paginationComponent.css";
import PageSizeDropdown from "../../common/components/PageSizeDropdown";
import { TabEnum } from "../../common/tabEnum";
import queryString from "query-string";
import { history } from "../../common/history";
import { PermissionEnum } from "../../common/permissionEnum";
import { checkPermission } from "../../common/checkPermission";

class ConferenceList extends Component {
  state = {
    current: 1,
    listType: ListTypes.DEFAULT,
    canCreateConference: false
  };

  constructor(props) {
    super(props);
    this.onNavigate = this.onNavigate.bind(this);
    this.setPageSize = this.setPageSize.bind(this);

    this.values = queryString.parse(this.props.location.search);

    this.current = parseInt(this.values.current);
    this.listType = this.values.listType;

    if (isNaN(this.current)) this.current = 1;
    if (this.listType === undefined) this.listType = ListTypes.DEFAULT;
  }

  getSnapshotBeforeUpdate() {
    this.values = queryString.parse(this.props.location.search);

    this.current = parseInt(this.values.current);
    if (isNaN(this.current)) this.current = 1;
    this.listType = this.values.listType;
    if (this.listType === undefined) this.listType = ListTypes.DEFAULT;
    this.props.setCurrentPage(this.current);

    this.props.setConferenceListTab(this.state.listType);

    if (
      this.state.current !== this.current ||
      this.state.listType !== this.listType
    ) {
      this.setState({ current: this.current, listType: this.listType });
      this.props.filterConferences(
        this.props.filterBy,
        this.current,
        this.props.limit,
        this.listType === ListTypes.FOLLOWED,
        this.listType === ListTypes.UPCOMING
      );
      this.props.fetchNumberOfConferences(
        this.props.filterBy,
        this.listType === ListTypes.FOLLOWED,
        this.listType === ListTypes.UPCOMING
      );
    }
    return null;
  }

  //This is needed for getSnapshotBeforeUpdate() to run properly
  componentDidUpdate() {}

  componentDidMount() {
    this.props.fetchNumberOfConferences(
      "",
      this.listType === ListTypes.FOLLOWED,
      this.listType === ListTypes.UPCOMING
    );
    if (this.current < 1 || this.current > this.props.limit) this.current = 1;
    this.props.fetchConferences(
      this.current,
      this.props.limit,
      this.state.listType === ListTypes.FOLLOWED,
      this.state.listType === ListTypes.UPCOMING,
      this.props.isLoggedIn
    );
    this.props.setConferenceListTab(this.state.listType);

    if (this.props.isLoggedIn) {
      this.setState({
        canCreateConference: checkPermission(
          this.props.user.permissions,
          PermissionEnum.conferenceCreation
        )
      });
    }
  }

  onNavigate(e) {
    if (
      undefined === e.target.attributes.value ||
      undefined === e.target.attributes.value.value
    ) {
      return;
    }
    const newCurrent = Number(e.target.attributes.value.value);

    if (this.props.current !== newCurrent) {
      this.props.setCurrentPage(newCurrent);
      if (this.props.filter) {
        this.props.filterConferences(
          this.props.filterBy,
          newCurrent,
          this.props.limit,
          this.state.listType === ListTypes.FOLLOWED,
          this.state.listType === ListTypes.UPCOMING,
          this.props.isLoggedIn
        );
      } else {
        this.props.fetchConferences(
          newCurrent,
          this.props.limit,
          this.state.listType === ListTypes.FOLLOWED,
          this.state.listType === ListTypes.UPCOMING,
          this.props.isLoggedIn
        );
      }
    }
    history.push(
      "/conferences?listType=" + this.state.listType + "&current=" + newCurrent
    );
  }

  setPageSize(_, data) {
    if (this.props.conferenceListTab === TabEnum.all) {
      if (data.value !== this.props.limit) {
        this.props.setPageSize(data.value);
        this.props.fetchConferences(
          1,
          data.value,
          this.state.listType === ListTypes.FOLLOWED,
          this.state.listType === ListTypes.UPCOMING
        );
        this.props.setCurrentPage(1);
      }
    } else {
      if (data.value !== this.props.limit) {
        this.props.setPageSize(data.value);
        this.props.fetchConferences(
          1,
          data.value,
          this.state.listType === ListTypes.FOLLOWED,
          this.state.listType === ListTypes.UPCOMING
        );
        this.props.setCurrentPage(1);
      }
    }
  }

  renderCardGroupConferencesElement() {
    const { conferences } = this.props;
    return conferences.length > 0 ? (
      <Card.Group itemsPerRow={1}>
        {conferences.map(conference => (
          <Conference
            key={conference.id}
            index={conferences.indexOf(conference)}
            conference={conference}
          />
        ))}
      </Card.Group>
    ) : (
      <Message warning>{this.props.t("conferenceList.noResult")}</Message>
    );
  }

  renderPaginationElement() {
    const { limit, current, total } = this.props;
    return (
      <Pagination
        activePage={current}
        totalPages={Math.ceil(total / limit)}
        onClick={this.onNavigate}
        boundaryRange={0}
        siblingRange={1}
        ellipsisItem={null}
      />
    );
  }

  render() {
    const { error, loading, conferences } = this.props;

    if (error) {
      return <div>Error! {error.message}</div>;
    }

    if (loading) {
      return <div className="loadingDiv">{this.props.t("common.loading")}</div>;
    }
    return (
      <div>
        <Responsive minWidth={768}>
          <div className="conferenceListDiv">
            <ConferenceListTab />
            <div>
              {conferences.length > 0 ? (
                <div className="paginationComponentDiv">
                  <PageSizeDropdown onChange={this.setPageSize} />
                  {this.renderPaginationElement()}
                  {this.state.canCreateConference ? (
                    <Button
                      icon="add"
                      content={this.props.t("buttonText.newConference")}
                      floated="right"
                      onClick={() => {
                        history.push("/conferences/create");
                      }}
                    />
                  ) : null}
                </div>
              ) : (
                ""
              )}{" "}
              {this.renderCardGroupConferencesElement()}
            </div>
          </div>
        </Responsive>
        <Responsive {...Responsive.onlyMobile}>
          <div className="conferenceListDivMobile">
            <ConferenceListTab />

            <div>
              <Segment basic>
                {conferences.length > 0 ? (
                  <div className="pageSizeComponentDiv">
                    <PageSizeDropdown onChange={this.setPageSize} />
                  </div>
                ) : (
                  ""
                )}
                {this.state.canCreateConference ? (
                  <Button
                    icon="add"
                    content={this.props.t("buttonText.newConference")}
                    onClick={() => {
                      history.push("/conferences/create");
                    }}
                  />
                ) : null}
              </Segment>
              {this.renderCardGroupConferencesElement()}
              {conferences.length > 0 ? (
                <div className="paginationComponentDiv">
                  {this.renderPaginationElement()}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Responsive>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.loginLogout.loggedIn,
  user: state.loginLogout.user,
  conferences: state.conferences.conferences,
  loading: state.conferences.loading,
  error: state.conferences.error,
  current: state.conferences.current,
  limit: state.conferences.limit,
  total: state.conferences.total,
  filter: state.conferences.filter,
  filterBy: state.conferences.filterBy,
  conferenceListTab: state.conferences.conferenceListTab
});

const mapDispatchToProps = dispatch => ({
  fetchConferences: (number, limit, my, upcoming, isLoggedIn) =>
    Actions.fetchConferences(dispatch, number, limit, my, upcoming, isLoggedIn),
  filterConferences: (name, number, limit, my, upcoming, isLoggedIn) =>
    Actions.filterConferences(
      dispatch,
      name,
      number,
      limit,
      my,
      upcoming,
      isLoggedIn
    ),
  fetchNumberOfConferences: (name, my, upcoming) =>
    Actions.fetchNumberOfConferences(dispatch, name, my, upcoming),
  setCurrentPage: current => Actions.setCurrentPage(dispatch, current),
  setPageSize: limit => Actions.setPageSize(dispatch, limit),
  setConferenceListTab: listType => {
    let listTab = 0;
    switch (listType) {
      case ListTypes.UPCOMING: {
        listTab = 0;
        break;
      }
      case ListTypes.ALL: {
        listTab = 1;
        break;
      }
      case ListTypes.FOLLOWED: {
        listTab = 2;
        break;
      }
      default: {
        listTab = 0;
      }
    }
    Actions.setConferenceListTab(dispatch, listTab);
  }
});

ConferenceList.propTypes = {
  setPageSize: PropTypes.func,
  fetchConferences: PropTypes.func,
  fetchNumberOfConferences: PropTypes.func,
  setCurrentPage: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  limit: PropTypes.number,
  filter: PropTypes.bool,
  filterConferences: PropTypes.func,
  filterBy: PropTypes.string,
  current: PropTypes.number,
  conferences: PropTypes.array,
  total: PropTypes.number,
  t: PropTypes.func,
  error: PropTypes.string,
  loading: PropTypes.bool,
  conferenceListTab: PropTypes.number,
  currentPage: PropTypes.number,
  page: PropTypes.string,
  location: PropTypes.object,
  setConferenceListTab: PropTypes.func,
  user: PropTypes.object
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConferenceList)
);
