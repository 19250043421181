import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Card, Message, Responsive } from "semantic-ui-react";
import Submission from "./submission";
import * as Actions from "../actions";
import "../style/submissionsList.css";
import { history } from "../../common/history";

class SubmissionsPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.isLoggedIn) {
      this.props.fetchSubmissions(this.props.userId);
    } else {
      history.push("/");
    }
  }

  renderCardGroupSubmissionsElement() {
    const { submissions } = this.props;
    return submissions.length > 0 ? (
      <Card.Group itemsPerRow={1}>
        {submissions.map((submission, index) => (
          <Submission key={index} index={index} submission={submission} />
        ))}
      </Card.Group>
    ) : (
      <Message warning>{this.props.t("mySubmission.noResult")}</Message>
    );
  }

  render() {
    const { error, loading } = this.props;

    if (error) {
      return <div>Error! {error.message}</div>;
    }

    if (loading) {
      return <div className="loadingDiv">{this.props.t("common.loading")}</div>;
    }
    return (
      <div>
        <Responsive minWidth={768}>
          <div className="submissionListDiv">
            <div>{this.renderCardGroupSubmissionsElement()}</div>
          </div>
        </Responsive>
        <Responsive {...Responsive.onlyMobile}>
          <div className="submissionListDivMobile">
            <div>{this.renderCardGroupSubmissionsElement()}</div>
          </div>
        </Responsive>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  error: state.submissions.error,
  isLoggedIn: state.loginLogout.loggedIn,
  loading: state.submissions.loading,
  submissions: state.submissions.submissions,
  userId: state.loginLogout.userId
});

const mapDispatchToProps = dispatch => ({
  fetchSubmissions: id => Actions.fetchSubmissions(dispatch, id)
});

SubmissionsPage.propTypes = {
  error: PropTypes.string,
  fetchSubmissions: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  loading: PropTypes.bool,
  submissions: PropTypes.array,
  t: PropTypes.func,
  userId: PropTypes.string
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(SubmissionsPage)
);
