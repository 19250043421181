import * as ActionTypes from "../constants/actionTypes";
import { authHeader } from "../../common/authHeader";
import { fetchWrapper } from "../../common/fetchWrap";
import { history } from "../../common/history";
import { alertActions } from "../../alerts/actions";

export const unauthorized = dispatch => {
  dispatch(alertActions.error("alertMessage.unauthorized"));
  history.push("/");
};

const fetchUserListBegin = () => ({
  type: ActionTypes.FETCH_USERLIST_BEGIN
});

const fetchUserListError = error => ({
  type: ActionTypes.FETCH_USERLIST_ERROR,
  error
});

const fetchUserListSuccess = data => {
  return {
    type: ActionTypes.FETCH_USERLIST,
    users: data.users
  };
};

const setLimit = limit => ({
  type: ActionTypes.SET_USERLIST_LIMIT,
  limit
});

export const setPageSize = (dispatch, limit) => {
  dispatch(setLimit(limit));
};

export const fetchUserList = (dispatch, pageNumber, limit) => {
  dispatch(fetchUserListBegin());
  fetchWrapper(
    `/api/users/userlist?pageNumber=${pageNumber}&limit=${limit}`,
    {
      method: "GET",
      headers: authHeader()
    },
    true
  )
    .then(res => res.json())
    .then(res => dispatch(fetchUserListSuccess(res.value)))
    .catch(error => dispatch(fetchUserListError(error)));
};

const setNumberOfUsers = number => ({
  type: ActionTypes.SET_TOTAL_USERLIST,
  total: number
});

export const fetchNumberOfUsers = dispatch => {
  fetchWrapper(
    `/api/users/userlist/count`,
    {
      method: "GET",
      headers: authHeader()
    },
    true
  )
    .then(res => res.json())
    .then(res => dispatch(setNumberOfUsers(res.value)))
    .catch(error => dispatch(fetchUserListError(error)));
};

const setCurrent = currentPage => ({
  type: ActionTypes.SET_USERLIST_CURRENT,
  currentPage
});

export const setCurrentPage = (dispatch, currentPage) => {
  dispatch(setCurrent(currentPage));
};
