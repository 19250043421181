import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Table } from "semantic-ui-react";
import PropTypes, { number } from "prop-types";
import { connect } from "react-redux";
import { orderNames } from "../../common/nameLocalization";

class AuthorSupervisor extends Component {
  render() {
    return (
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {this.props.t("submissionDetails.name")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {this.props.t("paperSubmission.email")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {this.props.t("paperSubmission.institution")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {this.props.t("paperSubmission.department")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {this.props.t("paperSubmission.specialization")}
            </Table.HeaderCell>
            {this.props.userRole === 0 ? (
              <Table.HeaderCell>
                {this.props.t("paperSubmission.year")}
              </Table.HeaderCell>
            ) : null}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.submission.userSubmission.map((user, index) =>
            user.userRole === this.props.userRole ? (
              <Table.Row key={index}>
                <Table.Cell>
                  {orderNames(
                    user.firstName,
                    user.lastName,
                    this.props.t("localeFormats:namePattern")
                  )}
                </Table.Cell>
                <Table.Cell>{user.email}</Table.Cell>
                <Table.Cell>{user.institution}</Table.Cell>
                <Table.Cell>{user.department}</Table.Cell>
                <Table.Cell>{user.specialization}</Table.Cell>
                {this.props.userRole === 0 ? (
                  <Table.Cell>{user.year}</Table.Cell>
                ) : null}
              </Table.Row>
            ) : null
          )}
        </Table.Body>
      </Table>
    );
  }
}

AuthorSupervisor.propTypes = {
  submission: PropTypes.object,
  userRole: number,
  t: PropTypes.func
};

export default withTranslation()(connect()(AuthorSupervisor));
