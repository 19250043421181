import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button, Segment, Modal, Label } from "semantic-ui-react";

import { deleteTrack } from "../actions";

/////////////////////////////////////////////
//Delete Track with Modal component
//
//Delete component props:
//  set: trackName, trackId
//
/////////////////////////////////////////////
class DeleteTrack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      track: {
        name: this.props.trackName,
        id: this.props.trackId
      },
      conferenceId: this.props.conferenceId
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.trackName !== this.props.trackName ||
      prevProps.trackId !== this.props.trackId
    ) {
      this.setState({
        track: {
          name: this.props.trackName,
          id: this.props.trackId
        }
      });
    }
  }

  closeModal = () => {
    this.setState({ open: false });
  };

  renderTrigger() {
    return (
      <Button
        icon="trash alternate outline"
        onClick={() => this.setState({ open: true })}
      />
    );
  }

  renderActionButtons() {
    return (
      <Segment basic floated="right">
        <Button
          onClick={() => {
            this.props.deleteTrack(
              this.state.conferenceId,
              this.state.track.id
            );
            this.closeModal();
          }}
        >
          {this.props.t("buttonText.confirm")}
        </Button>
      </Segment>
    );
  }

  render() {
    const { open } = this.state;
    return (
      <Modal
        open={open}
        trigger={this.renderTrigger()}
        on="click"
        closeIcon
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={this.closeModal}
      >
        <Modal.Header>{this.props.t("track.deleteTrack")}</Modal.Header>
        <Modal.Content>
          {this.props.t("track.deleteTrackConfirm")}
          <div>
            <Label>{this.state.track.name}</Label>
          </div>
        </Modal.Content>
        <Modal.Actions>{this.renderActionButtons()}</Modal.Actions>
      </Modal>
    );
  }
}

DeleteTrack.propTypes = {
  conferenceId: PropTypes.string,
  deleteTrack: PropTypes.func,
  t: PropTypes.func,
  trackId: PropTypes.string,
  trackName: PropTypes.string
};

const mapStateToProps = state => ({
  conferenceId: state.conferenceProfileReducer.conference.id
});

const mapDispatchToProps = dispatch => ({
  deleteTrack: (conferenceId, trackId) =>
    deleteTrack(dispatch, conferenceId, trackId)
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(DeleteTrack)
);
