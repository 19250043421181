import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  Card,
  Message,
  Responsive,
  Pagination,
  Button,
  Icon
} from "semantic-ui-react";
import Submission from "./submission";
import * as Actions from "../actions";
import { history } from "../../common/history";
import "../style/trackSubmissions.css";
import { perPageLimit } from "../constants/perPageLimit";
import queryString from "query-string";

class TrackSubmissions extends Component {
  constructor(props) {
    super(props);

    this.trackId = props.match.params.trackId;
    this.conferenceId = props.match.params.conferenceId;
    this.onPaginationClick = this.onPaginationClick.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.values = queryString.parse(this.props.location.search);

    let currentPage = parseInt(this.values.page);
    if (currentPage === undefined || isNaN(currentPage)) {
      currentPage = this.props.currentPage;
    } else {
      this.props.setCurrentPage(currentPage);
    }

    this.props.fetchSubmissions(this.conferenceId, this.trackId, currentPage);
  }

  onPaginationClick(e) {
    if (
      undefined === e.target.attributes.value ||
      undefined === e.target.attributes.value.value
    ) {
      return;
    }
    const newCurrentPage = Number(e.target.attributes.value.value);

    if (this.props.currentPage !== newCurrentPage) {
      this.props.setCurrentPage(newCurrentPage);

      this.props.fetchSubmissions(
        this.conferenceId,
        this.trackId,
        newCurrentPage
      );
    }
    history.push(
      "/conferences/" +
        this.conferenceId +
        "/tracks/" +
        this.trackId +
        "/submissions?page=" +
        newCurrentPage
    );
  }

  renderPaginationElement() {
    const { currentPage, total } = this.props;
    return (
      <Pagination
        activePage={currentPage}
        totalPages={Math.ceil(total / perPageLimit)}
        onClick={this.onPaginationClick}
        boundaryRange={0}
        siblingRange={1}
        ellipsisItem={null}
      />
    );
  }

  renderCardGroupSubmissionsElement() {
    const { submissions } = this.props;
    return submissions.length > 0 ? (
      <Card.Group itemsPerRow={1}>
        {submissions.map((submission, index) => (
          <Submission
            key={index}
            index={index}
            submission={submission}
            assignAsReviewer={this.props.assignReviewer}
            trackId={this.trackId}
            refreshSubmissions={() =>
              this.props.fetchSubmissions(
                this.conferenceId,
                this.trackId,
                this.props.currentPage
              )
            }
            userId={this.props.userId}
          />
        ))}
      </Card.Group>
    ) : (
      <Message warning>{this.props.t("mySubmission.noResult")}</Message>
    );
  }

  render() {
    const { error, loading } = this.props;

    if (error) {
      return <div>Error! {error.message}</div>;
    }

    if (loading) {
      return <div className="loadingDiv">{this.props.t("common.loading")}</div>;
    }

    return (
      <>
        <div className="trackSubmissionsBackButton">
          <Button
            onClick={() => {
              window.history.back();
            }}
          >
            <Icon name="angle left" />
          </Button>
        </div>
        <Responsive minWidth={768}>
          {this.props.submissions.length > 0 ? (
            <div className="paginationElementDiv">
              {this.renderPaginationElement()}
            </div>
          ) : null}

          <div>{this.renderCardGroupSubmissionsElement()}</div>
        </Responsive>
        <Responsive {...Responsive.onlyMobile}>
          <div className="submissionListDivMobile">
            <div>{this.renderCardGroupSubmissionsElement()}</div>
          </div>
        </Responsive>
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentPage: state.trackSubmissions.currentPage,
  error: state.trackSubmissions.error,
  loading: state.trackSubmissions.loading,
  submissions: state.trackSubmissions.submissions,
  total: state.trackSubmissions.total,
  userId: state.loginLogout.userId
});

const mapDispatchToProps = dispatch => ({
  assignReviewer: (submissionId, trackId) =>
    Actions.assignReviewer(dispatch, submissionId, trackId),
  fetchSubmissions: (conferenceId, trackId, pageNumber) =>
    Actions.fetchTrackSubmissions(dispatch, conferenceId, trackId, pageNumber),
  setCurrentPage: currentPage => Actions.setCurrentPage(dispatch, currentPage)
});

TrackSubmissions.propTypes = {
  assignReviewer: PropTypes.func,
  currentPage: PropTypes.number,
  error: PropTypes.bool,
  fetchSubmissions: PropTypes.func,
  loading: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
  setCurrentPage: PropTypes.func,
  submissions: PropTypes.array,
  t: PropTypes.func,
  total: PropTypes.number,
  userId: PropTypes.string
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(TrackSubmissions)
);
