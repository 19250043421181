import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import {
  Responsive,
  Card,
  Segment,
  Button,
  Icon,
  Grid
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { fetchSubmission, fetchFile } from "../actions";
import "../style/submission.css";

import { mapStatusToSvg } from "../../common/mapStatusToSvg";
import MomentWithUtc from "../../common/components/momentWithUtc";
import AuthorSupervisorMobile from "./authorSupervisorMobile";
import AuthorSupervisor from "./authorSupervisor";
import WithdrawSubmission from "./withdrawSubmission";

class SubmissionDetailsPage extends Component {
  constructor(props) {
    super(props);
    this.submission = null;
  }
  componentDidMount() {
    this.props.fetchSubmission(this.props.match.params.id);
    // scrollTo added because manually scrolling up after being redirected to the submission was annoying
    window.scrollTo(0, 0);
  }

  renderMobileCard() {
    return (
      <Card style={{ width: "100%" }}>
        <Segment loading={this.props.loading}>
          <Card.Content>
            <Card.Header className="submissionTitle">
              <Grid columns="equal">
                <Grid.Column textAlign="left">
                  <p>
                    <b>{this.submission.title}</b>
                  </p>
                </Grid.Column>
                <Grid.Column textAlign="right">
                  {mapStatusToSvg(this.props.submission.status)}
                </Grid.Column>
              </Grid>
            </Card.Header>

            <Card.Meta>
              {this.props.t("submissionDetails.submissionDate")}
              {": "}
              <MomentWithUtc
                format={"dateFormat"}
                date={this.props.submission.date}
              />{" "}
              <br />
              {this.props.t("submissionDetails.time")}
              {": "}
              <MomentWithUtc
                format={"timeFormat"}
                date={this.props.submission.date}
              />
              <br />
              <br />
            </Card.Meta>
            <Card.Description className="submissionContent">
              {this.props.t("submissionDetails.download")}
              {": "}
              <p className="download">
                {" "}
                <Icon
                  link
                  onClick={() =>
                    this.props.fetchFile(
                      this.props.match.params.id,
                      this.submission.fileName
                    )
                  }
                  name="download"
                />{" "}
                <i>{this.submission.fileName}</i>
              </p>
              {this.props.t("submissionDetails.conference")}
              {": "}
              <Link
                className="link"
                to={`/conferences/${this.submission.conferenceShortName}`}
              >
                <b> {this.submission.conferenceName} </b>
              </Link>
              <br />
              {this.props.t("submissionDetails.track")}
              {": "}
              {this.submission.trackName}
              <br />
              <br />
              <b>{this.props.t("submissionDetails.abstract")}</b>
              <br />
              <p>{this.submission.abstract}</p>
              <br />
              <br />
              <p>
                <b>{this.props.t("submissionDetails.authors")}</b>
              </p>
              <AuthorSupervisorMobile
                submission={this.props.submission}
                userRole={0}
              />
              <br />
              <b>{this.props.t("submissionDetails.supervisors")}</b>
              <AuthorSupervisorMobile
                submission={this.props.submission}
                userRole={1}
              />
            </Card.Description>
            <WithdrawSubmission />
          </Card.Content>
        </Segment>
      </Card>
    );
  }

  renderCard() {
    return (
      <Card style={{ width: "100%" }}>
        <Segment loading={this.props.loading}>
          <Card.Content>
            <Card.Header className="submissionTitle">
              <Grid columns="equal">
                <Grid.Column textAlign="left">
                  <p>
                    <b>{this.submission.title}</b>
                  </p>
                </Grid.Column>

                <Grid.Column textAlign="right">
                  {mapStatusToSvg(this.props.submission.status)}
                </Grid.Column>
              </Grid>
            </Card.Header>

            <Card.Meta>
              {this.props.t("submissionDetails.submissionDate")}
              {": "}
              <MomentWithUtc
                format={"dateFormat"}
                date={this.props.submission.date}
              />{" "}
              <br />
              {this.props.t("submissionDetails.time")}
              {": "}
              <MomentWithUtc
                format={"timeFormat"}
                date={this.props.submission.date}
              />
              <br />
              <br />
            </Card.Meta>
            <Card.Description className="submissionContent">
              {this.props.t("submissionDetails.download")}
              {": "}
              <Icon
                link
                onClick={() =>
                  this.props.fetchFile(
                    this.props.match.params.id,
                    this.submission.fileName
                  )
                }
                name="download"
              />{" "}
              <i>{this.submission.fileName}</i>
              <br />
              {this.props.t("submissionDetails.conference")}
              {": "}
              <Link
                className="link"
                to={`/conferences/${this.submission.conferenceShortName}`}
              >
                <b> {this.submission.conferenceName} </b>
              </Link>
              <br />
              {this.props.t("submissionDetails.track")}
              {": "}
              {this.submission.trackName}
              <br />
              <br />
              <b>{this.props.t("submissionDetails.abstract")}</b>
              <br />
              <p>{this.submission.abstract}</p>
              <br />
              <br />
              <b>{this.props.t("submissionDetails.authors")}</b>
              <br />
              <AuthorSupervisor
                submission={this.props.submission}
                userRole={0}
              />
              <b>{this.props.t("submissionDetails.supervisors")}</b>
              <AuthorSupervisor
                submission={this.props.submission}
                userRole={1}
              />
            </Card.Description>
            <WithdrawSubmission />
          </Card.Content>
        </Segment>
      </Card>
    );
  }

  render() {
    if (this.props.loading) {
      return <div className="loadingDiv">{this.props.t("common.loading")}</div>;
    }

    if (this.props.error) {
      return <div>Error! {this.props.error.message}</div>;
    }

    this.submission = this.props.submission;
    return (
      <div style={{ marginTop: "100px" }}>
        <Suspense fallback={<div>Loading...</div>}>
          <Responsive minWidth={768}>
            <Button
              onClick={() => {
                window.history.back();
              }}
            >
              <Icon name="angle left" />
            </Button>
            {this.renderCard()}
          </Responsive>
          <Responsive {...Responsive.onlyMobile}>
            <Button onClick={() => window.history.back()}>
              <Icon name="angle left" />
            </Button>
            {this.renderMobileCard()}
          </Responsive>
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  submission: state.submissionDetails.submissionWithDetail,
  loading: state.submissionDetails.loading,
  error: state.submissionDetails.error
});

const mapDispatchToProps = dispatch => ({
  fetchSubmission: id => fetchSubmission(dispatch, id),
  fetchFile: (id, fileName) => fetchFile(dispatch, id, fileName)
});

SubmissionDetailsPage.propTypes = {
  t: PropTypes.func,
  fetchSubmission: PropTypes.func,
  fetchFile: PropTypes.func,
  submission: PropTypes.object,
  error: PropTypes.string,
  loading: PropTypes.bool,
  match: PropTypes.object
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(SubmissionDetailsPage)
);
