import * as ActionTypes from "../constants/actionTypes";
import _ from "lodash";

const initialState = {
  coAuthors: [],
  conference: {},
  error: null,
  loading: false,
  supervisors: []
};

const submissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PROPERTIES:
      return {
        conference: action.conference
      };
    case ActionTypes.GET_PROPERTIES_ERROR:
      return {
        error: action.error
      };
    case ActionTypes.GET_USERS: {
      var _users;
      switch (action.userType) {
        case "coAuthor":
          // eslint-disable-next-line no-undef
          _users = new Set(state.coAuthors);
          break;
        case "supervisor":
          // eslint-disable-next-line no-undef
          _users = new Set(state.supervisors);
          break;
        default:
          return state;
      }

      action.users.forEach(user => {
        _users.add(user);
      });

      switch (action.userType) {
        case "supervisor":
          return {
            ...state,
            supervisors: _.uniqWith(Array.from(_users), _.isEqual)
          };
        case "coAuthor":
          return {
            ...state,
            coAuthors: _.uniqWith(Array.from(_users), _.isEqual)
          };
        default:
          return state;
      }
    }
    case ActionTypes.GET_USERS_ERROR: {
      return {
        error: action.error
      };
    }
    case ActionTypes.SUBMITTING_PAPER:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.SUBMITTING_TERMINATED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default submissionReducer;
