import React, { Component } from "react";
import { Card, Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { StatusEnum } from "../../common/statusEnum";
import { mapStatusToSvg } from "../../common/mapStatusToSvg";
import MomentWithUtc from "../../common/components/momentWithUtc";

class Submission extends Component {
  render() {
    this.uploader = this.props.submission.userSubmission.find(
      us => us.isUploader
    );
    this.submissionId = this.props.submission.userSubmission[0].submissionId;
    return (
      <Card>
        <Card.Content key={this.submissionId}>
          <Card.Description>
            <Grid columns="equal">
              <Grid.Column textAlign="left">
                <p>
                  <b>{this.props.submission.title} </b>
                </p>
              </Grid.Column>
              <Grid.Column textAlign="right">
                {mapStatusToSvg(this.props.submission.status)}
              </Grid.Column>
            </Grid>
            <p>
              {this.props.t("mySubmission.conferenceName")}
              {": "}
              {this.props.submission.conferenceName}{" "}
            </p>
            <p>
              {this.props.t("mySubmission.trackName")}
              {": "}
              {this.props.submission.trackName}{" "}
            </p>
            <p>
              {this.props.t("submissionList.uploader")}
              {": "}
              {this.uploader.firstName +
                " " +
                this.uploader.lastName +
                " <" +
                this.uploader.email +
                ">"}
            </p>
            <p>
              {this.props.t("paperSubmission.coAuthors")}
              {": "}
              {this.props.submission.userSubmission
                .filter(
                  us => us.email !== this.uploader.email && us.userRole === 0
                )
                .map(us => us.firstName + " " + us.lastName)}
            </p>
            <p>
              {this.props.t("mySubmission.date")}
              {": "}
              <MomentWithUtc
                format={"dateFormat"}
                date={this.props.submission.date}
              />{" "}
            </p>
            <p>
              {this.props.t("mySubmission.time")}
              {": "}
              <MomentWithUtc
                format={"timeFormat"}
                date={this.props.submission.date}
              />{" "}
            </p>
            <p>
              {this.props.t("mySubmission.status")}
              {": "}
              {this.props.t(
                `submissionStatus.${StatusEnum[this.props.submission.status]}`
              )}{" "}
            </p>
          </Card.Description>
        </Card.Content>
      </Card>
    );
  }
}

Submission.propTypes = {
  submission: PropTypes.object,
  t: PropTypes.func
};

export default withTranslation()(connect()(Submission));
