import React, { Component } from "react";
import { Input, Responsive, Button, Popup, Icon } from "semantic-ui-react";
import * as Actions from "../../conferenceList/actions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { TabEnum } from "../../common/tabEnum";

class Search extends Component {
  constructor(props) {
    super(props);
    this.onFilter = this.onFilter.bind(this);
    this.state = { searchValue: "" };
  }

  onFilter(e) {
    this.setState({ searchValue: e.target.value });
    this.props.setCurrentPage(1);
    this.props.setFilterConferences(e.target.value);
    if (e.target.value.length >= 3) {
      if (TabEnum.all === this.props.conferenceListTab) {
        this.props.fetchNumberOfConferences(e.target.value, false, false);
        this.props.filterConferences(
          e.target.value,
          1,
          this.props.limit,
          false,
          false
        );
      } else if (TabEnum.followed === this.props.conferenceListTab) {
        this.props.fetchNumberOfConferences(e.target.value, true, false);
        this.props.filterConferences(
          e.target.value,
          1,
          this.props.limit,
          true,
          false
        );
      } else {
        this.props.fetchNumberOfConferences(e.target.value, false, true);
        this.props.filterConferences(
          e.target.value,
          1,
          this.props.limit,
          false,
          true
        );
      }
    } else if (e.target.value === "") {
      if (TabEnum.all === this.props.conferenceListTab) {
        this.props.fetchNumberOfConferences("", false, false);
        this.props.removeFilterConferences();
        this.props.fetchConferences(1, this.props.limit, false, false);
      } else if (TabEnum.followed === this.props.conferenceListTab) {
        this.props.fetchNumberOfConferences("", true, false);
        this.props.removeFilterConferences();
        this.props.fetchConferences(1, this.props.limit, true, false);
      } else {
        this.props.fetchNumberOfConferences("", false, true);
        this.props.removeFilterConferences();
        this.props.fetchConferences(1, this.props.limit, false, true);
      }
    }
  }

  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          <Popup
            content={
              <Input
                onChange={this.onFilter}
                placeholder={this.props.t("topBar.search")}
                value={this.state.searchValue}
              />
            }
            on="click"
            pinned
            position="bottom center"
            trigger={
              <Button basic icon>
                <Icon name="search" />
              </Button>
            }
          />
        </Responsive>
        <Responsive as={Input} minWidth={768}>
          <Input
            onChange={this.onFilter}
            floated="right"
            icon="search"
            placeholder={this.props.t("topBar.search")}
            value={this.state.searchValue}
          />
        </Responsive>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  current: state.conferences.current,
  filterBy: state.conferences.filterBy,
  limit: state.conferences.limit,
  conferenceListTab: state.conferences.conferenceListTab
});

const mapDispatchToProps = dispatch => ({
  filterConferences: (name, number, limit, my, upcoming) =>
    Actions.filterConferences(dispatch, name, number, limit, my, upcoming),
  removeFilterConferences: () => Actions.removeFilterConferences(dispatch),
  setFilterConferences: name => Actions.setFilterConferences(dispatch, name),
  fetchConferences: (number, limit, my, upcoming) =>
    Actions.fetchConferences(dispatch, number, limit, my, upcoming),
  setCurrentPage: current => Actions.setCurrentPage(dispatch, current),
  fetchNumberOfConferences: (name, my, upcoming) =>
    Actions.fetchNumberOfConferences(dispatch, name, my, upcoming)
});

Search.propTypes = {
  fetchNumberOfConferences: PropTypes.func,
  setFilterConferences: PropTypes.func,
  setCurrentPage: PropTypes.func,
  filterConferences: PropTypes.func,
  limit: PropTypes.number,
  removeFilterConferences: PropTypes.func,
  fetchConferences: PropTypes.func,
  t: PropTypes.func,
  conferenceListTab: PropTypes.number
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Search)
);
