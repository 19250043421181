import React, { Component, Suspense } from "react";
import { Container } from "semantic-ui-react";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import RegisterPage from "./registration/components/registerPage";
import TopBar from "./common/components/topBar";
import ConferenceList from "./conferenceList/components/conferenceList";
import ConferenceCreateAndEditPage from "./conferenceCreateAndEdit/components/conferenceCreateAndEditPage";
import ConferenceEditPage from "./conferenceCreateAndEdit/components/conferenceEdit";
import ConferenceProfilePage from "./conferenceProfile/components/conferenceProfilePage";
import { history } from "./common/history";
import ConfirmRegistration from "./common/components/confirmRegistration";
import Alert from "./alerts/components/alerts";
import SubmissionForm from "./paperSubmission/components/submissionForm";
import submissionsPage from "./submissions/components/submissionsPage";
import userProfile from "./userProfile/components/userProfile";
import submissionDetailsPage from "./submissionDetails/components/submissionDetailsPage";
import ResendConfirmation from "./loginLogout/components/resendConfirmation";
import TrackSubmissions from "./trackSubmissions/components/trackSubmissions";
import AssignedSubmissionsPage from "./assignedSubmissions/components/assignedSubmissionsPage";
import UserList from "./userList/components/userList";

class App extends Component {
  render() {
    return (
      <>
        <Suspense fallback={<div>Loading...</div>}>
          <TopBar />
          <Alert />
          <Container style={{ margin: 50 }}>
            <Router history={history}>
              <Switch>
                <Route
                  exact
                  path="/confirmRegistration"
                  component={ConfirmRegistration}
                ></Route>
                <Route exact path="/register" component={RegisterPage} />
                <Route
                  path="/resendConfirmationEmail"
                  component={ResendConfirmation}
                />
                <Route
                  exact
                  path="/conferences/create"
                  component={ConferenceCreateAndEditPage}
                />
                <Route exact path="/conferences" component={ConferenceList} />
                <Route
                  exact
                  path="/conferences/:conferenceShortName/apply"
                  component={SubmissionForm}
                />

                <Route exact path="/userList" component={UserList} />

                <Route
                  exact
                  path="/conferences/:conferenceShortName/edit"
                  component={ConferenceEditPage}
                />
                <Route
                  exact
                  path="/conferences/:conferenceShortName"
                  component={ConferenceProfilePage}
                />
                <Route path="/profile/:id" component={userProfile} />
                <Route
                  path="/submissions/:id"
                  component={submissionDetailsPage}
                />
                <Route path="/submissions" component={submissionsPage} />
                <Route
                  path="/assignedSubmissions"
                  component={AssignedSubmissionsPage}
                />
                <Route
                  exact
                  path="/conferences/:conferenceId/tracks/:trackId/submissions"
                  component={TrackSubmissions}
                />
                <Route exact path="/">
                  <Redirect to="/conferences?listType=UPCOMING&current=1" />
                </Route>
              </Switch>
            </Router>
          </Container>
        </Suspense>
      </>
    );
  }
}

export default App;
