import * as actionTypes from "../constants/actionTypes";

const initialState = {
  currentPage: 1,
  error: null,
  loading: false,
  submissions: [],
  total: null
};

const trackSubmissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TRACKSUBMISSIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.FETCH_TRACKSUBMISSIONS:
      return {
        ...state,
        loading: false,
        submissions: action.submissions
      };
    case actionTypes.FETCH_TRACKSUBMISSIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        submissions: []
      };
    case actionTypes.SET_TOTAL_TRACKSUBMISSIONS:
      return {
        ...state,
        total: action.total
      };
    case actionTypes.SET_CURRENT_TRACKSUBMISSIONS:
      return {
        ...state,
        currentPage: action.currentPage
      };
    default:
      return state;
  }
};

export default trackSubmissionsReducer;
