import * as actionTypes from "../constants/actionTypes";

const initialState = {
  conferences: [],
  total: null,
  current: 1,
  limit: 3,
  loading: false,
  error: null,
  filter: false,
  filterBy: "",
  conferenceListTab: 0
};

const conferencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CONFERENCES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.FETCH_CONFERENCES:
      return {
        ...state,
        loading: false,
        conferences: action.conferences
      };
    case actionTypes.FETCH_CONFERENCES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        conferences: []
      };
    case actionTypes.FILTER_CONFERENCES:
      return {
        ...state,
        loading: false,
        conferences: action.conferences,
        filter: true
      };
    case actionTypes.REMOVE_FILTER:
      return {
        ...state,
        filter: false,
        filterBy: ""
      };
    case actionTypes.SET_FILTER:
      return {
        ...state,
        filterBy: action.filterBy,
        filter: action.filter
      };
    case actionTypes.SET_TOTAL:
      return {
        ...state,
        total: action.total
      };
    case actionTypes.SET_CURRENT:
      return {
        ...state,
        current: action.current
      };
    case actionTypes.SET_LIMIT:
      return {
        ...state,
        limit: action.limit
      };

    case actionTypes.SET_CONFERECE_LIST_TAB:
      return {
        ...state,
        conferenceListTab: action.conferenceListTab
      };
    case actionTypes.SET_FOLLOWED:
      return {
        ...state,
        conferences: state.conferences.map((conference, index) => {
          if (index === action.index) {
            return { ...conference, isFollowed: true };
          }
          return conference;
        })
      };
    case actionTypes.REMOVE_FOLLOWED:
      return {
        ...state,
        conferences: state.conferences.map((conference, index) => {
          if (index === action.index)
            return { ...conference, isFollowed: false };
          return conference;
        })
      };
    default:
      return state;
  }
};
export default conferencesReducer;
