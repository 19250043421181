import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Dropdown, Image } from "semantic-ui-react";
import PropTypes from "prop-types";
import "../style/LanguageDropdown.css";
import * as Actions from "../actions";

const languagesList = [
  { value: "en", image: { src: "/resources/img/en.jpg" } },
  { value: "hu", image: { src: "/resources/img/hu.jpg" } }
];

class LanguageDropdown extends Component {
  constructor(props) {
    super(props);
    if (
      !Object.prototype.hasOwnProperty.call(localStorage, "selectedLanguage")
    ) {
      localStorage.setItem("selectedLanguage", "en");
    }
  }
  onLanguageChange = (_, data) => {
    this.props.setLanguage(data.value);
    if (this.props.isLoggedIn) {
      let user = {
        ...this.props.loggedInUser,
        preferredLanguage: data.value
      };
      delete user.permissions;
      this.props.setPreferredLanguage(user);
    }
  };

  getLanguages() {
    return languagesList.map(x => {
      return { value: x.value, image: x.image };
    });
  }

  render() {
    const languages = this.getLanguages();
    let trigger = (
      <span>
        <Image
          className="ui
          circular
          image"
          src={
            "/resources/img/" +
            localStorage.getItem("selectedLanguage") +
            ".jpg"
          }
          alt="selectedLanguageFlag"
        />
      </span>
    );
    return (
      <div>
        <Dropdown
          inline
          trigger={trigger}
          options={languages}
          defaultValue={this.props.selectedLanguage}
          className="dropdown"
          onChange={this.onLanguageChange}
          pointing="top right"
          icon={null}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.loginLogout.loggedIn,
  selectedLanguage: state.setLanguage.language,
  loggedInUser: state.loginLogout.user
});

const mapDispatchToProps = dispatch => ({
  setLanguage: language => Actions.setLanguage(dispatch, language),
  setPreferredLanguage: preferredLanguage =>
    Actions.setPreferredLanguage(dispatch, preferredLanguage)
});

LanguageDropdown.propTypes = {
  isLoggedIn: PropTypes.bool,
  loggedInUser: PropTypes.object,
  selectedLanguage: PropTypes.string,
  setPreferredLanguage: PropTypes.func,
  i18n: PropTypes.object,
  setLanguage: PropTypes.func
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(LanguageDropdown)
);
