import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Card, Grid, Divider } from "semantic-ui-react";
import PropTypes, { number } from "prop-types";
import { connect } from "react-redux";
import { orderNames } from "../../common/nameLocalization";

class AuthorSupervisorMobile extends Component {
  render() {
    return (
      <Card.Group>
        {this.props.submission.userSubmission.map((user, index) =>
          user.userRole === this.props.userRole ? (
            <Card key={index}>
              <Card.Content>
                <Card.Header>
                  <b>
                    {orderNames(
                      user.firstName,
                      user.lastName,
                      this.props.t("localeFormats:namePattern")
                    )}
                  </b>
                </Card.Header>
                <Card.Meta>{user.email}</Card.Meta>
                <Divider />
                <Card.Description>
                  <Grid columns={2} divided="vertically">
                    <Grid.Row padding={5}>
                      <Grid.Column textAlign="left">
                        {this.props.t("submissionDetails.institution")}
                        {": "}
                      </Grid.Column>
                      <Grid.Column textAlign="center">
                        {user.institution}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column textAlign="left">
                        {this.props.t("submissionDetails.department")}
                        {": "}
                      </Grid.Column>
                      <Grid.Column textAlign="center">
                        {user.department}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Card.Description>
              </Card.Content>
            </Card>
          ) : null
        )}
      </Card.Group>
    );
  }
}

AuthorSupervisorMobile.propTypes = {
  submission: PropTypes.object,
  userRole: number,
  t: PropTypes.func
};

export default withTranslation()(connect()(AuthorSupervisorMobile));
