import React, { Component } from "react";
import { connect } from "react-redux";
import * as Actions from "../actions";
import queryString from "query-string";

import PropTypes from "prop-types";

class ConfirmRegistration extends Component {
  constructor(props) {
    super(props);
    this.values = queryString.parse(this.props.location.search);
  }
  componentDidMount() {
    this.props.confirmUser(this.values.userId, this.values.token);
  }

  render() {
    return <></>;
  }
}

const mapDispatchToProps = dispatch => ({
  confirmUser: (userId, token) => Actions.confirmUser(dispatch, userId, token)
});

ConfirmRegistration.propTypes = {
  location: PropTypes.object,
  confirmUser: PropTypes.func
};

export default connect(null, mapDispatchToProps)(ConfirmRegistration);
