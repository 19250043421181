import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import {
  Responsive,
  Card,
  Image,
  Grid,
  Segment,
  Button,
  Icon
} from "semantic-ui-react";
import FavoriteButton from "../../followButton/components/followButton";
import { fetchConference, fetchExtensions } from "../actions";
import { setConferenceListTab } from "../../conferenceList/actions";
import { setOpen } from "../../loginLogout/actions";
import TrackPanel from "./trackPanel";
import SubmissionDeadlinePanel from "./submissionDeadlinePanel";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "../style/conferenceProfilePage.css";
import { Link } from "react-router-dom";
import SubmissionsTable from "./submissionsTable";
import { history } from "../../common/history";
import MomentWithUtc from "../../common/components/momentWithUtc";

class ConferenceProfilePage extends Component {
  constructor(props) {
    super(props);
    this.committeeMemberAt = [];
    this.handleLogin = this.handleLogin.bind(this);
  }

  componentDidMount() {
    this.props.fetchConference(
      this.props.match.params.conferenceShortName,
      this.props.userId
    );
  }

  renderTitleWithMetaData() {
    return (
      <div>
        <div className="conferenceTitle">
          {this.props.isLoggedIn ? (
            <FavoriteButton
              isFollowed={this.props.conference.isFollowed}
              conferenceId={this.props.conference.id}
              index={this.props.index}
            />
          ) : (
            ""
          )}
          {this.props.conference.name}
          {this.props.conference.isOrganizer ? (
            <Button
              floated="right"
              content={this.props.t("buttonText.edit")}
              icon="edit outline"
              onClick={() => {
                history.push(
                  `/conferences/${this.props.conference.shortName}/edit`
                );
              }}
            />
          ) : null}
        </div>
        <div className="conferenceMeta">
          {this.props.t("conferenceProfile.location")}
          {this.props.conference.location}
          <br />
          {this.props.t("conferenceProfile.startDate")}
          <MomentWithUtc
            format={"dateFormat"}
            date={this.props.conference.startDate}
          />
          <br />
          <SubmissionDeadlinePanel />
        </div>
      </div>
    );
  }

  renderContent() {
    return (
      <div>
        <div className="conferenceContent">
          {this.props.conference.longDescription}
        </div>
      </div>
    );
  }

  handleLogin() {
    this.props.setOpen(true);
  }

  renderSubmissionContainer() {
    return (
      <div className="submitButton">
        {this.props.isLoggedIn ? (
          this.props.conference.active ? (
            <Button
              fluid
              as={Link}
              to={"/conferences/" + this.props.conference.shortName + "/apply"}
            >
              {this.props.t("conferenceProfile.submitButton")}
            </Button>
          ) : (
            <Button fluid disabled>
              {" "}
              {this.props.t("conferenceProfile.inactive")}
            </Button>
          )
        ) : (
          <Button fluid onClick={this.handleLogin}>
            {this.props.t("conferenceProfile.pleaseLogIn")}
          </Button>
        )}
      </div>
    );
  }

  initilizeTracksForCommitteeMember() {
    this.committeeMemberAt = this.props.conference.tracks
      .filter(track =>
        track.userTracks.some(ut => {
          return ut.userId === this.props.userId;
        })
      )
      .map(track => track.id);
  }

  render() {
    if (this.props.error) {
      return <div>Error! {this.props.error.message}</div>;
    }

    if (this.props.loading) {
      return <div className="loadingDiv">{this.props.t("common.loading")}</div>;
    }

    return (
      <div style={{ marginTop: "100px" }}>
        <Suspense fallback={<div>Loading...</div>}>
          <Responsive minWidth={768}>
            <Button
              onClick={() => {
                this.props.setConferenceListTab(0);
                window.history.back();
              }}
            >
              <Icon name="angle left" />
            </Button>
            <Card style={{ width: "100%" }}>
              <Segment loading={this.props.loading}>
                <Card.Content>
                  <Grid columns={2}>
                    <Grid.Row>
                      <Grid.Column>
                        {this.renderTitleWithMetaData()}
                        {this.renderContent()}
                      </Grid.Column>

                      <Grid.Column>
                        <Image
                          floated="right"
                          size="large"
                          src={this.props.conference.logo}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Grid columns={1}>
                    <Grid.Column>
                      <Grid.Row>
                        <div className="trackPanel">
                          {this.initilizeTracksForCommitteeMember()}
                          {this.props.conference.tracks ? (
                            <TrackPanel
                              conferenceTracks={this.props.conference.tracks}
                              isOrganizer={this.props.conference.isOrganizer}
                              isCommitteeMember={
                                this.committeeMemberAt.length > 0
                              }
                              committeeMemberAt={this.committeeMemberAt}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid.Row>
                    </Grid.Column>
                  </Grid>
                  {this.renderSubmissionContainer()}
                </Card.Content>
              </Segment>
            </Card>
            {this.props.isLoggedIn && this.props.submissions.length > 0 ? (
              <SubmissionsTable
                key={this.props.submissions.length}
                submissions={this.props.submissions}
              />
            ) : (
              ""
            )}
          </Responsive>
          <Responsive {...Responsive.onlyMobile}>
            <Button onClick={() => window.history.back()}>
              <Icon name="angle left" />
            </Button>
            <Card centered style={{ width: "100%" }}>
              <Image centered size="medium" src={this.props.conference.logo} />
              <Card.Content>
                {this.renderTitleWithMetaData()}
                {this.renderContent()}

                <div className="trackPanel">
                  {this.props.conference.tracks ? (
                    <TrackPanel
                      conferenceTracks={this.props.conference.tracks}
                      isOrganizer={this.props.conference.isOrganizer}
                      isCommitteeMember={this.committeeMemberAt.length > 0}
                      committeeMemberAt={this.committeeMemberAt}
                    />
                  ) : (
                    ""
                  )}
                </div>
                {this.renderSubmissionContainer()}
              </Card.Content>
            </Card>
            {this.props.isLoggedIn && this.props.submissions.length > 0 ? (
              <SubmissionsTable
                key={this.props.submissions.length}
                submissions={this.props.submissions}
              />
            ) : (
              ""
            )}
          </Responsive>
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  conference: state.conferenceProfileReducer.conference,
  error: state.conferenceProfileReducer.error,
  isLoggedIn: state.loginLogout.loggedIn,
  loading: state.conferenceProfileReducer.loadingConferenceProfile,
  submissions: state.submissions.submissions,
  userId: state.loginLogout.userId
});

const mapDispatchToProps = dispatch => ({
  fetchConference: (name, userId) => fetchConference(dispatch, name, userId),
  fetchExtensions: () => fetchExtensions(dispatch),
  setConferenceListTab: listTab => setConferenceListTab(dispatch, listTab),
  setOpen: isOpen => setOpen(dispatch, isOpen)
});

ConferenceProfilePage.propTypes = {
  conference: PropTypes.object,
  t: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  fetchConference: PropTypes.func,
  fetchExtensions: PropTypes.func,
  match: PropTypes.object,
  error: PropTypes.string,
  loading: PropTypes.bool,
  index: PropTypes.number,
  setConferenceListTab: PropTypes.func,
  setOpen: PropTypes.func,
  submissions: PropTypes.array,
  userId: PropTypes.string
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConferenceProfilePage)
);
