import * as actionTypes from "../constants/actionTypes";

const initialState = {
  submissions: [],
  loading: false,
  error: null
};

const submissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SUBMISSIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.FETCH_SUBMISSIONS:
      return {
        ...state,
        loading: false,
        submissions: action.submissions
      };
    case actionTypes.FETCH_SUBMISSIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        submissions: []
      };
    default:
      return state;
  }
};
export default submissionsReducer;
