const emailValidator = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const nameValidator = /^[A-Za-z]+([ A-Za-z]+)*/;

export const validateEmail = email => {
  return emailValidator.test(email);
};

export const validateName = name => {
  return nameValidator.test(name);
};

export const validateAbstract = (currentLength, allowedLength) => {
  return currentLength <= allowedLength && currentLength !== 0;
};
