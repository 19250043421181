import React, { Component } from "react";
import { connect } from "react-redux";
import * as ListTypes from "../constants/submissionListTypes";
import PropTypes from "prop-types";
import { Tab } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

class SubmissionsListTab extends Component {
  render() {
    let panes = [
      {
        menuItem: {
          key: "supervised",
          content: this.props.t("submissionList.supervised"),
          as: Link,
          to:
            "/assignedSubmissions?listType=" + ListTypes.SUPERVISED + "&page=1",
          state: { current: "#current" }
        }
      }
    ];
    return (
      <div>
        <Tab activeIndex={this.props.submissionsListTab} panes={panes} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  submissionsListTab: state.assignedSubmissions.submissionsListTab
});

SubmissionsListTab.propTypes = {
  t: PropTypes.func,
  submissionsListTab: PropTypes.number
};

export default withTranslation()(connect(mapStateToProps)(SubmissionsListTab));
