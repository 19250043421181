import { alertActions } from "../../alerts/actions";
import { authHeader } from "../../common/authHeader";
import { fetchWrapper } from "../../common/fetchWrap";

export const follow = (dispatch, conferenceId) => {
  fetchWrapper(
    `/api/conferences/${conferenceId}/followers`,
    {
      method: "POST",
      headers: authHeader()
    },
    true
  )
    .then(res => res.json())
    .catch(error => dispatch(alertActions.error(error)));
};

export const unFollow = (dispatch, conferenceId) => {
  fetchWrapper(
    `/api/conferences/${conferenceId}/followers`,
    {
      method: "DELETE",
      headers: authHeader()
    },
    true
  )
    .then(res => res.json())
    .catch(error => dispatch(alertActions.error(error)));
};
