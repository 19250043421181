import * as actionTypes from "../constants/actionTypes";

const initialState = {
  user: null,
  loading: true,
  error: null,
  allPermissions: null
};

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.FETCH_USER_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
        user: null
      };
    }
    case actionTypes.FETCH_USER: {
      return {
        ...state,
        loading: false,
        user: action.user
      };
    }
    case actionTypes.FETCH_ALL_PERMISSIONS: {
      return {
        ...state,
        allPermissions: action.allPermissions
      };
    }
    default:
      return state;
  }
};
export default userProfileReducer;
