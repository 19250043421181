import React, { Component } from "react";
import { Card, Image, Label, Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import FavoriteButton from "../../followButton/components/followButton";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "../style/conference.css";
import { connect } from "react-redux";
import { history } from "../../common/history";

import MomentWithUtc from "../../common/components/momentWithUtc";

class Conference extends Component {
  navigate = () => {
    history.push("/conferences/" + this.props.conference.shortName);
  };
  render() {
    return (
      <Card>
        <Card.Content>
          <Card.Header>
            <Grid columns="equal">
              {this.props.isLoggedIn ? (
                <Grid.Column width={1}>
                  <FavoriteButton
                    isFollowed={this.props.conference.isFollowed}
                    conferenceId={this.props.conference.id}
                    index={this.props.index}
                  />
                </Grid.Column>
              ) : (
                ""
              )}
              <Grid.Column
                onClick={() => this.navigate()}
                style={{ cursor: "pointer" }}
              >
                {this.props.conference.name}
              </Grid.Column>
            </Grid>
          </Card.Header>
        </Card.Content>
        <Card.Content
          key={this.props.conference.shortName}
          as={Link}
          to={"/conferences/" + this.props.conference.shortName}
        >
          <Image floated="right" size="tiny" src={this.props.conference.logo} />

          <Card.Meta>
            {this.props.conference.location}
            <br />
            <MomentWithUtc
              format={"dateFormat"}
              date={this.props.conference.startDate}
            />
            --
            <MomentWithUtc
              format={"dateFormat"}
              date={this.props.conference.endDate}
            />
            <br />
          </Card.Meta>
          <Card.Description>
            {this.props.conference.shortDescription}
          </Card.Description>
          <Label.Group className="conferenceLableGroup">
            {this.props.conference.tracks
              ? this.props.conference.tracks.map(track => (
                  <Label key={this.props.conference.tracks.indexOf(track)}>
                    {track.name}
                  </Label>
                ))
              : ""}
          </Label.Group>
        </Card.Content>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.loginLogout.loggedIn
});

Conference.propTypes = {
  conference: PropTypes.object,
  t: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  index: PropTypes.number
};

export default withTranslation()(connect(mapStateToProps)(Conference));
