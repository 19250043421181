export const FETCH_CONFERENCES = "FETCH_CONFERENCES";
export const FETCH_CONFERENCES_BEGIN = "FETCH_CONFERENCES_BEGIN";
export const FETCH_CONFERENCES_ERROR = "FETCH_CONFERENCES_ERROR";
export const FILTER_CONFERENCES = "FILTER_CONFERENCES";
export const REMOVE_FILTER = "REMOVE_FILTER";
export const SET_FILTER = "SET_FILTER";
export const SET_TOTAL = "SET_TOTAL";
export const SET_CURRENT = "SET_CURRENT";
export const SET_LIMIT = "SET_LIMIT";
export const SET_CONFERECE_LIST_TAB = "SET_CONFERECE_LIST_TAB";
export const SET_FOLLOWED = "SET_FOLLOWED";
export const REMOVE_FOLLOWED = "REMOVE_FOLLOWED";
