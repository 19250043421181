import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { Responsive, Card, Grid, Button, Icon, Form } from "semantic-ui-react";
import {
  unauthorized,
  fetchUserDetails,
  updateUserDetails,
  notLoggedIn
} from "../actions";
import { withTranslation } from "react-i18next";
import "../style/profileIcon.css";
import "../style/border.css";
import "../style/permission.css";
import PropTypes from "prop-types";
import UserPermissions from "./userPermissions";
import { PermissionEnum } from "../../common/permissionEnum";
import { checkPermission } from "../../common/checkPermission";

class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      spec: "",
      institution: "",
      department: "",
      year: "",
      nameError: false,
      specError: false,
      institutionError: false,
      departmentError: false,
      yearError: false,
      emailError: false,
      passwordError: false,
      nameModified: false,
      specModified: false,
      institutionModified: false,
      yearModified: false,
      modified: false,
      adminView: false,
      permissions: []
    };

    this.disable = this.disable.bind(this);
    this.firstNameChange = this.firstNameChange.bind(this);
    this.lastNameChange = this.lastNameChange.bind(this);
    this.specChange = this.specChange.bind(this);
    this.departmentChange = this.departmentChange.bind(this);
    this.institutionChange = this.institutionChange.bind(this);
    this.yearChange = this.yearChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.determineView = this.determineView.bind(this);
  }

  componentDidMount() {
    this.determineView();
  }

  determineView() {
    if (!this.props.isLoggedIn) {
      this.props.notLoggedIn();
    } else {
      // isAdmin determines if the person trying to view the profile is an admin
      var isAdmin = checkPermission(
        this.props.user.permissions,
        PermissionEnum.permissionEdit
      );
      // selfView determines if you are trying to watch your own profile
      const selfView = this.props.match.params.id === this.props.user.id;
      if (isAdmin || selfView) {
        // sets adminView so that an Admin can edit others permission, but not his own
        this.setState({ adminView: isAdmin && !selfView });
        this.props.fetchUserDetails(this.props.match.params.id);
      } else {
        this.props.unauthorized();
      }
    }
  }

  disable() {
    let disable = true;
    if (
      this.state.firstName ||
      this.state.lastName ||
      this.state.institution ||
      this.state.department ||
      this.state.spec ||
      this.state.year
    ) {
      disable = false;
    }
    return disable;
  }

  firstNameChange(e) {
    this.setState({ firstName: e.target.value });
  }

  lastNameChange(e) {
    this.setState({ lastName: e.target.value });
  }

  specChange(e) {
    this.setState({ spec: e.target.value });
  }

  institutionChange(e) {
    this.setState({ institution: e.target.value });
  }

  departmentChange(e) {
    this.setState({ department: e.target.value });
  }

  yearChange(e) {
    this.setState({ year: e.target.value });
  }

  handleSave(e) {
    e.preventDefault();
    if (this.state.firstName) this.user.firstName = this.state.firstName;

    if (this.state.lastName) this.user.lastName = this.state.lastName;

    if (this.state.department) this.user.department = this.state.department;

    if (this.state.spec) this.user.specialization = this.state.spec;

    if (this.state.institution) this.user.institution = this.state.institution;

    if (this.state.year) this.user.year = this.state.year;

    this.props.updateUserDetails(this.user);
  }

  render() {
    if (this.props.error) {
      return <div>Error! {this.props.error.message}</div>;
    }

    if (this.props.loading) {
      return <div className="loadingDiv">{this.props.t("common.loading")}</div>;
    }

    this.user = this.props.user;
    return (
      <div style={{ marginTop: "100px" }}>
        <Suspense fallback={<div>Loading...</div>}>
          <Responsive minWidth={768}>
            <Card style={{ width: "100%" }}>
              <Grid columns={this.state.adminView ? 3 : 2}>
                <Grid.Column textAlign="center" width={4}>
                  <Icon className="profileIcon" name="user" size="massive" />
                </Grid.Column>
                <Grid.Column width={this.state.adminView ? 6 : 9}>
                  <Form
                    className="border"
                    autoComplete="off"
                    onSubmit={this.handleSave}
                  >
                    <Form.Group width="equal">
                      <Form.Field error={this.state.nameError}>
                        <label>{this.props.t("register.firstName")}</label>
                        <input
                          name="firstName"
                          defaultValue={this.props.viewedUser.firstName}
                          onChange={this.firstNameChange}
                          readOnly={this.state.adminView}
                        />
                      </Form.Field>
                      <Form.Field error={this.state.nameError}>
                        <label>{this.props.t("register.lastName")}</label>
                        <input
                          name="lastName"
                          defaultValue={this.props.viewedUser.lastName}
                          onChange={this.lastNameChange}
                          readOnly={this.state.adminView}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Field error={this.state.institutionError}>
                      <label>
                        {this.props.t("paperSubmission.institution")}
                      </label>
                      <input
                        name="institution"
                        defaultValue={this.props.viewedUser.institution}
                        onChange={this.institutionChange}
                        readOnly={this.state.adminView}
                      />
                    </Form.Field>
                    <Form.Group widths="equal">
                      <Form.Field error={this.state.departmentError}>
                        <label>
                          {this.props.t("paperSubmission.department")}
                        </label>
                        <input
                          name="department"
                          defaultValue={this.props.viewedUser.department}
                          onChange={this.departmentChange}
                          readOnly={this.state.adminView}
                        />
                      </Form.Field>
                      <Form.Field error={this.state.specError}>
                        <label>
                          {this.props.t("paperSubmission.specialization")}
                        </label>
                        <input
                          name="specialization"
                          defaultValue={this.props.viewedUser.specialization}
                          onChange={this.specChange}
                          readOnly={this.state.adminView}
                        />
                      </Form.Field>
                      <Form.Field error={this.state.yearError}>
                        <label>{this.props.t("paperSubmission.year")}</label>
                        <input
                          type="number"
                          min={1}
                          max={6}
                          name="year"
                          defaultValue={this.props.viewedUser.year}
                          onChange={this.yearChange}
                          readOnly={this.state.adminView}
                        />
                      </Form.Field>
                    </Form.Group>
                    {this.state.adminView ? null : (
                      <Button type="submit" disabled={this.disable()}>
                        {this.props.t("userProfile.save")}
                      </Button>
                    )}
                  </Form>
                </Grid.Column>
                {this.state.adminView ? (
                  <Grid.Column>
                    <div className="permission">
                      <UserPermissions />
                    </div>
                  </Grid.Column>
                ) : null}
              </Grid>
            </Card>
          </Responsive>
          <Responsive {...Responsive.onlyMobile}>
            <Grid divided="vertically">
              <Grid.Row columns={1}>
                <Grid.Column textAlign="center">
                  <Icon name="user" size="massive" />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Form autoComplete="off" onSubmit={this.handleSave}>
                    <Form.Field error={this.state.nameError}>
                      <label>{this.props.t("register.firstName")}</label>
                      <input
                        name="firstName"
                        defaultValue={this.props.viewedUser.firstName}
                        onChange={this.firstNameChange}
                        readOnly={this.state.adminView}
                      />
                    </Form.Field>
                    <Form.Field error={this.state.nameError}>
                      <label>{this.props.t("register.lastName")}</label>
                      <input
                        name="lastName"
                        defaultValue={this.props.viewedUser.lastName}
                        onChange={this.lastNameChange}
                        readOnly={this.state.adminView}
                      />
                    </Form.Field>
                    <Form.Field error={this.state.institutionError}>
                      <label>
                        {this.props.t("paperSubmission.institution")}
                      </label>
                      <input
                        name="institution"
                        defaultValue={this.props.viewedUser.institution}
                        onChange={this.institutionChange}
                        readOnly={this.state.adminView}
                      />
                    </Form.Field>
                    <Form.Group widths="equal">
                      <Form.Field error={this.state.departmentError}>
                        <label>
                          {this.props.t("paperSubmission.department")}
                        </label>
                        <input
                          name="department"
                          defaultValue={this.props.viewedUser.department}
                          onChange={this.departmentChange}
                          readOnly={this.state.adminView}
                        />
                      </Form.Field>
                      <Form.Field error={this.state.specError}>
                        <label>
                          {this.props.t("paperSubmission.specialization")}
                        </label>
                        <input
                          name="specialization"
                          defaultValue={this.props.viewedUser.specialization}
                          onChange={this.specChange}
                          readOnly={this.state.adminView}
                        />
                      </Form.Field>
                      <Form.Field error={this.state.yearError}>
                        <label>{this.props.t("paperSubmission.year")}</label>
                        <input
                          type="number"
                          min={1}
                          max={6}
                          name="year"
                          defaultValue={this.props.viewedUser.year}
                          onChange={this.yearChange}
                          readOnly={this.state.adminView}
                        />
                      </Form.Field>
                    </Form.Group>
                    {this.state.adminView ? null : (
                      <Button type="submit" disabled={this.disable()}>
                        {this.props.t("userProfile.save")}
                      </Button>
                    )}
                  </Form>
                  {this.state.adminView ? <UserPermissions /> : null}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Responsive>
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  error: state.userProfile.error,
  isLoggedIn: state.loginLogout.loggedIn,
  loading: state.userProfile.loading,
  user: state.loginLogout.user,
  viewedUser: state.userProfile.user,
  profile: state.userProfile.user
});

const mapDispatchToProps = dispatch => ({
  fetchUserDetails: id => fetchUserDetails(dispatch, id),
  notLoggedIn: () => notLoggedIn(dispatch),
  unauthorized: () => unauthorized(dispatch),
  updateUserDetails: user => updateUserDetails(dispatch, user)
});

UserProfile.propTypes = {
  error: PropTypes.string,
  fetchUserDetails: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  loading: PropTypes.bool,
  t: PropTypes.func,
  updateUserDetails: PropTypes.func,
  user: PropTypes.object,
  match: PropTypes.object,
  viewedUser: PropTypes.object,
  unauthorized: PropTypes.func,
  notLoggedIn: PropTypes.func,
  profile: PropTypes.object
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(UserProfile)
);
