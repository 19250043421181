import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Popup, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";

export class PopUpWarningSignWithMessage extends Component {
  render() {
    return (
      <Popup
        trigger={
          this.props.warningCondition ? (
            <Icon name="warning sign" color="red" />
          ) : null
        }
        content={this.props.t(this.props.translatedWarningMessage)}
        position="bottom center"
      />
    );
  }
}

PopUpWarningSignWithMessage.propTypes = {
  translatedWarningMessage: PropTypes.string,
  warningCondition: PropTypes.bool,
  t: PropTypes.func
};

export default withTranslation()(PopUpWarningSignWithMessage);
