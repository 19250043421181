import * as ActionTypes from "../constants/actionTypes";
import { alertActions } from "../../alerts/actions";
import { authHeader } from "../../common/authHeader";
import { history } from "../../common/history";
import {
  fetchSubmissionsSuccess,
  fetchSubmissionsError
} from "../../submissions/actions";
import { UserEnum } from "../../common/userEnum";
import { fetchWrapper } from "../../common/fetchWrap";

const fetchConferenceBegin = () => ({
  type: ActionTypes.FETCH_CONFERENCE_BEGIN
});
const fetchConferenceError = error => ({
  type: ActionTypes.FETCH_CONFERENCE_ERROR,
  error
});

const fetchConferenceSuccess = data => {
  return {
    type: ActionTypes.FETCH_CONFERENCE,
    conference: data.conference
  };
};

export const fetchConference = (dispatch, name, id) => {
  dispatch(fetchConferenceBegin());
  fetchWrapper(
    `/api/conferences/${name}`,
    {
      method: "GET",
      headers: authHeader()
    },
    undefined !== id
  ).then(res => {
    if (!res.ok) {
      dispatch(alertActions.error("alertMessage.conferenceNotFound"));
      history.push("/");
    } else {
      res
        .json()
        .then(res => {
          if (undefined !== id) {
            fetchSubmissions(dispatch, id, name);
          }
          fetchExtensions(dispatch);
          dispatch(fetchConferenceSuccess(res.value));
        })
        .catch(error => dispatch(fetchConferenceError(error)));
    }
  });
};

export const fetchSubmissions = (dispatch, id, name) => {
  fetchWrapper(
    `/api/users/${id}/submissions?shortName=${name}`,
    {
      method: "GET",
      headers: authHeader()
    },
    true
  )
    .then(res => {
      if (!res.ok) {
        dispatch(alertActions.error("alertMessage.conferenceNotFound"));
        history.push("/");
      } else {
        res
          .json()
          .then(res => dispatch(fetchSubmissionsSuccess(res.value)))
          .catch(error => dispatch(fetchSubmissionsError(error)));
      }
    })
    .catch(error => dispatch(alertActions.error(error)));
};

export const deleteTrack = (dispatch, conferenceId, trackId) => {
  fetchWrapper(
    `/api/conferences/${conferenceId}/tracks/${trackId}`,
    {
      method: "DELETE",
      headers: authHeader()
    },
    true
  )
    .then(res => {
      switch (res.status) {
        case 204: {
          dispatch(removeTrackFromList(trackId));
          dispatch(alertActions.success("alertMessage.trackDeleted"));
          break;
        }
        case 400: {
          dispatch(alertActions.error("alertMessage.trackNotDeleted"));
          break;
        }
        case 403: {
          dispatch(alertActions.error("alertMessage.userNoRight"));
          break;
        }
        default: {
          dispatch(alertActions.error("alertMessage.trackDeletionError"));
          break;
        }
      }
    })
    .catch(error => dispatch(alertActions.error(error)));
};

export const addNewTrack = (
  dispatch,
  conferenceId,
  track,
  trackExtensions,
  committeeMembers,
  evaluationCriteria,
  closeFunction
) => {
  let header = Object.assign(authHeader(), {
    "Content-Type": "application/json"
  });
  track.trackExtensions = trackExtensions.map(ext => {
    return { extensionId: ext.extensionId };
  });
  track.userTracks = committeeMembers.map(cm => {
    return { userId: cm.id, userRole: UserEnum.committee };
  });
  track.evaluationCriteria = evaluationCriteria.filter(
    ec => ec.name !== "" && ec.weigth !== ""
  );
  fetchWrapper(
    `/api/conferences/${conferenceId}/tracks/`,
    {
      method: "POST",
      headers: header,
      body: JSON.stringify(track)
    },
    true
  )
    .then(res => {
      switch (res.status) {
        case 201: {
          res.json().then(jsonifiedRes => {
            dispatch(addTrackToList(jsonifiedRes));
          });
          dispatch(alertActions.success("alertMessage.trackCreated"));
          closeFunction();
          break;
        }
        case 403: {
          dispatch(alertActions.error("alertMessage.userNoRight"));
          break;
        }
        default: {
          dispatch(alertActions.error("alertMessage.trackCreationError"));
          break;
        }
      }
    })
    .catch(err => dispatch(alertActions.error(err)));
};

export const updateTrack = (
  dispatch,
  conferenceId,
  track,
  trackExtensions,
  committeeMembers,
  evaluationCriteria,
  closeFunction
) => {
  let header = Object.assign(authHeader(), {
    "Content-Type": "application/json"
  });
  track.trackExtensions = trackExtensions;
  // needed because userDTO and userTrackDTO have different names for the id of the User
  if (committeeMembers !== undefined) {
    track.userTracks = committeeMembers.map(cm => {
      return {
        userId: cm.id !== undefined ? cm.id : cm.userId,
        trackId: track.id,
        firstName: cm.firstName,
        lastName: cm.lastName,
        email: cm.email,
        userRole: UserEnum.committee
      };
    });
  }
  track.evaluationCriteria = evaluationCriteria.filter(
    ec => ec.name !== "" && ec.weight !== ""
  );
  fetchWrapper(
    `/api/conferences/${conferenceId}/tracks/${track.id}`,
    {
      method: "PATCH",
      headers: header,
      body: JSON.stringify(track)
    },
    true
  )
    .then(res => {
      switch (res.status) {
        case 200: {
          dispatch(updateTrackList(track, track.id));
          dispatch(alertActions.success("alertMessage.trackUpdated"));
          closeFunction();
          break;
        }
        case 403: {
          dispatch(alertActions.error("alertMessage.userNoRight"));
          break;
        }
        default: {
          dispatch(alertActions.error("alertMessage.trackUpdateError"));
          break;
        }
      }
    })
    .catch(err => dispatch(alertActions.error(err)));
};

const addTrackToList = track => {
  return {
    type: ActionTypes.ADD_TRACK_TO_LIST,
    track: track
  };
};

const updateTrackList = (track, trackId) => {
  return {
    type: ActionTypes.UPDATE_TRACK_LIST,
    track: track,
    trackId: trackId
  };
};

const removeTrackFromList = trackId => {
  return {
    type: ActionTypes.REMOVE_TRACK_FROM_LIST,
    trackId: trackId
  };
};

export const fetchExtensions = dispatch => {
  fetchWrapper(`/api/extensions`, {
    method: "GET",
    headers: authHeader()
  })
    .then(res => {
      if (res.ok) {
        res.json().then(res => dispatch(fetchExtensionsSuccess(res.value)));
      } else {
        dispatch(alertActions.error("alertMessage.fetchExtensionError"));
      }
    })
    .catch(error => dispatch(fetchConferenceError(error)));
};

const fetchExtensionsSuccess = data => {
  return {
    type: ActionTypes.FETCH_EXTENSIONS,
    extensions: data.extensions
  };
};

const loadCommitteeAction = trackId => ({
  type: ActionTypes.LOAD_COMMITTEE,
  trackId: trackId
});

export const loadCommittee = (dispatch, trackId) => {
  dispatch(loadCommitteeAction(trackId));
};

export const fetchTrack = (conferenceId, trackId, dispatch) => {
  fetchWrapper(`/api/conferences/${conferenceId}/tracks/${trackId}`, {
    method: "GET",
    headers: authHeader()
  }).then(res => {
    if (res.ok) {
      res.json().then(track => dispatch(updateTrackList(track, trackId)));
    } else {
      dispatch(alertActions.error("alertMessage.fetchTrackError"));
    }
  });
};
